import { cx } from "class-variance-authority";
import { forwardRef } from "react";

export type KbdProps = {
  theme?: "light" | "dark";
} & React.HTMLAttributes<HTMLSpanElement>;

export const Kbd = forwardRef<HTMLSpanElement, KbdProps>(
  ({ className, theme = "light", ...props }, ref) => {
    return (
      <kbd
        ref={ref}
        className={cx(
          "inline-flex items-center justify-center rounded border border-b-[1.5px] px-1 text-center font-[650] font-mono text-[0.7125rem] uppercase shadow-sm",
          theme === "light"
            ? "border-slate-300 bg-slate-200 text-slate-700"
            : "border-slate-600 bg-slate-800 text-slate-200",
          className,
        )}
        {...props}
      />
    );
  },
);
