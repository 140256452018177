/**
 * Timer that can be used to measure the elapsed time between
 * two points in code.
 *
 * @example
 * ```typescript
 * const timer = new Timer();
 * const timeSince = timer.check();
 * console.log(timeSince);
 * ```
 */
export class Timer {
  private start: number;
  private lastCheck: number;
  private logger: typeof logger | typeof console;

  /**
   * @param name - The name of the timer. Prefixes logs in verbose mode.
   * @param verbose - Whether to log the elapsed time to the console.
   */
  constructor(
    public name: string,
    public verbose = IS_DEVELOPMENT,
  ) {
    this.start = performance.now();
    this.lastCheck = this.start;

    if (typeof self !== "undefined" && "logger" in self) {
      this.logger = self.logger as any;
    } else {
      this.logger = console;
    }
  }

  /**
   * Logs a message to the console if verbose is true.
   * @param message - The message to log.
   */
  log(message: string) {
    if (this.verbose) {
      this.logger.log(`[${this.name}] ${message}`);
    }
  }

  /**
   * Returns the elapsed time since the timer was created.
   * Logs the elapsed time to the console if verbose is true.
   * @returns The elapsed time in milliseconds.
   */
  elapsed() {
    const elapsed = performance.now() - this.start;
    this.log(`Elapsed: ${elapsed}ms`);
    return elapsed;
  }

  /**
   * Logs the elapsed time since the last check to the console if verbose is true.
   * @param label - The label to log.
   * @returns The elapsed time in milliseconds.
   */
  check(label?: string) {
    const now = performance.now();
    const timeSince = now - this.lastCheck;
    this.lastCheck = now;
    this.log(`${label ? `${label}: ` : ""}${timeSince}ms`);
    return timeSince;
  }

  /**
   * Resets the timer to the start time.
   */
  reset() {
    this.start = performance.now();
    this.lastCheck = this.start;
  }
}
