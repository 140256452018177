import Intercom from "@intercom/messenger-js-sdk";

type InitIntercomOptions = {
  email: string;
  user_hash: string;
};

export function initIntercom({ email, user_hash }: InitIntercomOptions) {
  Intercom({
    app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID,
    alignment: "left",
    email,
    user_hash,
  });
}
