import { and, eq, inArray, isNull } from "drizzle-orm";
import { db } from "../../lib/drizzle/db";
import { Tables } from "../../lib/drizzle/schema";
import { LocalStorage } from "../../lib/local-storage";

export async function paymentFetcher(paymentIds: string[]) {
  if (!paymentIds.length) {
    return [];
  }

  const allPaymentsAndBankTransactions = await db
    .select({
      payment: Tables.eobPayments,
      bankTransaction: Tables.bankTransactions,
    })
    .from(Tables.eobPayments)
    .leftJoin(
      Tables.bankTransactions,
      eq(Tables.eobPayments.bankTransactionId, Tables.bankTransactions.id),
    )
    .where(
      and(
        inArray(Tables.eobPayments.id, paymentIds),
        eq(
          Tables.eobPayments.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ),
    )
    .execute();

  const allClaimsResult = await db
    .select({
      eobClaim: Tables.eobClaims,
      ehrClaimId: Tables.eobToEhrClaims.ehrClaimId,
      ehrPatientId: Tables.eobToEhrClaims.ehrPatientId,
    })
    .from(Tables.eobClaims)
    .leftJoin(
      Tables.eobToEhrClaims,
      eq(Tables.eobClaims.id, Tables.eobToEhrClaims.eobClaimId),
    )
    .where(inArray(Tables.eobClaims.paymentId, paymentIds))
    .execute();

  const allClaims = allClaimsResult.map(
    ({ eobClaim, ehrClaimId, ehrPatientId }) => ({
      ...eobClaim,
      ehrClaimId,
      ehrPatientId,
    }),
  );

  const allTasks = await db
    .select()
    .from(Tables.tasks)
    .where(
      and(
        inArray(Tables.tasks.paymentId, paymentIds),
        isNull(Tables.tasks.deletedAt),
      ),
    )
    .execute();

  const results = allPaymentsAndBankTransactions.map(
    ({ payment, bankTransaction }) => {
      const claims = allClaims.filter(
        (claim) => claim.paymentId === payment.id,
      );
      const tasks = allTasks.filter((task) => task.paymentId === payment.id);
      return { paymentId: payment.id, payment, claims, bankTransaction, tasks };
    },
  );

  return results;
}
