import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@lassie/ui/button";
import { EmptyState } from "@lassie/ui/empty-state";
import {
  IconExclamationCircle,
  IconExclamationCircleFilled,
  IconReload,
} from "@tabler/icons-react";
import { cx } from "class-variance-authority";
import { useEffect, useState } from "react";
import { sleep } from "../lib/async";
import {
  type InitializerErrorBlob,
  getInitializerErrorText,
} from "../lib/initializer-types";
import { LoadingTimer } from "./loading-timer";

export enum BootstrapStatus {
  LOADING = "loading",
  SYNCING = "syncing",
  ERROR = "error",
  SUCCESS = "success",
}

export enum LoadingVariant {
  /** cold (long) splash screen, blocked by full bootstrap */
  BOOTSTRAP = "bootstrap",
  /** short splash screen, blocked by initializing SQLite */
  RECOVERY = "recovery",
  /** e.g. client upgrades, server commanded reset, version mismatch */
  UPGRADE = "upgrade",
}

type SplashProps = {
  status: BootstrapStatus;
  variant: LoadingVariant;
  error: InitializerErrorBlob | null;
};

export function Splash({ status, variant, error }: SplashProps) {
  const { logout } = useAuth0();

  const [isHiding, setIsHiding] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    async function hide() {
      setIsHiding(true);

      await sleep(90);
      setIsHidden(true);
    }

    if (
      status === BootstrapStatus.SUCCESS ||
      status === BootstrapStatus.SYNCING
    ) {
      hide();
    }
  }, [status]);

  if (status === BootstrapStatus.ERROR || error !== null) {
    return (
      <div
        className="fixed inset-0 z-40 flex flex-col items-center justify-center gap-8 bg-slate-100"
        data-sentry-unmask
      >
        <EmptyState
          icon={<IconExclamationCircleFilled size={48} />}
          intent="danger"
          title={
            error !== null
              ? getInitializerErrorText(error.type)
              : "An unexpected error occurred."
          }
          actions={[
            {
              children: "Retry",
              variant: "secondary",
              prefix: <IconReload size={16} />,
              onClick: () => {
                window.location.reload();
              },
            },
            {
              children: "Logout",
              className: "hover:!bg-slate-200",
              onClick: () => logout(),
            },
          ]}
        />
      </div>
    );
  }

  if (isHidden) {
    return null;
  }

  return (
    <div
      className={cx(
        "fixed inset-0 z-40 flex scale-100 flex-col items-center justify-center gap-4",
        variant === LoadingVariant.RECOVERY ? "bg-white" : "bg-slate-100",
        isHiding && "animate-[splash-exit_80ms_linear_forwards]",
      )}
      data-sentry-unmask
    >
      <LoadingTimer
        threshold={4000}
        className="-translate-x-1/2 -translate-y-1/2 !h-[400px] !w-[400px] fixed top-1/2 left-1/2 z-50 bg-white/90"
      />
      {variant === LoadingVariant.BOOTSTRAP ||
      variant === LoadingVariant.UPGRADE ? (
        <>
          <img src="/lassie-logo.svg" alt="Lassie Logo" className="h-24" />
          <Button
            className="animate-pulse font-bold [&>svg]:duration-700"
            size="lg"
            variant="ghost"
            loading
          >
            {variant === LoadingVariant.BOOTSTRAP
              ? "Setting up your dashboard..."
              : "Updating Lassie..."}
          </Button>
        </>
      ) : null}
    </div>
  );
}
