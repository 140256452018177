import type { CSSProperties } from "react";
import React from "react";
import * as icons from "../../assets/icons/index";

export type IconName = keyof typeof icons;

export type IconProps = {
  name: IconName;
  className?: string;
  size?: number;
  fill?: string;
};

function iconPropsToStyle(props: IconProps): {
  className?: string;
  style: CSSProperties;
} {
  return {
    className: props.className,
    style: {
      width: props.size,
      height: props.size,
      fill: props.fill,
    },
  };
}

export const Icon: React.FC<IconProps> = React.memo(function Icon(props) {
  const { className, style } = iconPropsToStyle(props);
  const IconComponent = icons[props.name];

  return <IconComponent className={className} style={style} />;
});
