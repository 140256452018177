import z from "zod";

/** Public object metadata */
export const objectMetaSchema = z.object({
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type ObjectMeta = z.infer<typeof objectMetaSchema>;

/** Deletable object metadata */
export const deletableSchema = z.object({
  deletedAt: z.string().nullable(),
});

export type Deletable = z.infer<typeof deletableSchema>;

/** Base ledger item schema */
export const baseLedgerItemSchema = z.object({
  journalEntryId: z.string(),
});

export type BaseLedgerItem = z.infer<typeof baseLedgerItemSchema>;
