import type React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import type { Client } from "../lib/client-types";
import { LocalStorage } from "../lib/local-storage";
import { InboxType } from "../lib/patient-inbox";

const getSessionStorageKey = () => {
  const selectedPractice = LocalStorage.get("selectedPractice");
  if (!selectedPractice) return "patientInbox:practice=NONE";
  return `patientInbox:practice=${selectedPractice}`;
};

export interface PatientInboxFlowState {
  taskIds: string[];
  split: InboxType;
  active: boolean;
}

interface PatientInboxContextProps extends PatientInboxFlowState {
  createFlow: (split: InboxType, tasks: Client.PatientInboxTask[]) => void;
  reset: () => void;
}

function getInitialFlowState(): PatientInboxFlowState {
  const storedFlow = sessionStorage.getItem(getSessionStorageKey());
  if (storedFlow) {
    return JSON.parse(storedFlow);
  }
  return { taskIds: [], split: InboxType.ALL, active: false };
}

const PatientInboxFlowContext = createContext<PatientInboxContextProps | null>(
  null,
);

/**
 * Context that manages the state of the patient inbox flow.
 * It uses sessionStorage to persist the flow state across sessions. The lifecycle is as follows:
 *
 * 1. `createFlow` is called with a split and tasks. The ids of these tasks are persisted in sessionStorage.
 * 2. `markCompleted` is called with a taskId. The taskId is removed from the list of taskIds in sessionStorage.
 * 3. `getNextTaskId` is called to get the next taskId in the flow.
 * 4. `reset` is called to reset the flow state.
 *
 * The flow is considered complete when the list of taskIds is empty.
 *
 * Real task data is not stored in the context, and should be fetched separately (via `usePatientInboxQuery`)
 * @see ../store/disk/api.ts
 */
export const PatientInboxFlowProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [flowState, setFlowState] =
    useState<PatientInboxFlowState>(getInitialFlowState);

  // Save flow state to sessionStorage on change
  useEffect(() => {
    sessionStorage.setItem(getSessionStorageKey(), JSON.stringify(flowState));
  }, [flowState]);

  const createFlow = useCallback(
    (split: InboxType, tasks: Client.PatientInboxTask[]) => {
      setFlowState({
        taskIds: tasks.map((task) => task.id),
        split,
        active: true,
      });
    },
    [],
  );

  const reset = () => {
    setFlowState({ taskIds: [], split: InboxType.ALL, active: false });
  };

  return (
    <PatientInboxFlowContext.Provider
      value={{
        ...flowState,
        createFlow,
        reset,
      }}
    >
      {children}
    </PatientInboxFlowContext.Provider>
  );
};

export const usePatientInboxFlow = (): PatientInboxContextProps => {
  const context = useContext(PatientInboxFlowContext);

  if (!context) {
    throw new Error(
      "usePatientInboxFlow must be used within a PatientInboxFlowProvider",
    );
  }

  return context;
};
