import type { MutationMessages, ResolveMutationMessage } from "@lassie/types";
import { v4 as uuidv4 } from "uuid";
import { socket } from "./socket-proxy";

/**
 * Sends a mutation to the server and returns a promise that resolves to the
 * mutation's response.
 *
 * @param mutation - The mutation to send.
 * @param timeout - The number of milliseconds to wait for the mutation to
 * resolve before timing out (default: 10 seconds)
 * @returns A promise that resolves to the mutation's response.
 */
export async function sendMutation(
  mutation: MutationMessages,
  timeout = 10000,
) {
  if (IS_DEMO) {
    return {
      success: true,
      error: null,
    };
  }

  const mutationUuid = uuidv4();

  const mutationPromise = new Promise<ResolveMutationMessage>(
    (resolve, reject) => {
      socket.on("mutate:resolve", (response) => {
        if (response.uuid === mutationUuid) {
          logger.info(`[mutation/${mutationUuid}] Resolving with`, response);
          resolve(response);
        }
      });

      socket.emit("mutate", {
        ...mutation,
        uuid: mutationUuid,
      });

      setTimeout(() => {
        reject(new Error("Mutation timed out"));
      }, timeout);
    },
  );

  return mutationPromise;
}
