import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import type { DateRange, Mode } from "react-day-picker";

import { IconCalendar, IconX } from "@tabler/icons-react";
import { cx } from "class-variance-authority";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { getDateLabel } from "./date-input";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export type DateRangeInputProps = {
  value: DateRange | undefined;
  onChange: (value: DateRange | undefined) => void;
  placeholder?: string;
  useFriendlyDates?: boolean;
  mode?: "range" | "week" | "month";
  clearable?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "value" | "onChange">;

export function DateRangeInput({
  className,
  value: date,
  onChange: setDate,
  placeholder,
  useFriendlyDates = true,
  mode = "range",
  clearable = false,
}: DateRangeInputProps) {
  return (
    <div className={cx("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="secondary"
            intent="muted"
            prefix={
              <IconCalendar
                size={18}
                strokeWidth={2}
                className="text-slate-500"
              />
            }
            className={cx(
              "!font-medium !justify-start relative text-left",
              !date && "text-slate-500",
            )}
          >
            {getDateRangeLabel(date, mode, useFriendlyDates, placeholder)}
            {clearable && date ? (
              <Button
                size="icon"
                intent="muted"
                variant="ghost"
                className="!bg-white hover:!bg-slate-100 !w-7 !h-7 !text-slate-600 absolute right-1.5 z-10"
                onClick={() => setDate(undefined)}
              >
                <IconX size={18} />
              </Button>
            ) : null}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="!p-0 w-auto bg-white" align="start">
          <Calendar
            autoFocus
            mode="range"
            defaultMonth={date?.from ?? new Date()}
            selected={date}
            required={mode === "week" || mode === "month"}
            onDayClick={
              mode === "week" || mode === "month"
                ? (day) => {
                    if (mode === "week") {
                      const start = startOfWeek(day);
                      const end = endOfWeek(day);
                      setDate({ from: start, to: end });
                    }

                    if (mode === "month") {
                      const start = startOfMonth(day);
                      const end = endOfMonth(day);
                      setDate({ from: start, to: end });
                    }
                  }
                : undefined
            }
            onSelect={mode === "week" || mode === "month" ? () => {} : setDate}
            numberOfMonths={mode === "week" ? 1 : 2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

export function getDateRangeLabel(
  dateRange: DateRange | undefined,
  mode: "range" | "week" | "month",
  useFriendlyDates: boolean,
  placeholder?: string,
) {
  if (!dateRange?.from) {
    return placeholder ?? "Pick a date range";
  }

  if (mode === "week" && dateRange?.from && useFriendlyDates) {
    return `Week of ${format(dateRange?.from, "LLL dd, y")}`;
  }

  if (mode === "month" && dateRange?.from && useFriendlyDates) {
    return `${format(dateRange?.from, "LLLL")} ${format(dateRange?.from, "y")}`;
  }

  if (
    dateRange?.from &&
    // if the end date is not set
    (!dateRange?.to ||
      // if the start and end date are the same
      new Date(dateRange.from).getDate() === new Date(dateRange.to).getDate())
  ) {
    // if the start and end date are the same,
    // just return the formatted start date
    return getDateLabel(dateRange.from, useFriendlyDates);
  }

  if (!dateRange.to) {
    return placeholder ?? "Pick a date";
  }

  return (
    <>
      {format(dateRange.from, "LLL d, y")}{" "}
      <span className="text-slate-500">&mdash;</span>{" "}
      {format(dateRange.to, "LLL d, y")}
    </>
  );
}
