import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@lassie/ui/button";
import * as Sentry from "@sentry/react";
import { cx } from "class-variance-authority";
import { useCallback, useEffect, useState } from "react";
import { BootstrapStatus, Splash } from "../components/splash";
import useInitializer from "../hooks/use-initializer";
import { useEhrName, useUser } from "./slices/user";

type LifecycleManagerProps = {
  children: React.ReactNode;
};

export function LifecycleManager({ children }: LifecycleManagerProps) {
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const user = useUser();

  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.email,
        id: user.uuid,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  const updateToken = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
        logger.debug("[auth] Updated access token");
      } catch (error) {
        logger.error("[auth] Error getting access token:", error);
        if (error instanceof Error && error.message === "login_required") {
          loginWithRedirect();
        }
      }
    }
  }, [getAccessTokenSilently, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    updateToken();
  }, [updateToken]);

  const { status, error, loadingVariant } = useInitializer({ accessToken });

  if (!isAuthLoading && !isAuthenticated) {
    return children;
  }

  return (
    <>
      <SyncIndicator show={status === BootstrapStatus.SYNCING} />
      {children}
      {/* override bootstrap status if auth is loading */}
      <Splash
        status={isAuthLoading ? BootstrapStatus.LOADING : status}
        error={error}
        variant={loadingVariant}
      />
    </>
  );
}

function SyncIndicator({ show }: { show: boolean }) {
  const { ehrName } = useEhrName();

  return (
    <Button
      data-sentry-unblock
      data-sentry-unmask
      as="div"
      intent="muted"
      loading
      className={cx(
        "cursor:default !text-slate-600 !transition-all absolute bottom-[1.125rem] left-3 z-40 select-none border-none duration-150",
        show ? "opacity-100" : "pointer-events-none opacity-0",
      )}
      tooltip={{
        delayDuration: 100,
        content: `Getting up to date with ${ehrName}`,
        align: "start",
        disableHoverableContent: !show,
      }}
    >
      Syncing
    </Button>
  );
}
