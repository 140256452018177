import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Button, type ButtonProps } from "@lassie/ui/button";
import { useEffect } from "react";
import useUserAgent from "use-user-agent";

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Auth0Provider
      domain={import.meta.env.PUBLIC_AUTH0_DOMAIN}
      clientId={import.meta.env.PUBLIC_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/authorize?next=${encodeURIComponent(
          window.location.pathname,
        )}`,
        audience: import.meta.env.PUBLIC_AUTH0_AUDIENCE,
        scope: "read:current_user update:current_user_metadata email",

        // redirect flows need response_type
        response_type: "code",
      }}
      cacheLocation="localstorage"
      useCookiesForTransactions={false}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      {children}
    </Auth0Provider>
  );
}

export function LoginRedirect() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return null;
}

export function LogoutButton(props: ButtonProps<"button">) {
  const { logout } = useAuth0();

  return (
    <Button {...props} onClick={() => logout()}>
      Logout
    </Button>
  );
}
