import type * as Comlink from "comlink";
import SQliteSharedWorkerUrl from "../workers/shared-sqlite?sharedworker&url";

const PROVIDER_REQUEST_TIMEOUT = 1000;

if (!globalThis.SharedWorker) {
  throw new Error("SharedWorker not supported");
}

const sharedWorker = new SharedWorker(SQliteSharedWorkerUrl, {
  type: "module",
  name: "lassie.sqlite-shared-service",
});

/**
 * Generates lock acquisition for a context
 * based on the clientId.
 *
 * iife wrapped to prevent re-initialization of promise
 * TODO: @siraj -- replace with something cleaner...
 */
const acquireContextLock = (() => {
  let p: Promise<void>;

  return (clientId: string) => {
    if (p) {
      return p;
    }

    p = new Promise<void>((resolve) => {
      navigator.locks.request(
        clientId,
        () =>
          new Promise((_) => {
            resolve();
          }),
      );
    });

    return p;
  };
})();

export class SharedService extends EventTarget {
  #serviceName: string;
  #clientId: Promise<string>;
  #portProviderFunc: () => MessagePort | Promise<MessagePort>;

  // this BroadcastChannel is used for client messaging. The provider
  // must have a separate BroadcastChannel in case the instance is
  // both client and provider (i.e. the 'active' tab)
  #clientChannel = new BroadcastChannel("SharedService");

  #onDeactivate: AbortController | null = null;
  #onClose = new AbortController();

  // this is client state to track the provider
  #providerPort: Promise<MessagePort | null>;
  providerCallbacks = new Map<
    string,
    { resolve: (value: any) => void; reject: (reason?: any) => void }
  >();
  #providerCounter = 0;
  #providerChangeCleanup: (() => void)[] = [];

  proxy;

  constructor(
    serviceName: string,
    portProviderFunc: () => MessagePort | Promise<MessagePort>,
  ) {
    super();

    this.#serviceName = serviceName;
    this.#portProviderFunc = portProviderFunc;

    this.#clientId = this.#getClientId();

    // Connect to the current provider and future providers.
    this.#providerPort = this.#providerChange();
    this.#clientChannel.addEventListener(
      "message",
      ({ data }) => {
        if (
          data?.type === "provider" &&
          data?.sharedService === this.#serviceName
        ) {
          // A context (possibly this one) announced itself as the new provider.
          // Discard any old provider and connect to the new one.
          this.#closeProviderPort(this.#providerPort);
          this.#providerPort = this.#providerChange();
        }
      },
      { signal: this.#onClose.signal },
    );

    this.proxy = this.#createProxy();
  }

  // manages provider state
  activate() {
    if (this.#onDeactivate) {
      logger.info(
        "[shared-service-debug] already activated, skipping",
        this.#onDeactivate,
      );
      return;
    }

    // one acquiring a lock on the service name, we become the service
    // provider. only one instance at a time will get the lock; the rest
    // will wait their turn.
    this.#onDeactivate = new AbortController();

    navigator.locks.request(
      `SharedService-${this.#serviceName}`,
      { signal: this.#onDeactivate.signal },
      async () => {
        logger.info(
          "[shared-service-debug] activating *LOCK ACQUIRED*",
          this.#serviceName,
        );

        // get the port to request client ports
        const port = await this.#portProviderFunc();
        port.start();

        // listen for client requests
        //
        // note: a separate BroadcastChannel instance is necessary because
        // we may be serving our own  request.
        const providerId = await this.#clientId;
        const broadcastChannel = new BroadcastChannel("SharedService");
        broadcastChannel.addEventListener(
          "message",
          async (event: MessageEvent) => {
            const { data } = event;

            if (
              data?.type === "request" &&
              data?.sharedService === this.#serviceName
            ) {
              // get a port to send to the client.
              const requestedPort = await new Promise<MessagePort>(
                (resolve) => {
                  port.addEventListener(
                    "message",
                    (event) => {
                      resolve(event.ports[0]);
                    },
                    { once: true },
                  );
                  port.postMessage(data.clientId);
                },
              );

              this.#sendPortToClient(data, requestedPort);
            }
          },
          // biome-ignore lint/style/noNonNullAssertion: checked in closure
          { signal: this.#onDeactivate!.signal },
        );

        // broadcast to all clients (tabs) that we are the new provider
        broadcastChannel.postMessage({
          type: "provider",
          sharedService: this.#serviceName,
          providerId,
        });

        // release the lock only on user abort or context destruction.
        return new Promise((_, reject) => {
          if (this.#onDeactivate === null) {
            // if there is no abort controller, there is nothing to do
            return;
          }

          this.#onDeactivate.signal.addEventListener("abort", () => {
            broadcastChannel.close();
            // biome-ignore lint/style/noNonNullAssertion: checked in closure
            reject(this.#onDeactivate!.signal.reason);
          });
        });
      },
    );
  }

  deactivate() {
    this.#onDeactivate?.abort();
    this.#onDeactivate = null;
  }

  close() {
    this.deactivate();
    this.#onClose.abort();
    for (const { reject } of this.providerCallbacks.values()) {
      reject(new Error("SharedService closed"));
    }
  }

  async #sendPortToClient(message: any, port: MessagePort) {
    sharedWorker.port.postMessage(message, [port]);
  }

  async #getClientId() {
    // Use a Web Lock to determine our clientId.
    const nonce = Math.random().toString();
    const clientId = await navigator.locks.request(nonce, async () => {
      const { held } = await navigator.locks.query();

      if (!held) {
        return undefined;
      }

      return held.find((lock) => lock.name === nonce)?.clientId;
    });

    // acquire a Web Lock named after the clientId. This lets other contexts
    // track this context's lifetime.
    //
    // TODO: change to lock on the clientId+serviceName (passing that lock
    // name in the service request). That way we can track instance
    // lifetime.
    await acquireContextLock(clientId);

    // Configure message forwarding via the SharedWorker. This must be
    // done after acquiring the clientId lock to avoid a race condition
    // in the SharedWorker.
    sharedWorker.port.addEventListener("message", (event) => {
      event.data.ports = event.ports;
      this.dispatchEvent(new MessageEvent("message", { data: event.data }));
    });
    sharedWorker.port.start();
    sharedWorker.port.postMessage({ clientId });

    return clientId;
  }

  async #providerChange() {
    // multiple calls to this function could be in flight at once. In that
    // case, we only care about the most recent call, i.e. the one
    // assigned to this.#providerPort.
    //
    // this counter lets us determine whether this call is still the most recent.
    const providerCounter = ++this.#providerCounter;

    // Obtain a MessagePort from the provider. The request can fail during
    // a provider transition, so retry until successful.
    let providerPort: MessagePort | null = null;
    const clientId = await this.#clientId;
    while (!providerPort && providerCounter === this.#providerCounter) {
      // Broadcast a request for the port.
      const nonce = randomString();
      this.#clientChannel.postMessage({
        type: "request",
        nonce,
        sharedService: this.#serviceName,
        clientId,
      });

      // wait for the provider to respond (via the service worker) or
      // timeout. Timeouts can occur if there is no provider to receive
      // the broadcast or if the provider is too busy.
      const providerPortReady = new Promise<MessagePort>((resolve) => {
        const abortController = new AbortController();
        this.addEventListener(
          "message",
          (e) => {
            // TODO: fix class extension to allow correct typing
            const event = e as MessageEvent;

            if (event.data?.nonce === nonce) {
              resolve(event.data.ports[0]);
              abortController.abort();
            }
          },
          { signal: abortController.signal },
        );
        this.#providerChangeCleanup.push(() => abortController.abort());
      });

      providerPort = await Promise.race([
        providerPortReady,
        new Promise<null>((resolve) =>
          setTimeout(() => resolve(null), PROVIDER_REQUEST_TIMEOUT),
        ),
      ]);

      if (!providerPort) {
        // the provider request timed out. If it does eventually arrive
        // just close it and move on
        providerPortReady.then((port) => port?.close());
      }
    }

    if (providerPort && providerCounter === this.#providerCounter) {
      // clean up all earlier attempts to get the provider port.
      this.#providerChangeCleanup.forEach((f) => f());
      this.#providerChangeCleanup = [];

      // port configuration
      providerPort.addEventListener("message", ({ data }) => {
        const callbacks = this.providerCallbacks.get(data.nonce);

        if (!callbacks) {
          // if we don't have any callbacks for this nonce, then this request is
          // obsolete because a new provider has been elected.
          return;
        }

        if (!data.error) {
          callbacks.resolve(data.result);
        } else {
          callbacks.reject(Object.assign(new Error(), data.error));
        }
      });
      providerPort.start();
      return providerPort;
    }

    // if we got here either there is no port because this request timed out,
    // or there is a port but it is already obsolete because a new provider has
    // announced itself
    providerPort?.close();
    return null;
  }

  #closeProviderPort(providerPort: Promise<MessagePort | null>) {
    providerPort.then((port) => port?.close());
    for (const { reject } of this.providerCallbacks.values()) {
      reject(new Error("SharedService provider change"));
    }
  }

  #createProxy() {
    return new Proxy(
      {},
      {
        get: (_, method) => {
          return async (...args: any[]) => {
            // use a nonce to match up requests and responses. This allows
            // the responses to be out of order.
            const nonce = randomString();

            const providerPort = await this.#providerPort;
            return new Promise((resolve, reject) => {
              this.providerCallbacks.set(nonce, { resolve, reject });

              if (providerPort) {
                providerPort.postMessage({ nonce, method, args });
              } else {
                logger.warn("No provider available. Could not call ", method);
              }
            }).finally(() => {
              this.providerCallbacks.delete(nonce);
            });
          };
        },
      },
    );
  }
}

/**
 * Wrap a target (object or Comlink proxy) with MessagePort for proxying.
 * @param {object | Comlink.Remote<any>} target
 * @returns {MessagePort}
 */
export function createSharedServicePort(
  target: object | Comlink.Remote<any>,
): MessagePort {
  const { port1: providerPort1, port2: providerPort2 } = new MessageChannel();

  providerPort1.addEventListener("message", ({ data: clientId }) => {
    const { port1, port2 } = new MessageChannel();

    // the port requester holds a lock while using the channel. When the
    // lock is released by the requester, clean up the port on this side.
    navigator.locks.request(clientId, () => {
      port1.close();
    });

    port1.addEventListener("message", async ({ data }) => {
      const response: { nonce: any; result?: any; error?: any } = {
        nonce: data.nonce,
      };

      //
      const method = data?.method as keyof typeof target;
      try {
        if (typeof target[method] === "function") {
          // Types are not inferred by TS with this condition
          const fn = target[method] as (...args: any[]) => Promise<any>;

          // This works for both Comlink proxies and regular objects with methods
          response.result = await fn(...data.args);
        } else {
          throw new Error(`Method ${data.method} not found on target`);
        }
      } catch (e) {
        // Error is not structured cloneable so copy into POJO.
        const error =
          e instanceof Error
            ? Object.fromEntries(
                Object.getOwnPropertyNames(e).map((k) => [
                  k,
                  e[k as keyof Error],
                ]),
              )
            : e;
        response.error = error;
      }

      // TODO: use structured cloning to handle non-cloneable objects, for now be mindful...
      // https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
      port1.postMessage(response);
    });

    port1.start();
    providerPort1.postMessage(null, [port2]);
  });

  providerPort1.start();
  return providerPort2;
}

/**
 * generates a random (enough) string.
 */
function randomString() {
  return Math.random().toString(36).replace("0.", "");
}
