import * as Sentry from "@sentry/react";
import type { Router } from "@tanstack/react-router";
import { LocalStorage } from "./local-storage";

type SentryInitOptions = {
  enabled: boolean;
  router: Router<any, any, any, any>;
};

const forceReplay = LocalStorage.get("__force_replay");

export const initSentry = (options: SentryInitOptions) => {
  if (!options.enabled) {
    // Sentry has an `enabled` flag, but we prefer running with
    // no overhead, instead of only not reporting.
    return;
  }

  Sentry.init({
    release: __RELEASE_NAME__,
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        unblock: [".sentry-unblock, [data-sentry-unblock]"],
        unmask: [".sentry-unmask, [data-sentry-unmask]"],
        beforeErrorSampling: (event) => {
          // filter out internal user emails
          if (!forceReplay && event.user?.email?.endsWith("@golassie.com")) {
            return false;
          }

          return true;
        },
      }),
      Sentry.captureConsoleIntegration({
        levels: ["warn", "error"],
      }),
      Sentry.tanstackRouterBrowserTracingIntegration(options.router),
    ],
    // Tracing
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost", // For local development
      /^https:\/\/product-api\.golassie\.com/, // For the production server
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
};
