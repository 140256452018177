import { useLocation, useRouter } from "@tanstack/react-router";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { type Crumb, HistoryManager } from "../lib/history/history-manager";

const HistoryContext = createContext<{
  historyBreadcrumbs: Crumb[];
  manager: HistoryManager;
}>({
  historyBreadcrumbs: [],
  manager: {} as HistoryManager,
});

export const useHistory = () => {
  return useContext(HistoryContext);
};

class BreadcrumbStorageManager {
  private cache: Record<string, Crumb[]> = {};

  constructor(private readonly key: string) {
    try {
      this.cache = JSON.parse(sessionStorage.getItem(this.key) ?? "{}");
    } catch (error) {
      console.warn("error parsing breadcrumb cache", error);
      this.cache = {};
    }
  }

  getBreadcrumbsFromCache(route: string) {
    if (!this.cache[route]) {
      return null;
    }

    return this.cache[route];
  }

  set(route: string, crumbs: Crumb[]) {
    this.cache[route] = crumbs;
    sessionStorage.setItem(this.key, JSON.stringify(this.cache));
  }
}

const breadcrumbStorageManager = new BreadcrumbStorageManager(
  "historyBreadcrumbs",
);

export function HistoryProvider({ children }: { children: React.ReactNode }) {
  const [historyBreadcrumbs, setHistoryBreadcrumbs] = useState<Crumb[]>([]);
  const manager = useMemo(() => new HistoryManager(), []);
  const router = useRouter();

  useEffect(() => {
    const storedBreadcrumbs = breadcrumbStorageManager.getBreadcrumbsFromCache(
      router.history.location.pathname,
    );
    if (storedBreadcrumbs) {
      setHistoryBreadcrumbs(storedBreadcrumbs);
    } else {
      manager.getHistoryBreadcrumbs().then(setHistoryBreadcrumbs);
    }

    const unlisten = router.history.subscribe(() => {
      logger.info("[tanstack/sub] history changed", router.history.location);
      manager.push(router.history.location);
      manager.getHistoryBreadcrumbs().then((breadcrumbs) => {
        breadcrumbStorageManager.set(
          router.history.location.pathname,
          breadcrumbs,
        );
        setHistoryBreadcrumbs(breadcrumbs);
      });
    });

    return () => {
      unlisten();
    };
  }, [router.history]);

  const ctx = useMemo(
    () => ({ historyBreadcrumbs, manager }),
    [historyBreadcrumbs, manager],
  );

  return (
    <HistoryContext.Provider value={ctx}>{children}</HistoryContext.Provider>
  );
}

/**
 * Model Inputs
 */
export namespace Breadcrumb {
  export type EobPayment = {
    type: "eobPayment";
    paymentId: string;

    paymentLabel: string;
  };

  export type EobClaim = {
    type: "eobClaim";
    paymentId: string;
    eobClaimId: string;

    paymentLabel: string;
    claimNumber: string;
  };

  export type Patient = {
    type: "patient";
    patientId: string;

    patientName: string;
  };

  export type Family = {
    type: "family";
    familyId: string;

    familyName: string;
  };

  export type Visit = {
    type: "visit";
    patientId: string;
    ehrClaimId: string;

    visitLabel: string;
    patientName: string;
  };

  export type Input = EobPayment | EobClaim | Patient | Visit | Family;
}

export function useBreadcrumbs(input?: Breadcrumb.Input) {
  const { pathname } = useLocation();
  const { historyBreadcrumbs, manager } = useHistory();

  const mergedBreadcrumbs = useMemo(() => {
    if (!input) {
      return historyBreadcrumbs;
    }

    const inputBreadcrumbs = inputToCrumb(input, pathname);

    if (
      input.type === "visit" &&
      ["Payments", "Posted"].includes(historyBreadcrumbs[0]?.label)
    ) {
      manager.flush();
      // if coming from a payments route and going to a visit that is a reset event
      // we can't handle it with the history manager alone
      return [
        {
          label: "Patients",
          to: "/patients",
        },
        ...inputBreadcrumbs,
      ];
    }

    if (
      input.type === "family" &&
      ["Balances"].includes(historyBreadcrumbs[0]?.label)
    ) {
      manager.flush();
      // same thing for family from patient ledger
      return [
        {
          label: "Patients",
          to: "/patients",
        },
        {
          label: "Balances",
          to: "/patients/balances",
        },
        ...inputBreadcrumbs,
      ];
    }

    if (
      input.type === "eobPayment" &&
      historyBreadcrumbs[0]?.label === "Patients"
    ) {
      manager.flush();
      // same thing for going to payment from a ledger
      return [
        {
          label: "Payments",
          to: "/payments",
        },
        ...inputBreadcrumbs,
      ];
    }

    return mergeBreadcrumbs(historyBreadcrumbs, inputBreadcrumbs);
  }, [historyBreadcrumbs, input, pathname]);

  return mergedBreadcrumbs;
}

function mergeBreadcrumbs(
  historyBreadcrumbs: Crumb[],
  inputBreadcrumbs: Crumb[],
) {
  const mergedBreadcrumbs: Crumb[] = [];

  for (const crumb of historyBreadcrumbs) {
    if (crumb.to === inputBreadcrumbs[0].to) {
      break;
    }

    mergedBreadcrumbs.push(crumb);
  }

  mergedBreadcrumbs.push(...inputBreadcrumbs);

  return mergedBreadcrumbs;
}

function inputToCrumb(input: Breadcrumb.Input, pathname: string): Crumb[] {
  if (input.type === "eobPayment") {
    return [
      {
        label: input.paymentLabel,
        to: pathname,
      },
    ];
  }

  if (input.type === "eobClaim") {
    return [
      {
        label: input.paymentLabel,
        to: `/payments/${input.paymentId}`,
      },
      {
        label: `Claim ${input.claimNumber}`,
        to: pathname,
      },
    ];
  }

  if (input.type === "patient") {
    return [
      {
        label: input.patientName,
        to: pathname,
      },
    ];
  }

  if (input.type === "visit") {
    return [
      {
        label: input.visitLabel,
        to: pathname,
      },
    ];
  }

  if (input.type === "family") {
    return [
      {
        label: input.familyName,
        to: pathname,
      },
    ];
  }

  console.warn("unsupported breadcrumb input", input);
  return [];
}
