import type React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import type { Client } from "../lib/client-types";
import { LocalStorage } from "../lib/local-storage";
import { PaymentsInboxType } from "../lib/payment-inbox";

const getSessionStorageKey = () => {
  const selectedPractice = LocalStorage.get("selectedPractice");
  if (!selectedPractice) return "paymentInbox:practice=NONE";
  return `paymentInbox:practice=${selectedPractice}`;
};

export interface PaymentInboxFlowState {
  paymentIds: string[];
  split: PaymentsInboxType;
  active: boolean;
}

interface PaymentInboxContextProps extends PaymentInboxFlowState {
  createFlow: (
    split: PaymentsInboxType,
    inboxPayments: Client.InboxPayment[],
  ) => void;
  reset: () => void;
  disable: () => void;
  enable: () => void;
}

function getInitialFlowState(): PaymentInboxFlowState {
  const storedFlow = sessionStorage.getItem(getSessionStorageKey());
  if (storedFlow) {
    return JSON.parse(storedFlow);
  }
  return { paymentIds: [], split: PaymentsInboxType.ALL, active: false };
}

const PaymentInboxFlowContext = createContext<PaymentInboxContextProps | null>(
  null,
);

/**
 * Context that manages the state of the patient inbox flow.
 * It uses sessionStorage to persist the flow state across sessions. The lifecycle is as follows:
 *
 * 1. `createFlow` is called with a split and tasks. The ids of these tasks are persisted in sessionStorage.
 * 2. `markCompleted` is called with a taskId. The taskId is removed from the list of taskIds in sessionStorage.
 * 3. `getNextTaskId` is called to get the next taskId in the flow.
 * 4. `reset` is called to reset the flow state.
 *
 * The flow is considered complete when the list of taskIds is empty.
 *
 * Real task data is not stored in the context, and should be fetched separately (via `usePatientInboxQuery`)
 * @see ../store/disk/api.ts
 */
export const PaymentInboxFlowProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [flowState, setFlowState] =
    useState<PaymentInboxFlowState>(getInitialFlowState);

  // Save flow state to sessionStorage on change
  useEffect(() => {
    sessionStorage.setItem(getSessionStorageKey(), JSON.stringify(flowState));
  }, [flowState]);

  const createFlow = useCallback(
    (split: PaymentsInboxType, inboxPayments: Client.InboxPayment[]) => {
      setFlowState({
        paymentIds: inboxPayments.map(
          (inboxPayment) => inboxPayment.payment.id,
        ),
        split,
        active: true,
      });
    },
    [],
  );

  const reset = () => {
    setFlowState({
      paymentIds: [],
      split: PaymentsInboxType.ALL,
      active: false,
    });
  };

  const disable = () => {
    setFlowState((prev) => ({ ...prev, active: false }));
  };

  const enable = () => {
    setFlowState((prev) => ({ ...prev, active: true }));
  };

  return (
    <PaymentInboxFlowContext.Provider
      value={{
        ...flowState,
        createFlow,
        reset,
        disable,
        enable,
      }}
    >
      {children}
    </PaymentInboxFlowContext.Provider>
  );
};

export const usePaymentInboxFlow = (): PaymentInboxContextProps => {
  const context = useContext(PaymentInboxFlowContext);

  if (!context) {
    throw new Error(
      "usePaymentInboxFlow must be used within a PaymentInboxFlowProvider",
    );
  }

  return context;
};
