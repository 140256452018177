import * as demo from "./demo";
import * as live from "./live";

export const {
  handleInsuranceLogins,
  handleBankAccounts,
  handleClearRecentSearch,
  handleCompleteBankLink,
  handleCreateBankLink,
  handleRecentSearches,
  handleRemoveBankLink,
  handleSearch,
} = IS_DEMO ? demo : live;
