import z from "zod";
import { objectMetaSchema } from "./generic";

export const bankTransactionSchema = z.object({
  id: z.string(),
  accountName: z.string(),
  institutionName: z.string(),
  status: z.enum(["PENDING", "POSTED"]),
  accruedAt: z.string(),
  ...objectMetaSchema.shape,
});
export type BankTransaction = z.infer<typeof bankTransactionSchema>;
