import { type Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { TooltipProvider } from "@lassie/ui/tooltip";
import { captureException } from "@sentry/react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { AuthProvider } from "./components/auth";
import { ErrorFallback } from "./components/common/error-fallback";
import { NotFound } from "./components/common/not-found";
import { Toaster } from "./components/toaster";
import { SidebarProvider } from "./context/sidebar-context";
import { PatientInboxFlowProvider } from "./hooks/use-patient-inbox-flow";
import { PaymentInboxFlowProvider } from "./hooks/use-payment-inbox-flow-2";
import type { History } from "./lib/history/history-types";
import { LocalStorage } from "./lib/local-storage";
import { initSentry } from "./lib/sentry";
import { routeTree } from "./routeTree.gen";
import { LifecycleManager } from "./store/lifecycle-manager";
import { store } from "./store/root";

const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultStaleTime: 5000,
  context: {
    // auth will initially be undefined
    auth: {} as Auth0ContextInterface,
  },
  defaultErrorComponent: ErrorFallback,
  defaultNotFoundComponent: NotFound,
});

initSentry({
  enabled: !IS_DEVELOPMENT && !IS_DEMO,
  router,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }

  interface HistoryState {
    from?: History.From;
  }
}

const debugFlag = LocalStorage.get("__lassie_debug");
const isDebug = (IS_DEVELOPMENT && debugFlag !== false) || debugFlag === true;

/**
 * lazy load the debugger in development mode
 */
const Debugger = isDebug
  ? lazy(() =>
      import("./components/internal/debugger").then((module) => ({
        default: module.Debugger,
      })),
    )
  : () => null;

function RouterWithAuth() {
  const auth = useAuth0();
  return (
    <RouterProvider
      router={router}
      context={{ auth }}
      defaultPreload="intent"
    />
  );
}

// TODO: should be provided by the environment
const isStaging = window.location.hostname.endsWith("lassie.dev");

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        {IS_DEVELOPMENT && <link rel="icon" href="/favicon-dev.svg" />}
        {isStaging && <link rel="icon" href="/favicon-staging.svg" />}
      </Helmet>

      <TooltipProvider delayDuration={50}>
        <SidebarProvider>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(e) => captureException(e)}
          >
            <AuthProvider>
              <Provider store={store}>
                <Toaster />
                <LifecycleManager>
                  <PatientInboxFlowProvider>
                    <PaymentInboxFlowProvider>
                      <RouterWithAuth />
                    </PaymentInboxFlowProvider>
                  </PatientInboxFlowProvider>
                </LifecycleManager>
                <Debugger />
              </Provider>
            </AuthProvider>
          </ErrorBoundary>
        </SidebarProvider>
      </TooltipProvider>
    </HelmetProvider>
  );
}

export default App;
