import type {
  BankSource,
  InsuranceLogin,
  SearchResultItem,
} from "@lassie/types";
import type { DiskAction } from "../types";

export async function handleInsuranceLogins(
  _payload: DiskAction.InsuranceLogins["options"],
): Promise<{ insuranceLogins: InsuranceLogin[] }> {
  return {
    insuranceLogins: [
      {
        id: "insurance-login-1",
        source: "DeltaDental.com",
        sourceName: "DELTA_DENTAL_INS",
        username: "dentist@example.com",
        password: "superSecurePassword1",
        error: null,
      },
      {
        id: "insurance-login-2",
        sourceName: "DENTAL_XCHANGE",
        source: "DentalXChange.com",
        username: "dentist@example.com",
        password: "superSecurePassword2",
        error: null,
      },
      {
        id: "insurance-login-3",
        sourceName: "AETNA_PORTAL",
        source: "AetnaDental.com",
        username: "dentist@example.com",
        password: "superSecurePassword3",
        error: null,
      },
      {
        id: "insurance-login-4",
        sourceName: "UHC_EPAYMENTS",
        source: "UHC ePayment Center",
        username: "dentist@example.com",
        password: "superSecurePassword4",
        error: null,
      },
    ],
  };
}

export async function handleBankAccounts(
  _payload: DiskAction.BankAccounts["options"],
): Promise<{ bankSources: BankSource[] }> {
  return {
    bankSources: [
      {
        id: 1,
        logo: null,
        createdAt: "2024-01-01",
        institutionName: "Bank of America",
        status: "ACTIVE",
        vendor: "PLAID",
        accounts: [
          {
            name: "Bank of America Checking",
            type: "CHECKING",
            mask: "1234",
            ignoredAt: null,
          },
        ],
      },
    ],
  };
}

export async function handleCreateBankLink(
  payload: DiskAction.CreateBankLink["options"],
): Promise<DiskAction.CreateBankLink["response"]> {
  return { link_token: "test", expiration: "test", request_id: "test" };
}

export async function handleCompleteBankLink(
  payload: DiskAction.CompleteBankLink["options"],
): Promise<DiskAction.CompleteBankLink["response"]> {
  return null;
}

export async function handleRemoveBankLink(
  payload: DiskAction.RemoveBankLink["options"],
): Promise<DiskAction.RemoveBankLink["response"]> {
  return null;
}

export async function handleSearch(
  payload: DiskAction.Search["options"],
): Promise<{ results: SearchResultItem[]; query: string }> {
  if (!payload.query) {
    throw new Error("Query is required");
  }

  return { results: [], query: "" };
}

export async function handleClearRecentSearch(
  payload: DiskAction.ClearRecentSearch["options"],
): Promise<DiskAction.ClearRecentSearch["response"]> {
  return { success: true };
}

export async function handleRecentSearches(): Promise<
  DiskAction.RecentSearches["response"]
> {
  return [];
}
