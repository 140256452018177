import type { Socket as SocketEvents } from "@lassie/types";
import { create, keyResolver, windowScheduler } from "@yornaath/batshit";
import { LocalStorage } from "../../lib/local-storage";
import { socket } from "../../lib/socket-proxy";
import { enterLedgers, enterPayments } from "../../lib/transfer";
import { ledgerFetcher } from "./ledger-fetcher";
import { paymentFetcher } from "./payment-fetcher";

/** Window for batching actions in milliseconds */
const BATCH_WINDOW = 250;

export const ledgersFromDisk = create({
  fetcher: ledgerFetcher,
  resolver: keyResolver("patientId"),
  scheduler: windowScheduler(BATCH_WINDOW),
  name: "batcher:ledgers-disk",
});

export const ledgersFromNetwork = create({
  fetcher: async (patientIds: string[]) => {
    if (IS_DEMO) {
      return [];
    }

    // biome-ignore lint/style/noNonNullAssertion: TEMP
    const selectedPractice = LocalStorage.get("selectedPractice")!;
    await emitWithAwaitableAck(
      "ledgers",
      {
        patientIds,
        selectedPractice,
      },
      (result) => {
        if (result.success) {
          enterLedgers(result.result, selectedPractice);
        } else {
          console.error("Error fetching ledgers", result.error);
        }
      },
    );

    return [];
  },
  resolver: () => [], // we don't need to resolve anything here
  scheduler: windowScheduler(BATCH_WINDOW),
  name: "batcher:ledgers-network",
});

export const paymentsFromDisk = create({
  fetcher: paymentFetcher,
  resolver: keyResolver("paymentId"),
  scheduler: windowScheduler(BATCH_WINDOW),
  name: "batcher:payments-disk",
});

export const paymentsFromNetwork = create({
  fetcher: async (paymentIds: string[]) => {
    if (IS_DEMO) {
      return [];
    }

    // biome-ignore lint/style/noNonNullAssertion: TEMP
    const selectedPractice = LocalStorage.get("selectedPractice")!;

    await emitWithAwaitableAck(
      "payments",
      {
        eobPaymentIds: paymentIds,
        selectedPractice,
      },
      (result) => {
        if (result.success) {
          enterPayments(result.result, selectedPractice);
        } else {
          console.error("Error fetching payments", result.error);
        }
      },
    );

    return [];
  },
  resolver: () => [], // we don't need to resolve anything here
  scheduler: windowScheduler(BATCH_WINDOW),
  name: "batcher:payments-network",
});

/**
 * Wrapper around socket.emit. Returns a promise that resolves
 * when the ack handler is run.
 */
function emitWithAwaitableAck<Event extends "ledgers" | "payments">(
  event: Event,
  data: Parameters<SocketEvents.ClientEvents[Event]>[0],
  callback: Parameters<SocketEvents.ClientEvents[Event]>[1],
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const innerArgs = [
      data,
      (...args: any[]) => {
        try {
          // @ts-ignore
          callback(...args);
          resolve();
        } catch (error) {
          reject(error);
        }
      },
    ] as any;

    socket.emit(event, ...innerArgs);
  });
}
