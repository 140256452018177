import type { Auth0ContextInterface } from "@auth0/auth0-react";
import {
  Outlet,
  ScrollRestoration,
  createRootRouteWithContext,
} from "@tanstack/react-router";
import { lazy } from "react";
import { HistoryProvider } from "../components/history";

const LazyTanStackRouterDevtools = lazy(() =>
  IS_DEVELOPMENT
    ? import("@tanstack/router-devtools").then((module) => ({
        default: module.TanStackRouterDevtools,
      }))
    : Promise.resolve({ default: () => null }),
);

interface RootContext {
  auth: Auth0ContextInterface;
}

export const Route = createRootRouteWithContext<RootContext>()({
  component: RootComponent,
});

function RootComponent() {
  return (
    <HistoryProvider>
      <Outlet />
      <ScrollRestoration />
      <LazyTanStackRouterDevtools
        toggleButtonProps={{ className: "mb-[190px]" }}
      />
    </HistoryProvider>
  );
}
