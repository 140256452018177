"use client";

import type {
  TooltipContentProps,
  TooltipProps,
} from "@radix-ui/react-tooltip";
import { IconArrowBigUp, IconCommand } from "@tabler/icons-react";
import { type VariantProps, cva } from "class-variance-authority";
import { cx } from "class-variance-authority";
import { forwardRef } from "react";
import type { ComponentPropsWithRef, ElementType, ReactNode, Ref } from "react";
import useUserAgent from "use-user-agent";
import { Kbd } from "./kbd";
import type { PolymorphicComponentProps } from "./lib/types";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "./tooltip";

type BaseButtonTooltipProps = TooltipProps & {
  align?: TooltipContentProps["align"];
  className?: string;
};

// TODO merge with shortcut tooltip props
type ContentButtonTooltipProps = BaseButtonTooltipProps & {
  content: React.ReactNode;
};

type ShortcutButtonTooltipProps = BaseButtonTooltipProps & {
  shortcut: string[];
  content?: React.ReactNode;
};

export type ButtonTooltipProps =
  | ContentButtonTooltipProps
  | ShortcutButtonTooltipProps
  | ReactNode;

export const buttonVariants = cva(
  [
    "inline-flex select-none items-center justify-center whitespace-nowrap border font-semibold leading-none ring-offset-background transition-colors",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
    "disabled:pointer-events-none disabled:opacity-60",
    "py-[--btn-py] pr-[var(--btn-pr,var(--btn-px))] pl-[var(--btn-pl,var(--btn-px))]",
  ],
  {
    variants: {
      intent: {
        default: "",
        danger: "",
        muted: "",
        info: "",
      },
      variant: {
        primary: "",
        outline: "",
        secondary: "",
        ghost: "border-none bg-transparent font-medium",
        link: "border-none bg-none text-primary underline-offset-4 hover:underline",
      },
      size: {
        xs: "h-7 gap-[0.375rem] rounded text-xs [--btn-px:0.625rem] [--btn-py:0.5rem]",
        default:
          "h-9 gap-1.5 rounded-md text-sm [--btn-px:0.75rem] [--btn-py:0.5rem]",
        sm: "h-8 gap-1 rounded-md text-sm [--btn-px:0.75rem] [--btn-py:0.5rem]",
        lg: "h-10 gap-3 rounded-lg text-lg [--btn-px:0.875rem] [--btn-py:0.75rem]",
        icon: "!p-0 h-8 w-8 rounded-md",
      },
      rounded: {
        default: "",
        full: "!rounded-full",
      },
      hasPrefix: {
        true: "[--btn-pl:calc(var(--btn-px)-0.125rem)]",
        false: "",
      },
      hasSuffix: {
        true: "[--btn-pr:calc(var(--btn-px)-0.125rem)]",
        false: "",
      },
    },
    compoundVariants: [
      // primary variants
      {
        intent: "default",
        variant: "primary",
        className:
          "border-brand-800/60 bg-brand-700 text-white shadow-black/[0.04] shadow-sm hover:bg-brand-700/90",
      },
      {
        intent: "info",
        variant: "primary",
        className:
          "border-blue-700/60 bg-blue-600 text-white shadow-black/[0.04] shadow-sm hover:bg-blue-600/90",
      },
      {
        intent: "danger",
        variant: "primary",
        className:
          "border-red-700/70 bg-red-600 text-white shadow-black/[0.04] shadow-sm hover:bg-red-600/90",
      },
      // secondary variants
      {
        intent: "default",
        variant: "secondary",
        className:
          "border border-slate-300 bg-slate-50 text-slate-800 shadow-sm hover:bg-slate-200",
      },
      {
        intent: "info",
        variant: "secondary",
        className: "border-blue-100 bg-blue-50 text-blue-800 hover:bg-blue-200",
      },
      {
        intent: "danger",
        variant: "secondary",
        className:
          "border-red-200/80 bg-red-100 text-red-800 shadow-sm hover:border-red-200 hover:bg-red-200",
      },
      {
        intent: "muted",
        variant: "secondary",
        className: "border-slate-200 bg-white text-slate-800",
      },
      // ghost variants
      {
        intent: "default",
        variant: "ghost",
        className: "text-brand-700 hover:bg-brand-100",
      },
      {
        intent: "muted",
        variant: "ghost",
        className: "text-slate-600 hover:bg-slate-100",
      },
      {
        intent: "danger",
        variant: "ghost",
        className: "text-red-500 hover:bg-red-50",
      },
    ],
    defaultVariants: {
      intent: "default",
      variant: "secondary",
      size: "default",
      rounded: "default",
      hasPrefix: false,
      hasSuffix: false,
    },
  },
);

type ButtonBaseProps = {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  loading?: boolean;
  unset?: boolean;
  tooltip?: React.ReactNode | ButtonTooltipProps;
} & VariantProps<typeof buttonVariants>;

export type ButtonProps<C extends ElementType = "button"> =
  PolymorphicComponentProps<C, ButtonBaseProps>;

export const Button = forwardRef(
  <E extends ElementType = "button">(
    { as, ...props }: ButtonProps<E>,
    ref: Ref<ComponentPropsWithRef<E>["ref"]>,
  ) => {
    const {
      // content
      children,
      prefix,
      suffix,
      className,
      loading,
      disabled,
      tooltip: tooltipProps,

      // variants
      intent,
      variant,
      rounded,
      size,
      unset,

      // component props
      ...componentProps
    } = props;

    const { data } = useUserAgent(navigator.userAgent, {
      useHighEntropyResult: false,
    });

    const isAppleDevice = data?.device?.vendor === "Apple";

    const tooltip = tooltipProps as ButtonTooltipProps;
    const Component = as ?? "button";

    const buttonInner = (
      <Component
        ref={ref}
        className={cx(
          !unset &&
            buttonVariants({
              intent,
              variant,
              rounded,
              size,
              hasPrefix: !!prefix,
              hasSuffix: !!suffix,
            }),
          className,
        )}
        disabled={loading || disabled}
        {...componentProps}
      >
        {loading ? <LoadingIcon /> : prefix ? <span>{prefix}</span> : null}
        {children}
        {suffix ? <span>{suffix}</span> : null}
      </Component>
    );

    if (tooltip) {
      if (isContentTooltipProps(tooltip) || isShortcutTooltipProps(tooltip)) {
        let content: React.ReactNode;

        const hasShortcut =
          isShortcutTooltipProps(tooltip) && tooltip.shortcut.length > 0;

        if (hasShortcut) {
          content = (
            <>
              {tooltip.content}
              {tooltip.shortcut.map((shortcut, index) => {
                let inner: ReactNode = shortcut;

                if (shortcut.toLowerCase() === "shift") {
                  inner = (
                    <IconArrowBigUp
                      size={12}
                      strokeWidth={2.5}
                      className="my-0.5"
                    />
                  );
                }

                if (shortcut.toLowerCase() === "mod") {
                  inner = isAppleDevice ? (
                    <IconCommand
                      size={12}
                      strokeWidth={1.75}
                      className="my-0.5"
                    />
                  ) : (
                    "Ctrl"
                  );
                }

                return (
                  <Kbd
                    className={cx(tooltip.content && "first-of-type:ml-1")}
                    theme="dark"
                    // biome-ignore lint/suspicious/noArrayIndexKey:
                    key={`${shortcut}-${index}`}
                  >
                    {inner}
                  </Kbd>
                );
              })}
            </>
          );
        } else {
          content = tooltip.content;
        }

        const { className, align, ...tooltipProps } = tooltip;
        return (
          <Tooltip {...tooltipProps}>
            <TooltipTrigger asChild>{buttonInner}</TooltipTrigger>
            <TooltipContent
              align={align}
              className={cx(
                className,
                hasShortcut && "!py-[0.325rem] inline-flex items-center gap-1",
                hasShortcut && tooltip.content ? "!pl-2 !pr-1.5" : "!px-1.5",
              )}
            >
              <TooltipArrow />
              {content}
            </TooltipContent>
          </Tooltip>
        );
      }

      return (
        <Tooltip>
          <TooltipTrigger asChild>{buttonInner}</TooltipTrigger>
          <TooltipContent>
            {tooltip}
            <TooltipArrow />
          </TooltipContent>
        </Tooltip>
      );
    }

    return buttonInner;
  },
) as <C extends ElementType = "button">(
  props: ButtonProps<C> & { ref?: ComponentPropsWithRef<C>["ref"] },
) => ReactNode;

// @ts-expect-error - polymorphic component breaks displayName
Button.displayName = "Button";

function isContentTooltipProps(
  tooltip: ButtonTooltipProps,
): tooltip is ContentButtonTooltipProps {
  return (
    typeof tooltip === "object" &&
    tooltip !== null &&
    "content" in tooltip &&
    !("shortcut" in tooltip)
  );
}

function isShortcutTooltipProps(
  tooltip: ButtonTooltipProps,
): tooltip is ShortcutButtonTooltipProps {
  return (
    typeof tooltip === "object" && tooltip !== null && "shortcut" in tooltip
  );
}

function LoadingIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-[1.125em] w-[1.125em] animate-spin"
    >
      <title>Loading</title>
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="currentColor"
      />
      <path
        d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
        fill="currentColor"
      />
    </svg>
  );
}
