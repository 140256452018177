import type {
  Balance,
  BankTransaction,
  Ehr,
  EhrToEob,
  EobClaim,
  EobPayment,
  EobProcedure,
  EobProcedureRelation,
  PayerGroup,
  PostingAdjustment,
  Task,
} from "@lassie/types";
import { sql } from "drizzle-orm";
import { integer, sqliteTable, text } from "drizzle-orm/sqlite-core";

export const bookEntriesTable = sqliteTable("bookEntries", {
  id: text("id").primaryKey().unique(),
  bookId: text("bookId").notNull(),
  debitAmount: integer("debitAmount").notNull(),
  creditAmount: integer("creditAmount").notNull(),
  journalEntryId: text("journalEntryId").notNull(),
  journalEntryType: text("journalEntryType", {
    enum: [
      "EHR_PROCEDURE",
      "EHR_PAYMENT",
      "EHR_INSURANCE_PAYMENT",
      "EHR_ADJUSTMENT",
      "EHR_OTHER",
    ],
  }).notNull(),
  entryDate: text("entryDate").notNull(),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const patientsTable = sqliteTable("patients", {
  id: text("id").primaryKey(),
  bookId: text("bookId").unique().notNull(),
  firstName: text("firstName").notNull(),
  lastName: text("lastName").notNull(),
  dateOfBirth: text("dateOfBirth"),
  familyId: text("familyId").notNull(),
  plans: text("plans", { mode: "json" }).$type<Ehr.Plan[]>().notNull(),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),
  lastViewedAt: text("lastViewedAt").default(sql`null`),
  isGuarantor: integer("isGuarantor", { mode: "boolean" }).notNull(),
  balance: text("balance", { mode: "json" }).$type<Balance>(),
  practiceUuid: text("practiceUuid").notNull(),
});

export const providersTable = sqliteTable("providers", {
  id: text("id").primaryKey(),
  abbreviation: text("abbreviation").notNull(),
  firstName: text("firstName").notNull(),
  lastName: text("lastName").notNull(),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const proceduresTable = sqliteTable("procedures", {
  id: text("id").notNull().primaryKey(),
  journalEntryId: text("journalEntryId").notNull(),
  procedureCodeId: text("procedureCodeId").notNull(),
  ehrNumber: integer("ehrNumber").notNull(),
  dateOfService: text("dateOfService").notNull(),
  tooth: text("tooth"),
  claimIds: text("claimIds", { mode: "json" }).$type<string[]>(),
  ledgerAmount: integer("ledgerAmount").notNull(),
  patientId: text("patientId").notNull(),
  providerId: text("providerId").notNull(),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const claimsTable = sqliteTable("claims", {
  id: text("id").primaryKey(),
  patientId: text("patientId").notNull(),
  plan: text("plan", { mode: "json" }).$type<Ehr.Plan>().notNull(),
  ledgerAmount: integer("ledgerAmount").notNull(),
  creditAdjustmentAmount: integer("creditAdjustmentAmount").notNull(),
  debitAdjustmentAmount: integer("debitAdjustmentAmount").notNull(),
  estimatedPaymentAmount: integer("estimatedPaymentAmount").notNull(),
  receivedDate: text("receivedDate"),
  sentDate: text("sentDate"),
  claimType: text("claimType", {
    enum: ["PRIMARY", "SECONDARY", "PRE_AUTH", "CAPITATION", "OTHER"],
  }).notNull(),
  claimStatus: text("claimStatus", {
    enum: [
      "BATCHED",
      "UNSENT",
      "RECEIVED",
      "SENT",
      "HOLD_UNTIL_PRIMARY_RECEIVED",
    ],
  }).notNull(),
  claimNote: text("claimNote"),
  internalNote: text("internalNote"),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const claimProceduresTable = sqliteTable("claimProcedures", {
  id: text("id").primaryKey(),
  ehrNumber: integer("ehrNumber").notNull(),
  claimId: text("claimId").notNull(),
  procedureId: text("procedureId").notNull(),
  patientId: text("patientId").notNull(),
  submittedAmount: integer("submittedAmount"),
  itemizedAmount: integer("itemizedAmount"),
  deductibleAmount: integer("deductibleAmount"),
  note: text("note"),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const adjustmentsTable = sqliteTable("adjustments", {
  id: text("id").primaryKey(),
  journalEntryId: text("journalEntryId").notNull(),
  claimId: text("claimId"),
  claimProcedureId: text("claimProcedureId"),
  patientId: text("patientId").notNull(),
  providerId: text("providerId").notNull(),
  adjustmentAmount: integer("adjustmentAmount").notNull(),
  adjustmentDirection: text("adjustmentDirection", {
    enum: ["CREDIT", "DEBIT"],
  }).notNull(),
  adjustmentType: text("adjustmentType").notNull(),
  note: text("note"),
  adjustmentDate: text("adjustmentDate").notNull(),
  procedureId: text("procedureId"),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const insurancePaymentsTable = sqliteTable("insurancePayments", {
  id: text("id").primaryKey(),
  journalEntryId: text("journalEntryId").notNull(),
  claimId: text("claimId").notNull(),
  claimProcedureId: text("claimProcedureId"),
  patientId: text("patientId").notNull(),
  providerId: text("providerId").notNull(),
  paymentAmount: integer("paymentAmount").notNull(),
  details: text("details", { mode: "json" })
    .$type<Ehr.InsurancePaymentDetails>()
    .notNull(),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const patientPaymentsTable = sqliteTable("patientPayments", {
  id: text("id").primaryKey(),
  journalEntryId: text("journalEntryId").notNull(),
  patientId: text("patientId").notNull(),
  procedureId: text("procedureId"),
  paymentAmount: integer("paymentAmount").notNull(),
  details: text("details", { mode: "json" })
    .$type<Ehr.PatientPaymentDetails>()
    .notNull(),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const tasksTable = sqliteTable("tasks", {
  id: text("id").primaryKey(),
  note: text("note"),
  type: text("type", {
    enum: [
      "FILE_SECONDARY_CLAIM",
      "ADD_SECONDARY_PLAN",
      "REFILE_CLAIM",
      "ADJUST_CLAIM",
      "POST_CLAIM",
      "RESOLVE_PAYMENT",
      "REVIEW_CLAIM",
      "ONBOARDING",
      "OTHER",
    ],
  }).notNull(),
  claimId: text("claimId"),
  paymentId: text("paymentId"),
  bankTransactionId: text("bankTransactionId"),
  completedAt: text("completedAt"),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const eobToEhrClaimsTable = sqliteTable("eobToEhrClaims", {
  eobClaimId: text("eobClaimId").primaryKey(),
  ehrClaimId: text("ehrClaimId").notNull(),
  ehrPatientId: text("ehrPatientId").notNull(),
  postingAdjustments: text("postingAdjustments", { mode: "json" })
    .$type<PostingAdjustment[]>()
    .notNull(),
  practiceUuid: text("practiceUuid").notNull(),
});

export const eobClaimsTable = sqliteTable("eobClaims", {
  id: text("id").primaryKey(),
  paymentId: text("paymentId").notNull(),
  procedures: text("procedures", { mode: "json" })
    .$type<EobProcedure[]>()
    .notNull(),
  procedureRelations: text("procedureRelations", { mode: "json" })
    .$type<EobProcedureRelation[]>()
    .notNull(),
  patientName: text("patientName").notNull(),
  patientDateOfBirth: text("patientDateOfBirth"),
  claimNumber: text("claimNumber").notNull(),
  groupName: text("groupName"),
  groupNumber: text("groupNumber"),
  dateOfService: text("dateOfService"),
  subscriberName: text("subscriberName"),
  subscriberId: text("subscriberId"),
  subscriberDateOfBirth: text("subscriberDateOfBirth"),
  paymentAmount: integer("paymentAmount").notNull(),
  acceptedAmount: integer("acceptedAmount").notNull(),
  primaryAmount: integer("primaryAmount").notNull(),
  patientAmount: integer("patientAmount").notNull(),
  adjustmentAmount: integer("adjustmentAmount").notNull(),
  submittedAmount: integer("submittedAmount").notNull(),
  deductibleAmount: integer("deductibleAmount").notNull(),
  status: text("status", {
    enum: [
      "POSTED_BY_US",
      "POSTED_BY_PRACTICE",
      "SKIPPED",
      "ATTEMPTED",
      "CREATED",
    ],
  }).notNull(),
  pdfPath: text("pdfPath"),
  processedAt: text("processedAt"),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),

  practiceUuid: text("practiceUuid").notNull(),
});

export const eobPaymentsTable = sqliteTable("eobPayments", {
  id: text("id").primaryKey(),
  bankTransactionId: text("bankTransactionId"),
  paymentNumber: text("paymentNumber").notNull(),
  paymentAmount: integer("paymentAmount").notNull(),
  paymentDate: text("paymentDate").notNull(),
  adjustmentAmount: integer("adjustmentAmount").notNull(),
  paymentMethod: text("paymentMethod", {
    enum: ["ACH", "CHECK", "VCC", "NON_PAYMENT"],
  }).notNull(),
  payerName: text("payerName").notNull(),
  payerGroupId: text("payerGroupId").notNull(),
  pdfPath: text("pdfPath"),
  receivedByPracticeAt: text("receivedByPracticeAt"),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),

  practiceUuid: text("practiceUuid").notNull(),
});

export const bankTransactionsTable = sqliteTable("bankTransactions", {
  id: text("id").primaryKey(),
  accountName: text("accountName").notNull(),
  institutionName: text("institutionName").notNull(),
  status: text("status", {
    enum: ["PENDING", "POSTED"],
  }).notNull(),
  accruedAt: text("accruedAt").notNull(),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),

  practiceUuid: text("practiceUuid").notNull(),
});

export const payerGroupsTable = sqliteTable("payerGroups", {
  id: text("id").primaryKey(),
  name: text("name").notNull(),
});

export const procedureCodesTable = sqliteTable("procedureCodes", {
  id: text("id").primaryKey(),
  procedureCode: text("procedureCode").notNull(),
  description: text("description").notNull(),
  abbreviatedDescription: text("abbreviatedDescription"),
  friendlyDescription: text("friendlyDescription"),
  createdAt: text("createdAt").notNull(),
  updatedAt: text("updatedAt").notNull(),
  deletedAt: text("deletedAt"),

  practiceUuid: text("practiceUuid").notNull(),
});

export const prefetchStatusTable = sqliteTable("prefetchStatus", {
  id: text("id").primaryKey(),
  entityType: text("entityType", {
    // prefetching methods
    enum: ["ledger", "payment"],
  }).notNull(),
  entityId: text("entityId").notNull(),
  status: text("status", {
    enum: ["PENDING", "COMPLETED"],
  }).notNull(),
  lastPrefetchedAt: text("lastPrefetchedAt").notNull(),

  practiceUuid: text("practiceUuid").notNull(),
});

type AssertAssignable<T, Expected> = T extends Expected ? true : never;
type TableModel<T> = T extends { $inferSelect: infer U } ? U : never;
type AssertSchemaMatches<TTable, KType> = AssertAssignable<
  TableModel<TTable>,
  KType
>;

/**
 * This is a compile-time check that ensures that the tables are
 * correctly defined and match the expected types.
 */
true satisfies AssertSchemaMatches<typeof bookEntriesTable, Ehr.BookEntry>;
true satisfies AssertSchemaMatches<typeof patientsTable, Ehr.Patient>;
true satisfies AssertSchemaMatches<typeof proceduresTable, Ehr.Procedure>;
true satisfies AssertSchemaMatches<typeof claimsTable, Ehr.Claim>;
true satisfies AssertSchemaMatches<typeof adjustmentsTable, Ehr.Adjustment>;
true satisfies AssertSchemaMatches<
  typeof insurancePaymentsTable,
  Ehr.InsurancePayment
>;
true satisfies AssertSchemaMatches<
  typeof patientPaymentsTable,
  Ehr.PatientPayment
>;
true satisfies AssertSchemaMatches<typeof providersTable, Ehr.Provider>;
true satisfies AssertSchemaMatches<
  typeof procedureCodesTable,
  Ehr.ProcedureCode
>;
true satisfies AssertSchemaMatches<typeof tasksTable, Task>;
true satisfies AssertSchemaMatches<typeof eobToEhrClaimsTable, EhrToEob>;
true satisfies AssertSchemaMatches<typeof eobClaimsTable, EobClaim>;
true satisfies AssertSchemaMatches<typeof eobPaymentsTable, EobPayment>;
true satisfies AssertSchemaMatches<
  typeof bankTransactionsTable,
  BankTransaction
>;
true satisfies AssertSchemaMatches<typeof payerGroupsTable, PayerGroup>;
/**
 * Queryable tables
 */
export const Tables = {
  prefetchStatus: prefetchStatusTable,
  patients: patientsTable,
  providers: providersTable,
  procedures: proceduresTable,
  procedureCodes: procedureCodesTable,
  bookEntries: bookEntriesTable,
  claims: claimsTable,
  claimProcedures: claimProceduresTable,
  adjustments: adjustmentsTable,
  insurancePayments: insurancePaymentsTable,
  patientPayments: patientPaymentsTable,
  tasks: tasksTable,
  eobToEhrClaims: eobToEhrClaimsTable,
  eobClaims: eobClaimsTable,
  eobPayments: eobPaymentsTable,
  bankTransactions: bankTransactionsTable,
  payerGroups: payerGroupsTable,
} as const;

export const TablesToPrimaryKey = {
  prefetchStatus: "id",
  patients: "id",
  providers: "id",
  procedures: "id",
  procedureCodes: "id",
  bookEntries: "id",
  claims: "id",
  claimProcedures: "id",
  adjustments: "id",
  insurancePayments: "id",
  patientPayments: "id",
  tasks: "id",
  eobToEhrClaims: "eobClaimId",
  eobClaims: "id",
  eobPayments: "id",
  bankTransactions: "id",
  payerGroups: "id",
} as const satisfies Record<keyof typeof Tables, string>;

/**
 * Get the primary key for a table
 */
export const getPrimaryKey = (table: string): string => {
  if (isTable(table)) {
    return TablesToPrimaryKey[table];
  }

  throw new Error(`Table ${table} not found`);
};

function isTable(table: string): table is keyof typeof Tables {
  return table in Tables;
}
