import { addDays, format } from "date-fns";
import { createContext, useContext } from "react";
import { z } from "zod";

export const formatDateRange = (dateRange: { from: Date; to: Date }) => {
  const formattedFrom = format(dateRange.from, "yyyy-MM-dd");
  const formattedTo = format(dateRange.to, "yyyy-MM-dd");
  return `${formattedFrom}-to-${formattedTo}`;
};

export const POSTED_REPORT_INTERVALS = ["Daily", "Weekly", "Monthly"] as const;
export const POSTED_BY = ["Lassie", "Practice"] as const;
export const GROUP_BY = ["Payments", "Claims"] as const;

export type ReportInterval = PostedFilters["reportInterval"];
export type PostedBy = PostedFilters["postedBy"];

export const INTERVAL_TO_LABEL = {
  Daily: "",
  Weekly: "Week of",
  Monthly: "Month of",
} as const;

export const DATE_RANGE_SCHEMA = z
  .string()
  .transform((value) => {
    const [from, to] = value.split("-to-");
    return {
      from: addDays(new Date(from), 1),
      to: addDays(new Date(to), 1),
    };
  })
  .catch(() => {
    const from = addDays(new Date(), 0);
    const to = addDays(new Date(), 0);
    return {
      from,
      to,
    };
  });

export const POSTED_FILTERS_SCHEMA = z.object({
  reportInterval: z.enum(POSTED_REPORT_INTERVALS).catch("Daily"),
  dateRange: z.string().catch(() => {
    const from = addDays(new Date(), 0);
    const to = addDays(new Date(), 0);
    return formatDateRange({ from, to });
  }),
  groupBy: z.enum(GROUP_BY).catch("Payments"),
  postedBy: z.enum(POSTED_BY).optional().catch(undefined),
  payerGroupId: z.string().optional().catch(undefined),
  patientName: z.string().optional().catch(undefined),
  claimNumber: z.string().optional().catch(undefined),
  paymentNumber: z.string().optional().catch(undefined),
  postedAmount: z.string().optional().catch(undefined),
});

export type PostedFiltersQuery = z.infer<typeof POSTED_FILTERS_SCHEMA>;
export type PostedFilters = {
  reportInterval: (typeof POSTED_REPORT_INTERVALS)[number];
  dateRange: {
    from: Date;
    to: Date;
  };
  groupBy: (typeof GROUP_BY)[number];
  postedBy?: (typeof POSTED_BY)[number];
  payerGroupId?: string;
  patientName?: string;
  claimNumber?: string;
  paymentNumber?: string;
  postedAmount?: string;
};

export type PostedContextType = {
  setFilters: (filters: Partial<PostedFilters>) => void;
  filters: PostedFilters;

  onSearchText: (searchText: string | null) => void;
  searchText: string | null;
};

const postedContext = createContext<PostedContextType>({} as PostedContextType);

export const PostedProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: PostedContextType;
}) => {
  return (
    <postedContext.Provider value={value}>{children}</postedContext.Provider>
  );
};

export const usePostedContext = () => {
  return useContext(postedContext);
};
