import { PlatformReader } from "platform-ts";

const reader = new PlatformReader();

const getCanvasFingerprint = () => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const txt = "lassie.<more-patient-time>1@551e";

  if (!ctx) return "canvas-unsupported";

  ctx.textBaseline = "alphabetic";
  ctx.font = "14px 'Arial'";
  ctx.fillStyle = "#f60";
  ctx.fillRect(125, 1, 62, 20);
  ctx.fillStyle = "#069";
  ctx.fillText(txt, 2, 15);
  ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
  ctx.fillText(txt, 4, 17);

  return canvas.toDataURL();
};

export function fingerprint() {
  const browser = reader.getResult();

  const fingerprint = {
    ...browser,
    canvas: getCanvasFingerprint(),
  };

  const stringified = JSON.stringify(fingerprint);
  return window.btoa(stringified);
}
