import { z } from "zod";
import { payerGroupSchema, practiceSchema } from "./practice";

/*
 * Bootstrap
 */

export const bootstrapResponseSchema = z
  .object({
    // collections
    patients: z.array(z.any()),
    providers: z.array(z.any()),
    tasks: z.array(z.any()),
    eobToEhrClaims: z.array(z.any()),
    procedureCodes: z.array(z.any()),

    // ledger and payments
    bookEntries: z.array(z.any()),
    procedures: z.array(z.any()),
    claims: z.array(z.any()),
    claimProcedures: z.array(z.any()),
    adjustments: z.array(z.any()),
    insurancePayments: z.array(z.any()),
    patientPayments: z.array(z.any()),
    eobClaims: z.array(z.any()),
    eobPayments: z.array(z.any()),
    bankTransactions: z.array(z.any()),

    // meta
    prefetchedPatientIds: z.array(z.string()),
    prefetchedEobPaymentIds: z.array(z.string()),

    // configuration
    selectedPractice: z.string(), // uuid of the selected practice
    practices: z.array(practiceSchema),
    payerGroups: z.array(payerGroupSchema),
  })
  .strict();

export type BootstrapResponse = z.infer<typeof bootstrapResponseSchema>;

export const bootstrapQuerySchema = z.object({
  from: z.number().nullish(),
  selectedPractice: z.string().nullish(), // uuid of the selected practice
});
export type BootstrapQuery = z.infer<typeof bootstrapQuerySchema>;

/*
 * Patient Balances
 */

export const patientBalanceQuerySchema = z.object({
  ehrPatientIds: z.array(z.string()),
});
export type PatientBalanceQuery = z.infer<typeof patientBalanceQuerySchema>;

export const balanceSchema = z.object({
  openBalance: z.number(),
  totalBalance: z.number(),
  openFamilyBalance: z.number(),
  totalFamilyBalance: z.number(),
  resolved: z.boolean(),
  lastResolvedAt: z.string(),
  lastClaimPostedAt: z.string().nullable(),
  archivedAt: z.string().nullable(),
});

export type Balance = z.infer<typeof balanceSchema>;

export const patientBalanceResponseSchema = z.object({
  families: z.array(balanceSchema),
});
export type PatientBalanceResponse = z.infer<
  typeof patientBalanceResponseSchema
>;
