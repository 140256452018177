import z from "zod";
import { deletableSchema, objectMetaSchema } from "./generic";

export const userSchema = z.object({
  uuid: z.string(),
  email: z.string(),
  type: z.enum(["INTERNAL", "EXTERNAL"]),
});
export type User = z.infer<typeof userSchema>;

export const practicePayerGroupSchema = z.object({
  id: z.string(),
  postingStartDate: z.string().nullable(),
});
export type PracticePayerGroup = z.infer<typeof practicePayerGroupSchema>;

export const practiceSchema = z.object({
  id: z.number(),
  name: z.string(),
  ehrType: z.enum(["DENTRIX", "OPENDENTAL", "EAGLESOFT"]),
  practiceGroupId: z.string(),
  payerGroups: z.array(practicePayerGroupSchema),
  uuid: z.string(),
});
export type Practice = z.infer<typeof practiceSchema>;

export const taskSchema = z.object({
  id: z.string(),
  note: z.string().nullable(),
  type: z.enum([
    "FILE_SECONDARY_CLAIM",
    "ADD_SECONDARY_PLAN",
    "REFILE_CLAIM",
    "ADJUST_CLAIM",
    "POST_CLAIM",
    "RESOLVE_PAYMENT",
    "REVIEW_CLAIM",
    "ONBOARDING",
    "OTHER",
  ]),
  claimId: z.string().nullable(),
  paymentId: z.string().nullable(),
  bankTransactionId: z.string().nullable(),
  completedAt: z.string().nullable(),
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
});
export type Task = z.infer<typeof taskSchema>;

export const postingAdjustmentSchema = z.object({
  procedureEhrNumber: z.number(),
  case: z.string(),
  amount: z.number(),
  direction: z.enum(["CREDIT", "DEBIT"]),
});
export type PostingAdjustment = z.infer<typeof postingAdjustmentSchema>;

export const eobToEhrClaimsSchema = z.object({
  ehrClaimId: z.string(),
  eobClaimId: z.string(),
  ehrPatientId: z.string(),
  postingAdjustments: z.array(postingAdjustmentSchema),
});
export type EhrToEob = z.infer<typeof eobToEhrClaimsSchema>;

/*
 * EOB Models
 */
export const eobProcedureNoteSchema = z.object({
  id: z.number(),
  code: z.string(),
  note: z.string(),
  groupCode: z.string().nullable(),
  reasonCode: z.string().nullable(),
});
export type EobProcedureNote = z.infer<typeof eobProcedureNoteSchema>;

export const eobProcedureSchema = z.object({
  id: z.number(),
  notes: z.array(eobProcedureNoteSchema),
  procedureCode: z.string(),
  dateOfService: z.string(),
  paymentAmount: z.number(),
  acceptedAmount: z.number(),
  primaryAmount: z.number(),
  submittedAmount: z.number(),
  patientAmount: z.number(),
  deductibleAmount: z.number(),
  tooth: z.string().nullable(),
  surface: z.string().nullable(),
  ...objectMetaSchema.shape,
});
export type EobProcedure = z.infer<typeof eobProcedureSchema>;

export const eobProcedureRelationSchema = z.object({
  submittedProcedureId: z.number(),
  returnedProcedureId: z.number(),
});
export type EobProcedureRelation = z.infer<typeof eobProcedureRelationSchema>;

export const eobClaimSchema = z.object({
  id: z.string(),
  paymentId: z.string(),
  procedures: z.array(eobProcedureSchema),
  procedureRelations: z.array(eobProcedureRelationSchema),
  patientName: z.string(),
  patientDateOfBirth: z.string().nullable(),
  claimNumber: z.string(),
  groupName: z.string().nullable(),
  groupNumber: z.string().nullable(),
  dateOfService: z.string().nullable(),
  subscriberName: z.string().nullable(),
  subscriberId: z.string().nullable(),
  subscriberDateOfBirth: z.string().nullable(),
  paymentAmount: z.number(),
  acceptedAmount: z.number(),
  primaryAmount: z.number(),
  patientAmount: z.number(),
  deductibleAmount: z.number(),
  adjustmentAmount: z.number(),
  submittedAmount: z.number(),
  status: z.enum([
    "POSTED_BY_US",
    "POSTED_BY_PRACTICE",
    "SKIPPED",
    "ATTEMPTED",
    "CREATED",
  ]),
  pdfPath: z.string().nullable(),
  processedAt: z.string().nullable(),
  ...objectMetaSchema.shape,
});
export type EobClaim = z.infer<typeof eobClaimSchema>;

export const eobPaymentSchema = z.object({
  id: z.string(),
  bankTransactionId: z.string().nullable(),
  paymentNumber: z.string(),
  paymentAmount: z.number(),
  paymentDate: z.string(),
  adjustmentAmount: z.number(),
  paymentMethod: z.enum(["ACH", "CHECK", "VCC", "NON_PAYMENT"]),
  payerName: z.string(),
  payerGroupId: z.string(),
  pdfPath: z.string().nullable(),
  receivedByPracticeAt: z.string().nullable(),
  ...objectMetaSchema.shape,
});
export type EobPayment = z.infer<typeof eobPaymentSchema>;

export const eobSchema = z.object({
  id: z.string(), // claim id
  ehrClaimId: z.string(),
  claim: eobClaimSchema,
  payment: eobPaymentSchema,
});
export type Eob = z.infer<typeof eobSchema>;

export const payerGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
});
export type PayerGroup = z.infer<typeof payerGroupSchema>;

export const insuranceLoginSchema = z.object({
  id: z.string(),
  source: z.string(),
  sourceName: z.string(),
  username: z.string(),
  password: z.string(),
  error: z.string().nullable(),
});
export type InsuranceLogin = z.infer<typeof insuranceLoginSchema>;

export const bankAccountSchema = z.object({
  name: z.string(),
  type: z.string(),
  mask: z.string().nullable(),
  ignoredAt: z.string().nullable(),
});
export type BankAccount = z.infer<typeof bankAccountSchema>;

export const bankSourceSchema = z.object({
  id: z.number(),
  logo: z.string().nullable(),
  createdAt: z.string(),
  institutionName: z.string(),
  status: z.enum(["ACTIVE", "USER_ERROR", "ERROR"]),
  vendor: z.enum(["PLAID", "MX"]),

  accounts: z.array(bankAccountSchema),
});
export type BankSource = z.infer<typeof bankSourceSchema>;

export const bankInstitutionSchema = z.object({
  id: z.string(),
  name: z.string(),
  vendor: z.enum(["PLAID", "MX"]),
  vendorId: z.string(),
  logo: z.string().nullable(),
  primaryColor: z.string().nullable(),
  url: z.string().nullable(),
});
export type BankInstitution = z.infer<typeof bankInstitutionSchema>;
