import { practiceSchema } from "@lassie/types";
import { z } from "zod";
import { versionSchema } from "./version";

const localStorageSchema = z.object({
  version: versionSchema.optional(),
  selectedPractice: z.string().uuid(),
  selectedPracticeMetadata: practiceSchema.catch({
    id: 0,
    name: "",
    ehrType: "DENTRIX",
    practiceGroupId: "",
    payerGroups: [],
    uuid: "",
  }),
  lastSyncedAt: z.record(z.string(), z.number().nullable()).catch({}),
  clientId: z.string(),
  __lassie_debug: z.boolean().optional(),
  __lassie_debug_preferences: z
    .object({
      showTimers: z.boolean().default(false),
    })
    .nullish(),
  __force_replay: z.boolean().optional(),
});

export type LocalStorageShape = z.infer<typeof localStorageSchema>;
export type LocalStorageKey = keyof typeof localStorageSchema.shape;

/**
 * Type-safe localStorage. Validated at runtime via Zod.
 */
export const LocalStorage = {
  get<T extends keyof typeof localStorageSchema.shape>(
    key: T,
  ): z.infer<typeof localStorageSchema>[T] | null {
    const value = localStorage.getItem(key);

    // localStorage is always nullable
    if (!value) return null;

    const parsedValue = parseExpectedType(value, localStorageSchema.shape[key]);
    const validatedValue = localStorageSchema.shape[key].parse(parsedValue);

    return validatedValue as z.infer<typeof localStorageSchema>[T];
  },

  set<T extends keyof typeof localStorageSchema.shape>(
    key: T,
    value: z.infer<typeof localStorageSchema>[T],
  ) {
    const parsedValue = localStorageSchema.shape[key].parse(value);

    if (parsedValue === null) {
      localStorage.removeItem(key);
    } else {
      if (typeof parsedValue === "object") {
        localStorage.setItem(key, JSON.stringify(parsedValue));
      } else if (parsedValue === null || parsedValue === undefined) {
        localStorage.setItem(key, "null");
      } else {
        localStorage.setItem(key, parsedValue.toString());
      }
    }
  },

  updateLastSyncedAt(practiceId: string, lastSyncedAt: number | null) {
    const lastSyncedAtRecords = this.get("lastSyncedAt") ?? {};
    lastSyncedAtRecords[practiceId] = lastSyncedAt;
    this.set("lastSyncedAt", lastSyncedAtRecords);
  },

  getLastSyncedAt(practiceId: string) {
    const lastSyncedAtRecords = this.get("lastSyncedAt") ?? {};
    return lastSyncedAtRecords[practiceId] ?? null;
  },
};

/** Parses expected type based on Zod schema. */
function parseExpectedType(value: string, schema: z.ZodType<any>) {
  if (schema instanceof z.ZodBoolean) {
    return value === "true";
  }

  if (schema instanceof z.ZodString) {
    return value;
  }

  if (schema instanceof z.ZodNumber) {
    return Number(value);
  }

  if (schema instanceof z.ZodDate) {
    return new Date(value);
  }

  return JSON.parse(value);
}
