import type {
  Collection,
  CollectionToModel,
  DataCommandMessage,
  MutationMessages,
  SyncMessage,
} from "@lassie/types";
import { type EntityState, current } from "@reduxjs/toolkit";
import { type TagDescription, createApi } from "@reduxjs/toolkit/query/react";
import { eq } from "drizzle-orm";
import { toast } from "sonner";
import { db } from "../../lib/drizzle/db";
import { Tables } from "../../lib/drizzle/schema";
import { LocalStorage } from "../../lib/local-storage";
import { sendMutation } from "../../lib/mutation";
import { socket } from "../../lib/socket-proxy";
import { syncWorker } from "../../lib/workers";
import {
  type PatientLedger,
  type PatientLedgerError,
  getPatientLedgerFromDisk,
  isVisit,
} from "../selectors";
import { baseQuery } from "./rpc";
import {
  QUERY_TAGS,
  type QueryTag,
  QueryTagId,
  createTagProvider,
} from "./tags";
import type { DiskAction } from "./types";

export const diskApi = createApi({
  reducerPath: "disk",
  baseQuery: baseQuery(),
  tagTypes: QUERY_TAGS,
  endpoints: (builder) => ({
    /*
     * Patients
     */
    patients: builder.query<
      DiskAction.Patients["response"],
      DiskAction.Patients["options"]
    >({
      providesTags: createTagProvider("patients", {
        listIds: [QueryTagId.RECENT],
      }),
      query: (options) => ({
        name: "patients",
        options,
      }),
    }),
    /*
     * Ledger
     */
    ledger: builder.query<
      PatientLedger | PatientLedgerError,
      DiskAction.Ledger["options"]
    >({
      providesTags: (result, _error, arg) => {
        const tags: TagDescription<QueryTag>[] = [
          {
            type: "ledger",
            id: arg.patientId,
          },
        ];

        if (!result || result.error !== null) {
          return tags;
        }

        const tasks = result.ledgerItems.flatMap((item) => {
          if (isVisit(item)) {
            return item.eobs.flatMap((eob) => {
              if (!eob.task) {
                return [];
              }

              return [
                {
                  type: "tasks" as const,
                  id: eob.task.id,
                },
              ];
            });
          }

          return [];
        });

        tags.push(
          ...[
            { type: "patients" as const, id: QueryTagId.ALL },
            { type: "claims" as const, id: QueryTagId.ALL },
            { type: "claimProcedures" as const, id: QueryTagId.ALL },
            { type: "procedures" as const, id: QueryTagId.ALL },
            { type: "adjustments" as const, id: QueryTagId.ALL },
            { type: "insurancePayments" as const, id: QueryTagId.ALL },
            { type: "patientPayments" as const, id: QueryTagId.ALL },
            { type: "eobs" as const, id: QueryTagId.ALL },
            { type: "tasks" as const, id: QueryTagId.ALL },
            ...tasks,
          ],
        );

        return tags;
      },
      query: ({ patientId }) => ({
        name: "ledger",
        options: { patientId },
        forceRefetch: true,
      }),
      transformResponse: (response) => {
        if (!!response && "family" in response && "bookEntries" in response) {
          return getPatientLedgerFromDisk(response);
        }

        throw new Error("Invalid response. Expected patient ledger.");
      },
    }),
    /*
     * Payments
     */
    getAllPayments: builder.query<DiskAction.GetAllPayments["response"], void>({
      query: () => ({
        name: "allPayments",
        options: undefined,
      }),
      providesTags: createTagProvider("eobs"),
      onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;

        if (!data) {
          return;
        }

        // const paymentIds = data.payments.map((payment) => payment.id);

        // // biome-ignore lint/style/noNonNullAssertion: TEMP
        // const selectedPractice = LocalStorage.get("selectedPractice")!;

        // socket.emit(
        //   "payments",
        //   {
        //     eobPaymentIds: paymentIds,
        //     selectedPractice,
        //   },
        //   async (response) => {
        //     if (response.success) {
        //       enterPayments(response.result, selectedPractice);
        //     }
        //   },
        // );
      },
    }),
    payments: builder.query<
      DiskAction.Payments["response"],
      DiskAction.Payments["options"]
    >({
      query: (options) => ({
        name: "payments",
        options,
      }),
      providesTags: createTagProvider("eobs", {
        listIds: [QueryTagId.RECENT],
      }),
    }),
    getPayment: builder.query<
      DiskAction.GetPayment["response"],
      { paymentId: string }
    >({
      query: ({ paymentId }) => ({
        name: "payment",
        options: { paymentId },
      }),
      providesTags: createTagProvider("eobs", {
        listIds: [QueryTagId.RECENT],
        otherTags: [{ type: "tasks", id: QueryTagId.ALL }],
        fromArg: true,
      }),
      onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;

        if (!data) {
          return;
        }

        const eobClaimIds = data.claims.map((claim) => claim.id);

        // TODO: move to UI
        // const { prefetchedPatientIds } =
        //   // TODO: Get rid of this, prefetching ledgers by eobClaimIds should happen in the UI
        //   // this makes too many requests on the /payments page
        //   await syncWorker.prefetchLedgersByEobClaimIds(
        //     eobClaimIds,
        //     // biome-ignore lint/style/noNonNullAssertion: TEMP
        //     LocalStorage.get("selectedPractice")!,
        //   );

        // onPrefetchLedgersComplete({ prefetchedPatientIds, dispatch });
      },
    }),
    paymentsInbox: builder.query<DiskAction.PaymentsInbox["response"], void>({
      query: () => ({
        name: "paymentsInbox",
        options: undefined,
      }),
      providesTags: [
        "payments-inbox",
        { type: "eobs", id: QueryTagId.ALL },
        { type: "tasks", id: QueryTagId.ALL },
      ],
    }),
    posted: builder.query<
      DiskAction.Posted["response"],
      DiskAction.Posted["options"]
    >({
      query: (options) => ({
        name: "posted",
        options,
      }),
      providesTags: createTagProvider("eobs"),
    }),
    payerGroups: builder.query<DiskAction.PayerGroups["response"], void>({
      query: () => ({
        name: "payerGroups",
        options: undefined,
      }),
    }),
    /*
     * Manages sync event streams
     */
    realtime: builder.query<
      EntityState<CollectionToModel[Collection], number>,
      DiskAction.Realtime["options"]
    >({
      query: ({ token }) => {
        return {
          name: "realtime",
          options: { token },
        };
      },
      async onCacheEntryAdded(
        arg,
        { dispatch, cacheDataLoaded, cacheEntryRemoved },
      ) {
        socket.setAccessToken(arg.token);

        try {
          await cacheDataLoaded;

          const listener = async (event: SyncMessage | DataCommandMessage) => {
            if (!Array.isArray(event?.data)) {
              if (event.data.action === "reset") {
                // on reset, re-bootstrap provided models
                // TODO: make bootstrap dispatchable
                dispatch(diskApi.util.invalidateTags(event.data.models));

                return;
              }

              // TODO: migrate message types to zod schemas for parsing
              logger.warn("Incorrectly formatted sync message:", event);
              return;
            }

            const actions = event.data.filter(Boolean);

            logger.log("actions", actions);

            for (const action of actions) {
              const table = Tables[action.model as keyof typeof Tables];

              if (action.action === "delete") {
                logger.log(
                  // @ts-expect-error
                  `[sync] deleting ${action.model} with id ${action.payload?.id ?? action.payload?.ehrClaimId}`,
                );
                if ("id" in action.payload) {
                  // @ts-expect-error
                  await db.delete(table).where(eq(table.id, action.payload.id));
                } else {
                  logger.error("[sync] unknown delete action", action, table);
                }
              } else {
                logger.log(
                  // @ts-expect-error
                  `[sync] upserting ${action.model} with id ${action.payload?.[getPrimaryKey(action.model)]}`,
                );

                const payload = {
                  ...action.payload,
                  // biome-ignore lint/style/noNonNullAssertion: TEMP
                  practiceUuid: LocalStorage.get("selectedPractice")!,
                };

                await db
                  .insert(table)
                  .values(payload)
                  .onConflictDoUpdate({
                    // @ts-expect-error
                    target: getPrimaryKey(action.model),
                    set: payload,
                  });
              }

              const tags = actions.map((action) => action.model);

              dispatch(diskApi.util.invalidateTags(tags));
            }
          };

          socket.on("sync", listener);
        } catch (error) {
          // @see https://redux-toolkit.js.org/rtk-query/usage/streaming-updates
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
          logger.error(error);
        }
        await cacheEntryRemoved;

        logger.log("[socket] would disconnect");
      },
    }),
    patientInbox: builder.query<DiskAction.PatientInbox["response"], void>({
      query: () => ({
        name: "patientInbox",
        options: undefined,
      }),
      providesTags: [
        "ledger-inbox",
        { type: "patients", id: QueryTagId.ALL },
        { type: "tasks", id: QueryTagId.ALL },
      ],
      onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
        const result = await queryFulfilled;

        if (!result?.data?.length) {
          return;
        }
      },
    }),
    completedPatientInbox: builder.query<
      DiskAction.CompletedPatientInbox["response"],
      DiskAction.CompletedPatientInbox["options"]
    >({
      query: (options) => ({
        name: "completedPatientInbox",
        options,
      }),
      providesTags: [
        "ledger-inbox",
        "completed-ledger-inbox",
        { type: "patients", id: QueryTagId.ALL },
        { type: "tasks", id: QueryTagId.ALL },
      ],
      onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
        const result = await queryFulfilled;

        if (!result?.data?.length) {
          return;
        }
      },
    }),
    paperChecks: builder.query<
      DiskAction.PaperChecks["response"],
      DiskAction.PaperChecks["options"]
    >({
      providesTags: createTagProvider("eobPayments", {
        listIds: [QueryTagId.RECENT],
        otherTags: ["paper-checks"],
      }),
      query: (options) => ({
        name: "paperChecks",
        options,
      }),
    }),
    family: builder.query<
      DiskAction.Family["response"],
      DiskAction.Family["options"]
    >({
      providesTags: createTagProvider("patients", {
        otherTags: ["ledger"],
        formatResult: (result) => result?.family ?? null,
      }),
      query: (options) => ({
        name: "family",
        options,
      }),
    }),

    balances: builder.query<
      DiskAction.Balances["response"],
      DiskAction.Balances["options"]
    >({
      providesTags: createTagProvider("patients", {
        listIds: [QueryTagId.RECENT],
      }),
      query: (options) => ({
        name: "balances",
        options,
      }),
    }),

    /**
     * Mutations
     */
    mutation: builder.mutation<
      DiskAction.Mutation["response"],
      DiskAction.Mutation["options"]
    >({
      queryFn: async (mutation) => {
        const mutationPromise = sendMutation(mutation as MutationMessages);

        const response = await mutationPromise;

        logger.log(response);

        if (!response.success) {
          return { error: response.error };
        }

        return {
          data: null,
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        let startTime = performance.now();
        const logNow = () => {
          const logTime = performance.now();
          logger.log(`[mutation] Has took ${logTime - startTime}ms`);
          startTime = logTime;
        };

        // Task query updaters
        if ("taskId" in arg.payload) {
          const patientId = arg?.metadata?.patientId;
          const paymentId = arg?.metadata?.paymentId;
          const taskId = arg.payload.taskId;

          const newCompletedAt =
            arg.action === "complete" ? new Date().toISOString() : null;

          logger.log("[mutation] updating patient inbox");
          logNow();
          dispatch(
            diskApi.util.updateQueryData("patientInbox", undefined, (draft) => {
              const taskIndex = draft.findIndex((task) => task.id === taskId);

              if (taskIndex === -1) {
                logger.warn(
                  `[mutation] task ${taskId} not found in inbox, could not update`,
                );
                return draft;
              }

              draft[taskIndex].completedAt = newCompletedAt;
            }),
          );

          if (patientId && typeof patientId === "string") {
            logger.log("[mutation] updating patient ledger for", patientId);
            logNow();
            dispatch(
              diskApi.util.updateQueryData(
                "ledger",
                {
                  patientId,
                },
                (draft) => {
                  if (draft.error !== null) {
                    logger.error("[mutation] errorful ledger:", draft.error);
                    return draft;
                  }

                  if (draft === undefined || !draft.ledgerItems) {
                    logger.warn(
                      "[mutation] draft is undefined, could not update",
                    );
                    return draft;
                  }

                  let eobIdx = -1;
                  const ledgerItemIdx = draft.ledgerItems.findIndex((item) => {
                    if (!isVisit(item)) {
                      return false;
                    }

                    eobIdx = item.eobs.findIndex(
                      (eob) => eob.task?.id === taskId,
                    );

                    return eobIdx !== -1;
                  });

                  try {
                    // @ts-expect-error -- we know that this ledger item is a visit
                    draft.ledgerItems[ledgerItemIdx].eobs[
                      eobIdx
                    ].task.completedAt = newCompletedAt;
                  } catch (error) {
                    logger.error(
                      "[mutation] error updating ledger item",
                      error,
                    );
                    IS_DEVELOPMENT &&
                      console.table({
                        taskId,
                        ledgerItemIdx,
                        eobIdx,
                      });
                    logger.log(current(draft));
                  }
                },
              ),
            );
          }

          if (paymentId && typeof paymentId === "string") {
            logger.log("[mutation] updating payment inbox for", paymentId);
            logNow();
            dispatch(
              diskApi.util.updateQueryData(
                "paymentsInbox",
                undefined,
                (draft) => {
                  const paymentIndex = draft.payments.findIndex(
                    (payment) => payment.payment.id === paymentId,
                  );
                  if (paymentIndex === -1) {
                    logger.warn(
                      `[mutation] payment ${paymentId} not found in inbox, could not update`,
                    );
                    return draft;
                  }

                  if (draft.payments[paymentIndex].task?.id === taskId) {
                    // payment has this task at the top-level
                    draft.payments[paymentIndex].task.completedAt =
                      newCompletedAt;

                    return draft;
                  }
                  const claimIndex = draft.payments[
                    paymentIndex
                  ].claims.findIndex((claim) => claim.task?.id === taskId);

                  if (
                    claimIndex !== -1 &&
                    draft.payments[paymentIndex].claims[claimIndex].task
                  ) {
                    draft.payments[paymentIndex].claims[
                      claimIndex
                    ].task.completedAt = newCompletedAt;

                    return draft;
                  }
                },
              ),
            );

            dispatch(
              diskApi.util.updateQueryData(
                "getPayment",
                { paymentId: paymentId },
                (draft) => {
                  if (!draft) {
                    logger.warn(
                      "[mutation] draft is undefined, could not update",
                    );
                    return draft;
                  }

                  const taskIndex = draft.tasks.findIndex(
                    (task) => task.id === taskId,
                  );

                  if (taskIndex === -1) {
                    logger.warn(
                      "[mutation] task ${taskId} not found in payment, could not update",
                    );
                    return draft;
                  }

                  draft.tasks[taskIndex].completedAt = newCompletedAt;

                  return draft;
                },
              ),
            );
          }
        }

        if ("eobPaymentId" in arg.payload && arg.action === "received") {
          const eobPaymentId = arg.payload.eobPaymentId;

          const newReceivedByPracticeAt = new Date().toISOString();

          logger.log("[mutation] updating eob payment", eobPaymentId);
          logNow();
          dispatch(
            diskApi.util.updateQueryData(
              "getPayment",
              { paymentId: eobPaymentId },
              (draft) => {
                if (!draft) {
                  logger.warn(
                    "[mutation] draft is undefined, could not update",
                  );
                  return draft;
                }

                draft.payment.receivedByPracticeAt = newReceivedByPracticeAt;
              },
            ),
          );
          logger.log(
            "[mutation] updating eob payment ledger for",
            eobPaymentId,
          );
          logNow();
        }

        if ("patientId" in arg.payload && arg.action === "resolve-balance") {
          const patientId = arg.payload.patientId;
          const familyId = arg.metadata?.familyId;

          if (!familyId || typeof familyId !== "string") {
            logger.warn("[mutation] familyId is undefined, could not update");
            return;
          }

          logger.log("[mutation] updating family balance for", familyId);
          logNow();
          dispatch(
            diskApi.util.updateQueryData("family", { familyId }, (draft) => {
              if (!draft.family) {
                logger.warn("[mutation] draft is undefined, could not update");
                return draft;
              }

              for (const patient of draft.family) {
                if (patient.balance) {
                  patient.balance.resolved = true;
                  patient.balance.lastResolvedAt = new Date().toISOString();
                }
              }
            }),
          );
        }

        logNow();

        const tags: any[] = [];

        if ("taskId" in arg.payload) {
          tags.push({
            type: "tasks",
            id: arg.payload.taskId,
          });

          if (arg.metadata?.patientId) {
            tags.push({ type: "ledger", id: arg.metadata.patientId });
            if (arg.action === "uncomplete") {
              tags.push("ledger-inbox");
            }
            tags.push("completed-ledger-inbox");
          }
          if (arg.metadata?.paymentId) {
            tags.push({ type: "payments", id: arg.metadata.paymentId });
          }
        } else if ("eobPaymentId" in arg.payload) {
          tags.push({
            type: "eobs",
            id: arg.payload.eobPaymentId,
          });
          tags.push({
            type: "eobPayments",
            id: arg.payload.eobPaymentId,
          });
          tags.push("paper-checks");
        } else if (
          "patientId" in arg.payload &&
          arg.action === "resolve-balance"
        ) {
          tags.push({ type: "patients", id: arg.payload.patientId });
          tags.push({ type: "patients", id: QueryTagId.RECENT });

          if (arg.metadata?.familyId) {
            tags.push({ type: "family", id: arg.metadata.familyId });
          }
        }

        logger.log("[visit] INVALIDATING tags", tags);

        try {
          logger.log("[mutation] waiting for query to fulfill");
          logNow();
          await queryFulfilled;

          if ("taskId" in arg.payload) {
            logger.log("[mutation] Fulfilled, writing back to db");
            if (arg.action === "complete") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET completedAt = ? WHERE id = ?`,
                [new Date().toISOString(), arg.payload.taskId],
              );
            } else if (arg.action === "uncomplete") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET completedAt = NULL WHERE id = ?`,
                [arg.payload.taskId],
              );
            }
          }
          if ("eobPaymentId" in arg.payload) {
            if (arg.action === "received") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET receivedByPracticeAt = ? WHERE id = ?`,
                [new Date().toISOString(), arg.payload.eobPaymentId],
              );
            }
          }
          if ("patientId" in arg.payload && arg.action === "resolve-balance") {
            await syncWorker.queryWithParams(
              `UPDATE ${arg.model} SET balance = json_set(balance, '$.resolved', 1, '$.lastResolvedAt', ?) WHERE id = ?`,
              [new Date().toISOString(), arg.payload.patientId],
            );
          }

          logNow();
        } catch (error) {
          logger.error("[mutation] error:", error);

          const errorMessage =
            typeof error === "object" && error !== null && "error" in error
              ? (error.error as string)
              : "Unknown error";

          toast.error(errorMessage || "Error marking task.");
        } finally {
          logger.log("[mutation] complete, invalidating state");
          dispatch(diskApi.util.invalidateTags(tags));
        }

        logNow();
      },
    }),

    // server only queries
    insuranceLogins: builder.query<
      DiskAction.InsuranceLogins["response"],
      void
    >({
      providesTags: ["insuranceLogins"],
      query: () => ({
        name: "insuranceLogins",
        options: undefined,
      }),
    }),

    bankAccounts: builder.query<DiskAction.BankAccounts["response"], void>({
      providesTags: ["bankAccounts"],
      query: () => ({
        name: "bankAccounts",
        options: undefined,
      }),
    }),

    createBankLink: builder.mutation<
      DiskAction.CreateBankLink["response"],
      DiskAction.CreateBankLink["options"]
    >({
      query: (options) => ({
        name: "createBankLink",
        options,
      }),
    }),

    completeBankLink: builder.mutation<
      DiskAction.CompleteBankLink["response"],
      DiskAction.CompleteBankLink["options"]
    >({
      query: (options) => ({
        name: "completeBankLink",
        options,
      }),
    }),

    removeBankLink: builder.mutation<
      DiskAction.RemoveBankLink["response"],
      DiskAction.RemoveBankLink["options"]
    >({
      query: (options) => ({
        name: "removeBankLink",
        options,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        logger.info(`[remove-bank] Removing bank link ${arg.bankSourceId}`);

        await queryFulfilled;

        dispatch(
          diskApi.util.updateQueryData("bankAccounts", undefined, (draft) => {
            draft.bankSources = draft.bankSources.filter(
              (source) => source.id !== arg.bankSourceId,
            );
          }),
        );
      },
    }),

    search: builder.query<
      DiskAction.Search["response"],
      DiskAction.Search["options"]
    >({
      query: (options) => ({
        name: "search",
        options,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(
          diskApi.util.updateQueryData("recentSearches", undefined, (draft) => {
            const existingSearchIdx = draft?.findIndex(
              (search) => search.query === arg.query,
            );

            if (existingSearchIdx !== -1) {
              draft[existingSearchIdx].updatedAt = new Date().toISOString();
              return;
            }

            // otherwise add it to the list
            draft?.push({
              id: -1 * Math.random(),
              query: arg.query,
              updatedAt: new Date().toISOString(),
            });
          }),
        );

        await queryFulfilled;
        dispatch(diskApi.util.invalidateTags(["recent-searches"]));
      },
    }),

    recentSearches: builder.query<DiskAction.RecentSearches["response"], void>({
      query: () => ({
        name: "recentSearches",
        options: undefined,
      }),
      providesTags: ["recent-searches"],
    }),

    clearRecentSearch: builder.mutation<
      DiskAction.ClearRecentSearch["response"],
      DiskAction.ClearRecentSearch["options"]
    >({
      query: (options) => ({
        name: "clearRecentSearch",
        options,
      }),
      onQueryStarted: async (arg, { dispatch }) => {
        dispatch(
          diskApi.util.updateQueryData("recentSearches", undefined, (draft) => {
            if (!draft) {
              logger.warn("[mutation] draft is undefined, could not update");
              return;
            }

            if (arg.id === "all") {
              // clear all
              draft.splice(0, draft.length);
              return;
            }

            const queryIdx = draft.findIndex((search) => search.id === arg.id);
            if (queryIdx === -1) {
              logger.warn("[mutation] search not found in recent searches");
              return;
            }

            draft.splice(queryIdx, 1);
          }),
        );
      },
    }),
  }),
});

export const useMutation = diskApi.useMutationMutation;
export const {
  useLedgerQuery,
  usePatientsQuery,
  useCompletedPatientInboxQuery,
  usePaymentsQuery,
  useRecentSearchesQuery,
  useClearRecentSearchMutation,
  // TODO: do we need to add a search query?
  useGetPaymentQuery,
  usePayerGroupsQuery,
  useRealtimeQuery,
  usePrefetch,
  usePatientInboxQuery,
  useFamilyQuery,
  useGetAllPaymentsQuery,
  usePaperChecksQuery,
  usePaymentsInboxQuery,
  usePostedQuery,
  useBalancesQuery,
  useInsuranceLoginsQuery,
  useBankAccountsQuery,
  useSearchQuery,
  useCreateBankLinkMutation,
  useCompleteBankLinkMutation,
  useRemoveBankLinkMutation,
  util,
} = diskApi;
