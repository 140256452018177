import { PlatformReader } from "platform-ts";

const reader = new PlatformReader();

const UnsupportedReason = {
  MOBILE: "MOBILE",
  NO_SHARED_WORKER: "NO_SHARED_WORKER",
  NO_OPFS: "NO_OPFS",
} as const;

export function isSupported() {
  if (IS_DEVELOPMENT) {
    // always supported in dev
    return {
      supported: true,
      reason: null,
    };
  }

  const platform = reader.getResult();

  if (platform.device.type === "mobile") {
    return {
      supported: false,
      reason: UnsupportedReason.MOBILE,
    };
  }

  if (!hasOPFS()) {
    return {
      supported: false,
      reason: UnsupportedReason.NO_OPFS,
    };
  }

  if (!globalThis.SharedWorker) {
    return {
      supported: false,
      reason: UnsupportedReason.NO_SHARED_WORKER,
    };
  }

  return {
    supported: true,
    reason: null,
  };
}

/**
 * Soft check for OPFS support, SQLite will do a hard check and throw later.
 */
function hasOPFS() {
  return (
    "storage" in navigator &&
    "getDirectory" in navigator.storage &&
    typeof navigator.storage.getDirectory === "function"
  );
}
