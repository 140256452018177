import type { Practice, User } from "@lassie/types";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { LocalStorage } from "../../lib/local-storage";
import { useAppSelector } from "../hooks";

const storedSelectedPractice = LocalStorage.get("selectedPractice");
const storedSelectedPracticeMetadata = LocalStorage.get(
  "selectedPracticeMetadata",
);

type PracticeSlice = {
  selectedPractice: string | null;
  practices: Practice[];
  user: User | null;
};

const INITIAL_STATE: PracticeSlice = {
  selectedPractice: storedSelectedPractice,
  practices:
    storedSelectedPractice && storedSelectedPracticeMetadata
      ? [
          {
            id: storedSelectedPracticeMetadata?.id ?? -1,
            uuid: storedSelectedPractice,
            name: storedSelectedPracticeMetadata?.name ?? "",
            ehrType: storedSelectedPracticeMetadata?.ehrType ?? "--",
            practiceGroupId:
              storedSelectedPracticeMetadata?.practiceGroupId ?? "",
            payerGroups: storedSelectedPracticeMetadata?.payerGroups ?? [],
          },
        ]
      : [],
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    // TODO: move redux into the initializer flow (as side effects)
    // to avoid force firing a side-effectless version of this at end
    desyncedSetSelectedPractice: (state, action: PayloadAction<string>) => {
      state.selectedPractice = action.payload;
    },
    selectPractice: (state, action: PayloadAction<string>) => {
      state.selectedPractice = action.payload;
      LocalStorage.set("selectedPractice", action.payload);

      const practice = state.practices.find((p) => p.uuid === action.payload);
      if (practice) {
        LocalStorage.set("selectedPracticeMetadata", practice);
        if (!IS_DEMO) {
          window.location.href = "/";
        }
      }
    },
    setPractices: (state, action: PayloadAction<Practice[]>) => {
      const newPractices = action.payload;

      state.practices = newPractices;

      if (state.selectedPractice) {
        const practice = newPractices.find(
          (p) => p.uuid === state.selectedPractice,
        );
        if (!practice) {
          state.selectedPractice = state.practices[0]?.uuid;
          LocalStorage.set("selectedPractice", state.selectedPractice);
          LocalStorage.set("selectedPracticeMetadata", state.practices[0]);
        }
      }
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});
export const {
  selectPractice,
  setPractices,
  setUser,
  desyncedSetSelectedPractice,
} = userSlice.actions;
export const userReducer = userSlice.reducer;

export const useUser = () => {
  const user = useAppSelector((state) => state.user.user);
  return user;
};

export const useSelectedPractice = () => {
  const selectedPracticeId = useAppSelector(
    (state) => state.user.selectedPractice,
  );
  const practices = useAppSelector((state) => state.user.practices);
  return useMemo(
    () => practices.find((p) => p.uuid === selectedPracticeId),
    [selectedPracticeId, practices],
  );
};

export enum EhrType {
  DENTRIX = "DENTRIX",
  OPENDENTAL = "OPENDENTAL",
  EAGLESOFT = "EAGLESOFT",
}

export const useEhrName = () => {
  const practice = useSelectedPractice();

  const ehrTypeToName = {
    [EhrType.DENTRIX]: "Dentrix",
    [EhrType.OPENDENTAL]: "Open Dental",
    [EhrType.EAGLESOFT]: "Eaglesoft",
  };

  const ehrName = practice?.ehrType
    ? ehrTypeToName[practice.ehrType]
    : "Practice System";

  let ehrType = undefined;

  switch (practice?.ehrType) {
    case EhrType.DENTRIX:
      ehrType = EhrType.DENTRIX;
      break;
    case EhrType.OPENDENTAL:
      ehrType = EhrType.OPENDENTAL;
      break;
    case EhrType.EAGLESOFT:
      ehrType = EhrType.EAGLESOFT;
      break;
  }

  return {
    ehrName,
    ehrType,
  };
};
