/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ResetImport } from './routes/reset'
import { Route as LogoutImport } from './routes/logout'
import { Route as LoginImport } from './routes/login'
import { Route as AuthorizeImport } from './routes/authorize'
import { Route as DashboardImport } from './routes/_dashboard'
import { Route as DashboardIndexImport } from './routes/_dashboard/index'
import { Route as DashboardInsuranceLoginsImport } from './routes/_dashboard/insurance-logins'
import { Route as DashboardSearchIndexImport } from './routes/_dashboard/search/index'
import { Route as DashboardPostedIndexImport } from './routes/_dashboard/posted/index'
import { Route as DashboardPaymentsIndexImport } from './routes/_dashboard/payments/index'
import { Route as DashboardPatientsIndexImport } from './routes/_dashboard/patients/index'
import { Route as DashboardBankAccountsIndexImport } from './routes/_dashboard/bank-accounts/index'
import { Route as DashboardPaymentsPaperChecksImport } from './routes/_dashboard/payments/paper-checks'
import { Route as DashboardPatientsInboxImport } from './routes/_dashboard.patients.inbox'
import { Route as DashboardPaymentsInboxIndexImport } from './routes/_dashboard/payments/inbox/index'
import { Route as DashboardPaymentsPaymentIdIndexImport } from './routes/_dashboard/payments/$paymentId/index'
import { Route as DashboardPatientsTasksIndexImport } from './routes/_dashboard/patients/tasks/index'
import { Route as DashboardPatientsBalancesIndexImport } from './routes/_dashboard/patients/balances/index'
import { Route as DashboardPatientsPatientIdIndexImport } from './routes/_dashboard/patients/$patientId/index'
import { Route as DashboardPatientsTasksReviewImport } from './routes/_dashboard/patients/tasks/review'
import { Route as DashboardPatientsTasksRefileImport } from './routes/_dashboard/patients/tasks/refile'
import { Route as DashboardPatientsTasksFileSecondaryImport } from './routes/_dashboard/patients/tasks/file-secondary'
import { Route as DashboardPatientsTasksDoneImport } from './routes/_dashboard/patients/tasks/done'
import { Route as DashboardPatientsTasksAdjustImport } from './routes/_dashboard/patients/tasks/adjust'
import { Route as DashboardPatientsBalancesFamilyIdImport } from './routes/_dashboard/patients/balances/$familyId'
import { Route as DashboardPaymentsPaymentIdClaimsEobClaimIdImport } from './routes/_dashboard/payments/$paymentId/claims.$eobClaimId'
import { Route as DashboardPatientsPatientIdVisitsClaimIdImport } from './routes/_dashboard/patients/$patientId/visits.$claimId'

// Create/Update Routes

const ResetRoute = ResetImport.update({
  id: '/reset',
  path: '/reset',
  getParentRoute: () => rootRoute,
} as any)

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizeRoute = AuthorizeImport.update({
  id: '/authorize',
  path: '/authorize',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any)

const DashboardIndexRoute = DashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardInsuranceLoginsRoute = DashboardInsuranceLoginsImport.update({
  id: '/insurance-logins',
  path: '/insurance-logins',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardSearchIndexRoute = DashboardSearchIndexImport.update({
  id: '/search/',
  path: '/search/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPostedIndexRoute = DashboardPostedIndexImport.update({
  id: '/posted/',
  path: '/posted/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPaymentsIndexRoute = DashboardPaymentsIndexImport.update({
  id: '/payments/',
  path: '/payments/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPatientsIndexRoute = DashboardPatientsIndexImport.update({
  id: '/patients/',
  path: '/patients/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardBankAccountsIndexRoute = DashboardBankAccountsIndexImport.update(
  {
    id: '/bank-accounts/',
    path: '/bank-accounts/',
    getParentRoute: () => DashboardRoute,
  } as any,
)

const DashboardPaymentsPaperChecksRoute =
  DashboardPaymentsPaperChecksImport.update({
    id: '/payments/paper-checks',
    path: '/payments/paper-checks',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsInboxRoute = DashboardPatientsInboxImport.update({
  id: '/patients/inbox',
  path: '/patients/inbox',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPaymentsInboxIndexRoute =
  DashboardPaymentsInboxIndexImport.update({
    id: '/payments/inbox/',
    path: '/payments/inbox/',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPaymentsPaymentIdIndexRoute =
  DashboardPaymentsPaymentIdIndexImport.update({
    id: '/payments/$paymentId/',
    path: '/payments/$paymentId/',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsTasksIndexRoute =
  DashboardPatientsTasksIndexImport.update({
    id: '/patients/tasks/',
    path: '/patients/tasks/',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsBalancesIndexRoute =
  DashboardPatientsBalancesIndexImport.update({
    id: '/patients/balances/',
    path: '/patients/balances/',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsPatientIdIndexRoute =
  DashboardPatientsPatientIdIndexImport.update({
    id: '/patients/$patientId/',
    path: '/patients/$patientId/',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsTasksReviewRoute =
  DashboardPatientsTasksReviewImport.update({
    id: '/patients/tasks/review',
    path: '/patients/tasks/review',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsTasksRefileRoute =
  DashboardPatientsTasksRefileImport.update({
    id: '/patients/tasks/refile',
    path: '/patients/tasks/refile',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsTasksFileSecondaryRoute =
  DashboardPatientsTasksFileSecondaryImport.update({
    id: '/patients/tasks/file-secondary',
    path: '/patients/tasks/file-secondary',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsTasksDoneRoute = DashboardPatientsTasksDoneImport.update(
  {
    id: '/patients/tasks/done',
    path: '/patients/tasks/done',
    getParentRoute: () => DashboardRoute,
  } as any,
)

const DashboardPatientsTasksAdjustRoute =
  DashboardPatientsTasksAdjustImport.update({
    id: '/patients/tasks/adjust',
    path: '/patients/tasks/adjust',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsBalancesFamilyIdRoute =
  DashboardPatientsBalancesFamilyIdImport.update({
    id: '/patients/balances/$familyId',
    path: '/patients/balances/$familyId',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPaymentsPaymentIdClaimsEobClaimIdRoute =
  DashboardPaymentsPaymentIdClaimsEobClaimIdImport.update({
    id: '/payments/$paymentId/claims/$eobClaimId',
    path: '/payments/$paymentId/claims/$eobClaimId',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardPatientsPatientIdVisitsClaimIdRoute =
  DashboardPatientsPatientIdVisitsClaimIdImport.update({
    id: '/patients/$patientId/visits/$claimId',
    path: '/patients/$patientId/visits/$claimId',
    getParentRoute: () => DashboardRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/authorize': {
      id: '/authorize'
      path: '/authorize'
      fullPath: '/authorize'
      preLoaderRoute: typeof AuthorizeImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/reset': {
      id: '/reset'
      path: '/reset'
      fullPath: '/reset'
      preLoaderRoute: typeof ResetImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard/insurance-logins': {
      id: '/_dashboard/insurance-logins'
      path: '/insurance-logins'
      fullPath: '/insurance-logins'
      preLoaderRoute: typeof DashboardInsuranceLoginsImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/': {
      id: '/_dashboard/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof DashboardIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/inbox': {
      id: '/_dashboard/patients/inbox'
      path: '/patients/inbox'
      fullPath: '/patients/inbox'
      preLoaderRoute: typeof DashboardPatientsInboxImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/payments/paper-checks': {
      id: '/_dashboard/payments/paper-checks'
      path: '/payments/paper-checks'
      fullPath: '/payments/paper-checks'
      preLoaderRoute: typeof DashboardPaymentsPaperChecksImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/bank-accounts/': {
      id: '/_dashboard/bank-accounts/'
      path: '/bank-accounts'
      fullPath: '/bank-accounts'
      preLoaderRoute: typeof DashboardBankAccountsIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/': {
      id: '/_dashboard/patients/'
      path: '/patients'
      fullPath: '/patients'
      preLoaderRoute: typeof DashboardPatientsIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/payments/': {
      id: '/_dashboard/payments/'
      path: '/payments'
      fullPath: '/payments'
      preLoaderRoute: typeof DashboardPaymentsIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/posted/': {
      id: '/_dashboard/posted/'
      path: '/posted'
      fullPath: '/posted'
      preLoaderRoute: typeof DashboardPostedIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/search/': {
      id: '/_dashboard/search/'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof DashboardSearchIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/balances/$familyId': {
      id: '/_dashboard/patients/balances/$familyId'
      path: '/patients/balances/$familyId'
      fullPath: '/patients/balances/$familyId'
      preLoaderRoute: typeof DashboardPatientsBalancesFamilyIdImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/tasks/adjust': {
      id: '/_dashboard/patients/tasks/adjust'
      path: '/patients/tasks/adjust'
      fullPath: '/patients/tasks/adjust'
      preLoaderRoute: typeof DashboardPatientsTasksAdjustImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/tasks/done': {
      id: '/_dashboard/patients/tasks/done'
      path: '/patients/tasks/done'
      fullPath: '/patients/tasks/done'
      preLoaderRoute: typeof DashboardPatientsTasksDoneImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/tasks/file-secondary': {
      id: '/_dashboard/patients/tasks/file-secondary'
      path: '/patients/tasks/file-secondary'
      fullPath: '/patients/tasks/file-secondary'
      preLoaderRoute: typeof DashboardPatientsTasksFileSecondaryImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/tasks/refile': {
      id: '/_dashboard/patients/tasks/refile'
      path: '/patients/tasks/refile'
      fullPath: '/patients/tasks/refile'
      preLoaderRoute: typeof DashboardPatientsTasksRefileImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/tasks/review': {
      id: '/_dashboard/patients/tasks/review'
      path: '/patients/tasks/review'
      fullPath: '/patients/tasks/review'
      preLoaderRoute: typeof DashboardPatientsTasksReviewImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/$patientId/': {
      id: '/_dashboard/patients/$patientId/'
      path: '/patients/$patientId'
      fullPath: '/patients/$patientId'
      preLoaderRoute: typeof DashboardPatientsPatientIdIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/balances/': {
      id: '/_dashboard/patients/balances/'
      path: '/patients/balances'
      fullPath: '/patients/balances'
      preLoaderRoute: typeof DashboardPatientsBalancesIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/tasks/': {
      id: '/_dashboard/patients/tasks/'
      path: '/patients/tasks'
      fullPath: '/patients/tasks'
      preLoaderRoute: typeof DashboardPatientsTasksIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/payments/$paymentId/': {
      id: '/_dashboard/payments/$paymentId/'
      path: '/payments/$paymentId'
      fullPath: '/payments/$paymentId'
      preLoaderRoute: typeof DashboardPaymentsPaymentIdIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/payments/inbox/': {
      id: '/_dashboard/payments/inbox/'
      path: '/payments/inbox'
      fullPath: '/payments/inbox'
      preLoaderRoute: typeof DashboardPaymentsInboxIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/$patientId/visits/$claimId': {
      id: '/_dashboard/patients/$patientId/visits/$claimId'
      path: '/patients/$patientId/visits/$claimId'
      fullPath: '/patients/$patientId/visits/$claimId'
      preLoaderRoute: typeof DashboardPatientsPatientIdVisitsClaimIdImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/payments/$paymentId/claims/$eobClaimId': {
      id: '/_dashboard/payments/$paymentId/claims/$eobClaimId'
      path: '/payments/$paymentId/claims/$eobClaimId'
      fullPath: '/payments/$paymentId/claims/$eobClaimId'
      preLoaderRoute: typeof DashboardPaymentsPaymentIdClaimsEobClaimIdImport
      parentRoute: typeof DashboardImport
    }
  }
}

// Create and export the route tree

interface DashboardRouteChildren {
  DashboardInsuranceLoginsRoute: typeof DashboardInsuranceLoginsRoute
  DashboardIndexRoute: typeof DashboardIndexRoute
  DashboardPatientsInboxRoute: typeof DashboardPatientsInboxRoute
  DashboardPaymentsPaperChecksRoute: typeof DashboardPaymentsPaperChecksRoute
  DashboardBankAccountsIndexRoute: typeof DashboardBankAccountsIndexRoute
  DashboardPatientsIndexRoute: typeof DashboardPatientsIndexRoute
  DashboardPaymentsIndexRoute: typeof DashboardPaymentsIndexRoute
  DashboardPostedIndexRoute: typeof DashboardPostedIndexRoute
  DashboardSearchIndexRoute: typeof DashboardSearchIndexRoute
  DashboardPatientsBalancesFamilyIdRoute: typeof DashboardPatientsBalancesFamilyIdRoute
  DashboardPatientsTasksAdjustRoute: typeof DashboardPatientsTasksAdjustRoute
  DashboardPatientsTasksDoneRoute: typeof DashboardPatientsTasksDoneRoute
  DashboardPatientsTasksFileSecondaryRoute: typeof DashboardPatientsTasksFileSecondaryRoute
  DashboardPatientsTasksRefileRoute: typeof DashboardPatientsTasksRefileRoute
  DashboardPatientsTasksReviewRoute: typeof DashboardPatientsTasksReviewRoute
  DashboardPatientsPatientIdIndexRoute: typeof DashboardPatientsPatientIdIndexRoute
  DashboardPatientsBalancesIndexRoute: typeof DashboardPatientsBalancesIndexRoute
  DashboardPatientsTasksIndexRoute: typeof DashboardPatientsTasksIndexRoute
  DashboardPaymentsPaymentIdIndexRoute: typeof DashboardPaymentsPaymentIdIndexRoute
  DashboardPaymentsInboxIndexRoute: typeof DashboardPaymentsInboxIndexRoute
  DashboardPatientsPatientIdVisitsClaimIdRoute: typeof DashboardPatientsPatientIdVisitsClaimIdRoute
  DashboardPaymentsPaymentIdClaimsEobClaimIdRoute: typeof DashboardPaymentsPaymentIdClaimsEobClaimIdRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardInsuranceLoginsRoute: DashboardInsuranceLoginsRoute,
  DashboardIndexRoute: DashboardIndexRoute,
  DashboardPatientsInboxRoute: DashboardPatientsInboxRoute,
  DashboardPaymentsPaperChecksRoute: DashboardPaymentsPaperChecksRoute,
  DashboardBankAccountsIndexRoute: DashboardBankAccountsIndexRoute,
  DashboardPatientsIndexRoute: DashboardPatientsIndexRoute,
  DashboardPaymentsIndexRoute: DashboardPaymentsIndexRoute,
  DashboardPostedIndexRoute: DashboardPostedIndexRoute,
  DashboardSearchIndexRoute: DashboardSearchIndexRoute,
  DashboardPatientsBalancesFamilyIdRoute:
    DashboardPatientsBalancesFamilyIdRoute,
  DashboardPatientsTasksAdjustRoute: DashboardPatientsTasksAdjustRoute,
  DashboardPatientsTasksDoneRoute: DashboardPatientsTasksDoneRoute,
  DashboardPatientsTasksFileSecondaryRoute:
    DashboardPatientsTasksFileSecondaryRoute,
  DashboardPatientsTasksRefileRoute: DashboardPatientsTasksRefileRoute,
  DashboardPatientsTasksReviewRoute: DashboardPatientsTasksReviewRoute,
  DashboardPatientsPatientIdIndexRoute: DashboardPatientsPatientIdIndexRoute,
  DashboardPatientsBalancesIndexRoute: DashboardPatientsBalancesIndexRoute,
  DashboardPatientsTasksIndexRoute: DashboardPatientsTasksIndexRoute,
  DashboardPaymentsPaymentIdIndexRoute: DashboardPaymentsPaymentIdIndexRoute,
  DashboardPaymentsInboxIndexRoute: DashboardPaymentsInboxIndexRoute,
  DashboardPatientsPatientIdVisitsClaimIdRoute:
    DashboardPatientsPatientIdVisitsClaimIdRoute,
  DashboardPaymentsPaymentIdClaimsEobClaimIdRoute:
    DashboardPaymentsPaymentIdClaimsEobClaimIdRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof DashboardRouteWithChildren
  '/authorize': typeof AuthorizeRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/reset': typeof ResetRoute
  '/insurance-logins': typeof DashboardInsuranceLoginsRoute
  '/': typeof DashboardIndexRoute
  '/patients/inbox': typeof DashboardPatientsInboxRoute
  '/payments/paper-checks': typeof DashboardPaymentsPaperChecksRoute
  '/bank-accounts': typeof DashboardBankAccountsIndexRoute
  '/patients': typeof DashboardPatientsIndexRoute
  '/payments': typeof DashboardPaymentsIndexRoute
  '/posted': typeof DashboardPostedIndexRoute
  '/search': typeof DashboardSearchIndexRoute
  '/patients/balances/$familyId': typeof DashboardPatientsBalancesFamilyIdRoute
  '/patients/tasks/adjust': typeof DashboardPatientsTasksAdjustRoute
  '/patients/tasks/done': typeof DashboardPatientsTasksDoneRoute
  '/patients/tasks/file-secondary': typeof DashboardPatientsTasksFileSecondaryRoute
  '/patients/tasks/refile': typeof DashboardPatientsTasksRefileRoute
  '/patients/tasks/review': typeof DashboardPatientsTasksReviewRoute
  '/patients/$patientId': typeof DashboardPatientsPatientIdIndexRoute
  '/patients/balances': typeof DashboardPatientsBalancesIndexRoute
  '/patients/tasks': typeof DashboardPatientsTasksIndexRoute
  '/payments/$paymentId': typeof DashboardPaymentsPaymentIdIndexRoute
  '/payments/inbox': typeof DashboardPaymentsInboxIndexRoute
  '/patients/$patientId/visits/$claimId': typeof DashboardPatientsPatientIdVisitsClaimIdRoute
  '/payments/$paymentId/claims/$eobClaimId': typeof DashboardPaymentsPaymentIdClaimsEobClaimIdRoute
}

export interface FileRoutesByTo {
  '/authorize': typeof AuthorizeRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/reset': typeof ResetRoute
  '/insurance-logins': typeof DashboardInsuranceLoginsRoute
  '/': typeof DashboardIndexRoute
  '/patients/inbox': typeof DashboardPatientsInboxRoute
  '/payments/paper-checks': typeof DashboardPaymentsPaperChecksRoute
  '/bank-accounts': typeof DashboardBankAccountsIndexRoute
  '/patients': typeof DashboardPatientsIndexRoute
  '/payments': typeof DashboardPaymentsIndexRoute
  '/posted': typeof DashboardPostedIndexRoute
  '/search': typeof DashboardSearchIndexRoute
  '/patients/balances/$familyId': typeof DashboardPatientsBalancesFamilyIdRoute
  '/patients/tasks/adjust': typeof DashboardPatientsTasksAdjustRoute
  '/patients/tasks/done': typeof DashboardPatientsTasksDoneRoute
  '/patients/tasks/file-secondary': typeof DashboardPatientsTasksFileSecondaryRoute
  '/patients/tasks/refile': typeof DashboardPatientsTasksRefileRoute
  '/patients/tasks/review': typeof DashboardPatientsTasksReviewRoute
  '/patients/$patientId': typeof DashboardPatientsPatientIdIndexRoute
  '/patients/balances': typeof DashboardPatientsBalancesIndexRoute
  '/patients/tasks': typeof DashboardPatientsTasksIndexRoute
  '/payments/$paymentId': typeof DashboardPaymentsPaymentIdIndexRoute
  '/payments/inbox': typeof DashboardPaymentsInboxIndexRoute
  '/patients/$patientId/visits/$claimId': typeof DashboardPatientsPatientIdVisitsClaimIdRoute
  '/payments/$paymentId/claims/$eobClaimId': typeof DashboardPaymentsPaymentIdClaimsEobClaimIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_dashboard': typeof DashboardRouteWithChildren
  '/authorize': typeof AuthorizeRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/reset': typeof ResetRoute
  '/_dashboard/insurance-logins': typeof DashboardInsuranceLoginsRoute
  '/_dashboard/': typeof DashboardIndexRoute
  '/_dashboard/patients/inbox': typeof DashboardPatientsInboxRoute
  '/_dashboard/payments/paper-checks': typeof DashboardPaymentsPaperChecksRoute
  '/_dashboard/bank-accounts/': typeof DashboardBankAccountsIndexRoute
  '/_dashboard/patients/': typeof DashboardPatientsIndexRoute
  '/_dashboard/payments/': typeof DashboardPaymentsIndexRoute
  '/_dashboard/posted/': typeof DashboardPostedIndexRoute
  '/_dashboard/search/': typeof DashboardSearchIndexRoute
  '/_dashboard/patients/balances/$familyId': typeof DashboardPatientsBalancesFamilyIdRoute
  '/_dashboard/patients/tasks/adjust': typeof DashboardPatientsTasksAdjustRoute
  '/_dashboard/patients/tasks/done': typeof DashboardPatientsTasksDoneRoute
  '/_dashboard/patients/tasks/file-secondary': typeof DashboardPatientsTasksFileSecondaryRoute
  '/_dashboard/patients/tasks/refile': typeof DashboardPatientsTasksRefileRoute
  '/_dashboard/patients/tasks/review': typeof DashboardPatientsTasksReviewRoute
  '/_dashboard/patients/$patientId/': typeof DashboardPatientsPatientIdIndexRoute
  '/_dashboard/patients/balances/': typeof DashboardPatientsBalancesIndexRoute
  '/_dashboard/patients/tasks/': typeof DashboardPatientsTasksIndexRoute
  '/_dashboard/payments/$paymentId/': typeof DashboardPaymentsPaymentIdIndexRoute
  '/_dashboard/payments/inbox/': typeof DashboardPaymentsInboxIndexRoute
  '/_dashboard/patients/$patientId/visits/$claimId': typeof DashboardPatientsPatientIdVisitsClaimIdRoute
  '/_dashboard/payments/$paymentId/claims/$eobClaimId': typeof DashboardPaymentsPaymentIdClaimsEobClaimIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/authorize'
    | '/login'
    | '/logout'
    | '/reset'
    | '/insurance-logins'
    | '/'
    | '/patients/inbox'
    | '/payments/paper-checks'
    | '/bank-accounts'
    | '/patients'
    | '/payments'
    | '/posted'
    | '/search'
    | '/patients/balances/$familyId'
    | '/patients/tasks/adjust'
    | '/patients/tasks/done'
    | '/patients/tasks/file-secondary'
    | '/patients/tasks/refile'
    | '/patients/tasks/review'
    | '/patients/$patientId'
    | '/patients/balances'
    | '/patients/tasks'
    | '/payments/$paymentId'
    | '/payments/inbox'
    | '/patients/$patientId/visits/$claimId'
    | '/payments/$paymentId/claims/$eobClaimId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/authorize'
    | '/login'
    | '/logout'
    | '/reset'
    | '/insurance-logins'
    | '/'
    | '/patients/inbox'
    | '/payments/paper-checks'
    | '/bank-accounts'
    | '/patients'
    | '/payments'
    | '/posted'
    | '/search'
    | '/patients/balances/$familyId'
    | '/patients/tasks/adjust'
    | '/patients/tasks/done'
    | '/patients/tasks/file-secondary'
    | '/patients/tasks/refile'
    | '/patients/tasks/review'
    | '/patients/$patientId'
    | '/patients/balances'
    | '/patients/tasks'
    | '/payments/$paymentId'
    | '/payments/inbox'
    | '/patients/$patientId/visits/$claimId'
    | '/payments/$paymentId/claims/$eobClaimId'
  id:
    | '__root__'
    | '/_dashboard'
    | '/authorize'
    | '/login'
    | '/logout'
    | '/reset'
    | '/_dashboard/insurance-logins'
    | '/_dashboard/'
    | '/_dashboard/patients/inbox'
    | '/_dashboard/payments/paper-checks'
    | '/_dashboard/bank-accounts/'
    | '/_dashboard/patients/'
    | '/_dashboard/payments/'
    | '/_dashboard/posted/'
    | '/_dashboard/search/'
    | '/_dashboard/patients/balances/$familyId'
    | '/_dashboard/patients/tasks/adjust'
    | '/_dashboard/patients/tasks/done'
    | '/_dashboard/patients/tasks/file-secondary'
    | '/_dashboard/patients/tasks/refile'
    | '/_dashboard/patients/tasks/review'
    | '/_dashboard/patients/$patientId/'
    | '/_dashboard/patients/balances/'
    | '/_dashboard/patients/tasks/'
    | '/_dashboard/payments/$paymentId/'
    | '/_dashboard/payments/inbox/'
    | '/_dashboard/patients/$patientId/visits/$claimId'
    | '/_dashboard/payments/$paymentId/claims/$eobClaimId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  DashboardRoute: typeof DashboardRouteWithChildren
  AuthorizeRoute: typeof AuthorizeRoute
  LoginRoute: typeof LoginRoute
  LogoutRoute: typeof LogoutRoute
  ResetRoute: typeof ResetRoute
}

const rootRouteChildren: RootRouteChildren = {
  DashboardRoute: DashboardRouteWithChildren,
  AuthorizeRoute: AuthorizeRoute,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
  ResetRoute: ResetRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_dashboard",
        "/authorize",
        "/login",
        "/logout",
        "/reset"
      ]
    },
    "/_dashboard": {
      "filePath": "_dashboard.tsx",
      "children": [
        "/_dashboard/insurance-logins",
        "/_dashboard/",
        "/_dashboard/patients/inbox",
        "/_dashboard/payments/paper-checks",
        "/_dashboard/bank-accounts/",
        "/_dashboard/patients/",
        "/_dashboard/payments/",
        "/_dashboard/posted/",
        "/_dashboard/search/",
        "/_dashboard/patients/balances/$familyId",
        "/_dashboard/patients/tasks/adjust",
        "/_dashboard/patients/tasks/done",
        "/_dashboard/patients/tasks/file-secondary",
        "/_dashboard/patients/tasks/refile",
        "/_dashboard/patients/tasks/review",
        "/_dashboard/patients/$patientId/",
        "/_dashboard/patients/balances/",
        "/_dashboard/patients/tasks/",
        "/_dashboard/payments/$paymentId/",
        "/_dashboard/payments/inbox/",
        "/_dashboard/patients/$patientId/visits/$claimId",
        "/_dashboard/payments/$paymentId/claims/$eobClaimId"
      ]
    },
    "/authorize": {
      "filePath": "authorize.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/reset": {
      "filePath": "reset.tsx"
    },
    "/_dashboard/insurance-logins": {
      "filePath": "_dashboard/insurance-logins.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/": {
      "filePath": "_dashboard/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/inbox": {
      "filePath": "_dashboard.patients.inbox.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/payments/paper-checks": {
      "filePath": "_dashboard/payments/paper-checks.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/bank-accounts/": {
      "filePath": "_dashboard/bank-accounts/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/": {
      "filePath": "_dashboard/patients/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/payments/": {
      "filePath": "_dashboard/payments/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/posted/": {
      "filePath": "_dashboard/posted/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/search/": {
      "filePath": "_dashboard/search/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/balances/$familyId": {
      "filePath": "_dashboard/patients/balances/$familyId.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/tasks/adjust": {
      "filePath": "_dashboard/patients/tasks/adjust.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/tasks/done": {
      "filePath": "_dashboard/patients/tasks/done.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/tasks/file-secondary": {
      "filePath": "_dashboard/patients/tasks/file-secondary.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/tasks/refile": {
      "filePath": "_dashboard/patients/tasks/refile.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/tasks/review": {
      "filePath": "_dashboard/patients/tasks/review.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/$patientId/": {
      "filePath": "_dashboard/patients/$patientId/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/balances/": {
      "filePath": "_dashboard/patients/balances/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/tasks/": {
      "filePath": "_dashboard/patients/tasks/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/payments/$paymentId/": {
      "filePath": "_dashboard/payments/$paymentId/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/payments/inbox/": {
      "filePath": "_dashboard/payments/inbox/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/$patientId/visits/$claimId": {
      "filePath": "_dashboard/patients/$patientId/visits.$claimId.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/payments/$paymentId/claims/$eobClaimId": {
      "filePath": "_dashboard/payments/$paymentId/claims.$eobClaimId.tsx",
      "parent": "/_dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
