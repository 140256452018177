import type { Collection } from "@lassie/types";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { bootstrapped } from "../actions";

type BootstrapStatusUnfulfilled = {
  progress: number;
  bootstrappedAt: null;
  status: "unfulfilled";
};

type BootstrapStatusFulfilled = {
  progress: 1;
  bootstrappedAt: string;
  status: "done";
};

type BootstrapStatusFailed = {
  progress: -1;
  bootstrappedAt: null;
  status: "failed";
};

type BootstrapStatus =
  | BootstrapStatusUnfulfilled
  | BootstrapStatusFulfilled
  | BootstrapStatusFailed;

const DEFAULT_BOOTSTRAPPED_STATUS: BootstrapStatus = {
  progress: 0,
  bootstrappedAt: null,
  status: "unfulfilled",
};

export const lifecycleSlice = createSlice({
  name: "lifecycle",
  initialState: {
    bootstrappedStatus: {
      patients: DEFAULT_BOOTSTRAPPED_STATUS,
      procedureCodes: DEFAULT_BOOTSTRAPPED_STATUS,
      tasks: DEFAULT_BOOTSTRAPPED_STATUS,
      providers: DEFAULT_BOOTSTRAPPED_STATUS,
      payerGroups: DEFAULT_BOOTSTRAPPED_STATUS,
    } as Record<Collection, BootstrapStatus>,
  },
  reducers: {
    bootstrapProgress: (
      state,
      action: PayloadAction<{ collection: Collection; progress: number }>,
    ) => {
      if (action.payload.progress !== 1) {
        state.bootstrappedStatus[action.payload.collection].progress =
          action.payload.progress;
        state.bootstrappedStatus[action.payload.collection].status =
          "unfulfilled";
      }
    },
    bootstrapFailed: (
      state,
      action: PayloadAction<{ collection: Collection }>,
    ) => {
      state.bootstrappedStatus[action.payload.collection].progress = -1;
      state.bootstrappedStatus[action.payload.collection].status = "failed";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(bootstrapped, (state, action) => {
      action.payload.forEach((collection) => {
        state.bootstrappedStatus[collection] = {
          progress: 1,
          bootstrappedAt: new Date().toISOString(),
          status: "done",
        };
      });
    });
  },
});

export const { bootstrapProgress, bootstrapFailed } = lifecycleSlice.actions;
export const lifecycleReducer = lifecycleSlice.reducer;
