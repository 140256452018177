import { configureStore } from "@reduxjs/toolkit";
import { diskApi } from "./disk/api";
import { lifecycleReducer } from "./slices/lifecycle";
import { userReducer } from "./slices/user";

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    user: userReducer,
    lifecycle: lifecycleReducer,
    [diskApi.reducerPath]: diskApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(diskApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
