import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";

import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import "overlayscrollbars/styles/overlayscrollbars.css";

import ascii from "./assets/ascii.txt?raw";
import "./fonts.css";
import "./index.css";

import { createLogger } from "./config/logger.ts";
import { isSupported } from "./lib/is-supported";

const { supported, reason } = isSupported();

if (!supported) {
  // forward to the unsupported page
  let supportUrl = `${window.location.origin}/unsupported.html`;
  if (reason) {
    supportUrl += `?reason=${reason}`;
  }
  window.location.href = supportUrl;
}

createLogger();

console.log(
  `%c${ascii.replace("$$VERSION$$", __RELEASE_NAME__)}`,
  "font-weight: bold;",
);

OverlayScrollbars.plugin(ClickScrollPlugin);

// biome-ignore lint/style/noNonNullAssertion: @see index.html
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
