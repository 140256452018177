type LogLevel = "debug" | "info" | "warn" | "error";

const hasLocalStorage = () => {
  try {
    return typeof window !== "undefined" && window.localStorage;
  } catch (e) {
    return false;
  }
};

const isDebugMode = (debug: Debug) => {
  if (debug !== "default") {
    return debug === "on";
  }

  if (hasLocalStorage()) {
    return localStorage.getItem("__lassie_debug") === "true";
  }

  return false;
};

type Debug = "on" | "off" | "default";

export function createLogger(debug: Debug = IS_DEVELOPMENT ? "on" : "default") {
  const logs: { level: LogLevel; timestamp: number; args: any[] }[] = [];

  const log = (level: LogLevel, ...args: any[]) => {
    if (isDebugMode(debug)) {
      console[level](...args);
    }
    logs.push({ level, timestamp: Date.now(), args });
  };

  const flush = () => {
    console.log(`Flushing ${logs.length} logs...`);
    logs.length = 0;
  };

  const dump = () => {
    console.log(`Dumping ${logs.length} logs...`);

    let content = "";

    content += "-".repeat(32);
    content += `\nVersion: ${__RELEASE_NAME__}`;
    content += `\nCommit: ${__SHORT_SHA__}`;
    content += `\nDownloaded At: ${Date.now()}`;
    content += "\n";
    content += "-".repeat(32);
    content += "\n";

    content += logs
      .map(
        ({ level, timestamp, args }) =>
          `${timestamp}|${level}|${args.map((arg) => JSON.stringify(arg)).join(" ")}`,
      )
      .join("\n");

    downloadLogs(`lassie-${Date.now()}.log`, content);
  };

  const logger = {
    debug: (...args: any[]) => log("debug", ...args),
    info: (...args: any[]) => log("info", ...args),
    /** @deprecated Alias for `info` */
    log: (...args: any[]) => log("info", ...args),
    warn: (...args: any[]) => log("warn", ...args),
    error: (...args: any[]) => log("error", ...args),

    isDebug: () => isDebugMode(debug),
    flush,
    dump,
  };

  if (typeof self !== "undefined") {
    // @ts-ignore -- append to global
    self.logger = logger;
  }

  return logger;
}

function downloadLogs(filename: string, content: string) {
  const element = document.createElement("a");
  const blob = new Blob([content], { type: "text/plain" });
  const url = URL.createObjectURL(blob);

  element.setAttribute("href", url);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
}

// flush logs every 15 minutes
self.setInterval(
  () => {
    if (typeof logger !== "undefined") {
      logger.flush();
    }
  },
  15 * 60 * 1000,
);
