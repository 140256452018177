import { IconMoodCry } from "@tabler/icons-react";
import { cx } from "class-variance-authority";
import { useEffect, useState } from "react";
import { LocalStorage } from "../lib/local-storage";

/**
 * Loading timer that displays the time elapsed since the component was mounted.
 */
export function LoadingTimer({
  className,
  threshold = 1000,
  metadata,
  fallback,
}: {
  className?: string;
  threshold?: number;
  metadata?: any;
  fallback?: React.ReactNode;
}) {
  if (!IS_DEVELOPMENT) {
    // only show in dev
    return null;
  }

  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => time + 10);
    }, 10);

    return () => clearInterval(interval);
  }, []);

  if (LocalStorage.get("__lassie_debug_preferences")?.showTimers !== true) {
    // if `showTimers` is disabled in the debugger, don't show the timer
    return fallback ?? null;
  }

  return (
    <div
      className={cx(
        "flex h-full w-full flex-col items-center justify-center gap-1",
        className,
        time > threshold && "!bg-red-200",
      )}
    >
      <IconMoodCry
        size={42}
        className={cx("mb-2 text-red-600", time <= threshold && "invisible")}
      />
      <h2 className="font-bold text-2xl text-red-600">Loading </h2>
      <span className="font-mono text-xl">
        {(time / 1000).toFixed(2)} seconds
      </span>
      <pre>{JSON.stringify(metadata, null, 2)}</pre>
    </div>
  );
}
