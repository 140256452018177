import z from "zod";
import { patientSchema } from "./ehr";
import { eobClaimSchema, eobPaymentSchema } from "./practice";

const patientResultItemSchema = z.object({
  type: z.literal("patient"),
  score: z.number(),
  item: patientSchema.pick({
    id: true,
    firstName: true,
    lastName: true,
    dateOfBirth: true,
    plans: true,
  }),
});

const claimResultItemSchema = z.object({
  type: z.literal("claim"),
  score: z.number(),
  item: eobClaimSchema
    .pick({
      id: true,
      claimNumber: true,
      dateOfService: true,
      paymentAmount: true,
      subscriberName: true,
      patientName: true,
      procedures: true,
      status: true,
      paymentId: true,
    })
    .extend({
      payerName: z.string(),
      payerGroupId: z.string(),
    }),
});

const paymentResultItemSchema = z.object({
  type: z.literal("payment"),
  score: z.number(),
  item: eobPaymentSchema.pick({
    id: true,
    paymentAmount: true,
    paymentMethod: true,
    paymentNumber: true,
    paymentDate: true,
    payerName: true,
    payerGroupId: true,
  }),
});

export const searchResultItemSchema = z.union([
  patientResultItemSchema,
  claimResultItemSchema,
  paymentResultItemSchema,
]);

export type SearchResultItem = z.infer<typeof searchResultItemSchema>;

export type RecentSearch = {
  id: number;
  query: string;
  updatedAt: string;
};
