import z from "zod";
import { bankTransactionSchema } from "./bank";
import {
  adjustmentSchema,
  bookEntrySchema,
  bookSchema,
  claimProcedureSchema,
  claimSchema,
  insurancePaymentSchema,
  patientPaymentSchema,
  patientSchema,
  procedureCodeSchema,
  procedureSchema,
  providerSchema,
} from "./ehr";
import {
  bankAccountSchema,
  eobClaimSchema,
  eobPaymentSchema,
  eobToEhrClaimsSchema,
  insuranceLoginSchema,
  payerGroupSchema,
  practiceSchema,
  taskSchema,
} from "./practice";

export const collectionSchema = z.enum([
  "bankTransactions",
  "claims",
  "procedures",
  "claimProcedures",
  "procedureCodes",
  "practices",
  "insurancePayments",
  "patientPayments",
  "patients",
  "providers",
  "books",
  "bookEntries",
  "adjustments",
  "tasks",
  "eobToEhrClaims",
  "eobPayments",
  "eobClaims",
  "payerGroups",
  "insuranceLogins",
  "bankAccounts",
]);

export type Collection = z.infer<typeof collectionSchema>;

const collectionToSchemas = {
  bankTransactions: bankTransactionSchema,
  patients: patientSchema,
  providers: providerSchema,
  claims: claimSchema,
  procedures: procedureSchema,
  claimProcedures: claimProcedureSchema,
  procedureCodes: procedureCodeSchema,
  practices: practiceSchema,
  insurancePayments: insurancePaymentSchema,
  patientPayments: patientPaymentSchema,
  books: bookSchema,
  bookEntries: bookEntrySchema,
  adjustments: adjustmentSchema,
  tasks: taskSchema,
  eobToEhrClaims: eobToEhrClaimsSchema,
  eobPayments: eobPaymentSchema,
  eobClaims: eobClaimSchema,
  payerGroups: payerGroupSchema,
  insuranceLogins: insuranceLoginSchema,
  bankAccounts: bankAccountSchema,
} as const satisfies Record<Collection, z.ZodTypeAny>;

export const ALL_COLLECTIONS = collectionSchema.options;
export type CollectionToModel = {
  [K in Collection]: z.infer<(typeof collectionToSchemas)[K]>;
};
