import { drizzle } from "drizzle-orm/sqlite-proxy";
import { syncWorker } from "../workers";

export const db = drizzle(async (sql, params, _method) => {
  try {
    const { rows } = await syncWorker.queryWithParams(sql, params);
    return { rows };
  } catch (e: any) {
    console.error("Error from sqlite worker proxy: ", e);
    return { rows: [] };
  }
});
