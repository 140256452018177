export enum InitializerError {
  BOOTSTRAP_FAILED = "bootstrap_failed",
  RECOVERY_FAILED = "recovery_failed",
  USER_UNMAPPED = "user_unmapped",
  RECOVERY_TIMED_OUT = "recovery_timed_out",
  BANNED_CLIENT = "banned_client",
  SERVER_DENIED_CLIENT_CREDENTIALS = "server_denied_client_credentials",
  NO_PRACTICES_FOUND = "no_practices_found",
  INVALID_KEY = "invalid_key",
}

export function getInitializerErrorText(error: InitializerError) {
  switch (error) {
    case InitializerError.BOOTSTRAP_FAILED:
      return "Could not connect to Lassie";
    case InitializerError.RECOVERY_FAILED:
      return "Could not decrypt data";
    case InitializerError.USER_UNMAPPED:
      return "Unknown user";
    case InitializerError.RECOVERY_TIMED_OUT:
      return "Could not connect to Lassie";
    case InitializerError.BANNED_CLIENT:
      return "Suspicious activity detected";
    case InitializerError.SERVER_DENIED_CLIENT_CREDENTIALS:
      return "Could not connect to Lassie";
    case InitializerError.NO_PRACTICES_FOUND:
      return "No practices found";
    case InitializerError.INVALID_KEY:
      return "Could not decrypt data";
    default:
      return "Unknown error";
  }
}

export type InitializerErrorBlob = {
  type: InitializerError;
  message?: string;
};

export enum DbInitStatus {
  NONE = 0,
  INITIALIZED = 1,
  INVALID_KEY = 2,
}
