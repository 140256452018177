import type { Client } from "./client-types";

export enum InboxType {
  ALL = "ALL",
  FILE_SECONDARY_CLAIM = "FILE_SECONDARY_CLAIM",
  REFILE_CLAIM = "REFILE_CLAIM",
  ADJUST_CLAIM = "ADJUST_CLAIM",
  REVIEW_CLAIM = "REVIEW_CLAIM",
  COMPLETED = "COMPLETED",
}

/**
 * Filters for inbox patients based on their tasks.
 */
export const INBOX_TO_FILTER: Record<
  InboxType,
  (task: Client.PatientInboxTask) => boolean
> = {
  [InboxType.ALL]: (task) => task.completedAt === null,
  [InboxType.FILE_SECONDARY_CLAIM]: (task) =>
    task.completedAt === null && task.type === InboxType.FILE_SECONDARY_CLAIM,
  [InboxType.REFILE_CLAIM]: (task) =>
    task.completedAt === null && task.type === InboxType.REFILE_CLAIM,
  [InboxType.ADJUST_CLAIM]: (task) =>
    task.completedAt === null && task.type === InboxType.ADJUST_CLAIM,
  [InboxType.REVIEW_CLAIM]: (task) => {
    if (task.completedAt !== null) {
      return false;
    }

    if (task.type === InboxType.REVIEW_CLAIM) {
      return true;
    }

    if (task.type === "OTHER" && task.note?.startsWith("Review claim")) {
      return true;
    }

    return false;
  },
  [InboxType.COMPLETED]: (task) => task.completedAt !== null,
};

export const INBOX_TO_SLUG: Record<InboxType, string> = {
  [InboxType.ALL]: "/tasks",
  [InboxType.FILE_SECONDARY_CLAIM]: "/tasks/file-secondary",
  [InboxType.REFILE_CLAIM]: "/tasks/refile",
  [InboxType.ADJUST_CLAIM]: "/tasks/adjust",
  [InboxType.REVIEW_CLAIM]: "/tasks/review",
  [InboxType.COMPLETED]: "/tasks/done",
};

export const INBOX_TAB_ORDER = [
  InboxType.ALL,
  InboxType.FILE_SECONDARY_CLAIM,
  InboxType.REFILE_CLAIM,
  InboxType.ADJUST_CLAIM,
  InboxType.REVIEW_CLAIM,
  InboxType.COMPLETED,
];

export const INBOX_TAB_LABELS = {
  [InboxType.ALL]: "All",
  [InboxType.FILE_SECONDARY_CLAIM]: "File Secondary",
  [InboxType.REFILE_CLAIM]: "Refile",
  [InboxType.ADJUST_CLAIM]: "Adjust",
  [InboxType.REVIEW_CLAIM]: "Review",
  [InboxType.COMPLETED]: "Done",
};

export const paramToInboxType = (param?: string): InboxType | null => {
  if (!param) {
    return null;
  }

  for (const [type, slug] of Object.entries(INBOX_TO_SLUG)) {
    if (slug === param) {
      return type as InboxType;
    }
  }

  return null;
};
