import type { EobPayment } from "@lassie/types";
import { KNOWN_DELTA_ACRONYMS } from "../components/common/insurance-icon";

const SPECIFIC_CASING = {
  webtpa: "WebTPA",
  bofa: "BofA",
  unitedhealthcare: "UnitedHealthcare",
};

function isKnownAcronym(str: string) {
  return [
    "ACS",
    "AARP",
    "PPO",
    "HMO",
    "SRVC",
    "SVC",
    "EFT",
    "ACH",
    "VCC",
    "PRIM", // primary
    "SEC", // secondary
    "AR",
    "CA",
    "IL",
    "NJ",
    "MI",
    "MA",
    "MN",
    "GA",
    "VA",
    "WA",
    "NC",
    "CL",
    "TX",
    "PA",
    "OH",
    "MO",
    "OK",
    "IN",
    "UT",
    "AZ",
    "NV",
    "ID",
    "CT",
    "RI",
    "DE",
    "WY",
    "AK",
    "MX",
    "UHC",
    "UMR", // united medical resources
    ...KNOWN_DELTA_ACRONYMS,
    "GEHA",
    "GHMSI",
    "CCPOA",
    "FGP",
    "BCBS",
    "APWU",
    "EMI",
    "DBA",
    "DPO",
    "MCNA",
    "TDA",
    "PEHP",
    "PNC",
    "CBS",
    "WDS", // some BCBS plan suffix
    "FEP", // some BCBS plan
    "UHCD", // some Optum plan
    "IHP", // some Optum plan
    "WWHQ", // some Aflac plan
  ].includes(str.toUpperCase());
}

function isStopWord(str: string) {
  return ["of", "the", "a", "an"].includes(str.toLowerCase());
}

/**
 * Pluralizes strings with the provided suffix
 * @default suffix = "s"
 *
 * @returns The pluralized string
 * @example
 * pluralize(1, "dog", "s")  // "dog"
 * pluralize(2, "dog", "s")  // "dogs"
 * pluralize(3, "fox", "es") // "foxes"
 */
export const pluralize = (count: number, noun: string, suffix = "s") =>
  `${noun}${count !== 1 ? suffix : ""}`;

/**
 * Capitalizes the first letter of a string
 *
 * @param str The string to capitalize
 * @returns The capitalized string
 */
export const capitalize = (str: string) => {
  if (str in SPECIFIC_CASING) {
    return SPECIFIC_CASING[str as keyof typeof SPECIFIC_CASING];
  }

  if (isKnownAcronym(str)) {
    return str.toUpperCase();
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * Capitalizes the first letter of each word in a string
 *
 * @param str The string to capitalize
 * @returns The capitalized string
 */
export const capitalizeWords = (str: string) =>
  str
    .toLowerCase()
    .split(" ")
    .map((word, idx) =>
      idx === 0
        ? capitalize(word)
        : isStopWord(word)
          ? word.toLowerCase()
          : capitalize(word),
    )
    .join(" ");

/**
 * Cents to dollars
 *
 * @param cents The number of cents
 * @param withPrefix Whether to prefix the number with a dollar sign
 * @returns The number of dollars
 */
export const toDollars = (cents: number, withPrefix = true) => {
  const isNegative = cents < 0;
  const absCents = Math.abs(cents);

  const dollars = (absCents / 100).toFixed(2);
  const dollarsWithCommas = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const prefix = withPrefix ? "$" : "";
  const sign = isNegative ? "-" : "";

  return `${sign}${prefix}${dollarsWithCommas}`;
};

export function formatPaymentMethod(
  paymentMethod: EobPayment["paymentMethod"],
) {
  switch (paymentMethod) {
    case "ACH":
      return "EFT";
    case "CHECK":
      return "Check";
    case "VCC":
      return "VCC";
    case "NON_PAYMENT":
      return "Non-Payment";
    default:
      return paymentMethod;
  }
}
