import type { Client } from "./client-types";

export enum PaymentsInboxType {
  ALL = "ALL",
  PAPER_CHECKS = "PAPER_CHECKS",
  // TODO: Add other types
  COMPLETED = "COMPLETED",
}

/**
 * Filters for inbox patients based on their tasks.
 */
export const PAYMENTS_INBOX_TO_FILTER: Record<
  PaymentsInboxType,
  (payment: Client.InboxPayment) => boolean
> = {
  [PaymentsInboxType.ALL]: (inboxPayment) => !isPaymentComplete(inboxPayment),
  [PaymentsInboxType.PAPER_CHECKS]: (inboxPayment) =>
    inboxPayment.payment.paymentMethod === "CHECK" &&
    inboxPayment.payment.receivedByPracticeAt === null,
  [PaymentsInboxType.COMPLETED]: (inboxPayment) =>
    isPaymentComplete(inboxPayment),
};

export const PAYMENTS_INBOX_TO_SLUG: Record<PaymentsInboxType, string> = {
  [PaymentsInboxType.ALL]: "all",
  [PaymentsInboxType.PAPER_CHECKS]: "checks",
  [PaymentsInboxType.COMPLETED]: "completed",
};

export const PAYMENTS_INBOX_TAB_ORDER = [
  PaymentsInboxType.ALL,
  PaymentsInboxType.PAPER_CHECKS,
];

export const PAYMENTS_INBOX_TAB_LABELS = {
  [PaymentsInboxType.ALL]: "All",
  [PaymentsInboxType.PAPER_CHECKS]: "Checks",
  [PaymentsInboxType.COMPLETED]: "Completed",
};

export const paramToInboxType = (param?: string): PaymentsInboxType | null => {
  if (!param) {
    return null;
  }

  for (const [type, slug] of Object.entries(PAYMENTS_INBOX_TO_SLUG)) {
    if (slug === param) {
      return type as PaymentsInboxType;
    }
  }

  return null;
};

// utils
export function isPaymentComplete(payment: Client.InboxPayment) {
  if (payment.task?.completedAt === null) {
    return false;
  }

  if (payment.claims.some((claim) => claim.task?.completedAt === null)) {
    return false;
  }

  return true;
}

export function getPaymentNextTask(payment: Client.InboxPayment) {
  const claimTask = payment.claims.find(
    (claim) => claim.task?.completedAt === null,
  );

  if (claimTask) {
    return claimTask;
  }

  return payment.task;
}
