import type { EobClaim, PracticePayerGroup } from "@lassie/types";
import { endOfDay, isAfter, startOfDay, subMonths } from "date-fns";
import {
  type SQLWrapper,
  and,
  asc,
  count,
  desc,
  eq,
  exists,
  gte,
  inArray,
  isNotNull,
  isNull,
  like,
  lt,
  lte,
  not,
  or,
  sql,
} from "drizzle-orm";
import type { Client } from "../../lib/client-types";
import { db } from "../../lib/drizzle/db";
import { Tables } from "../../lib/drizzle/schema";
import { LocalStorage } from "../../lib/local-storage";
import { Timer } from "../../lib/timer";
import { syncWorker } from "../../lib/workers";
import {
  handleBankAccounts,
  handleClearRecentSearch,
  handleCompleteBankLink,
  handleCreateBankLink,
  handleInsuranceLogins,
  handleRecentSearches,
  handleRemoveBankLink,
  handleSearch,
} from "./server";

import { PaperCheckTab } from "../../routes/_dashboard/payments/paper-checks";
import {
  ledgersFromDisk,
  ledgersFromNetwork,
  paymentsFromDisk,
  paymentsFromNetwork,
} from "./batcher";
import type { DiskAction } from "./types";

async function handlePatients(
  options: DiskAction.Patients["options"],
): Promise<DiskAction.Patients["response"]> {
  const { query, pagination, sort } = options;

  // pagination
  const offsetBy = (pagination.page - 1) * pagination.size;

  // sorting
  const sortKey = sort?.key || "lastViewedAt";
  const sortOrder = sort?.order || "desc";
  const sortOrderFn = sortOrder === "desc" ? desc : asc;

  // filtering
  const firstName = query?.firstName ? query.firstName : null;
  const lastName = query?.lastName ? query.lastName : null;

  const patientsResult = await db
    .select()
    .from(Tables.patients)
    .limit(pagination.size)
    .offset(offsetBy)
    .where(() => {
      const conditions: SQLWrapper[] = [
        isNull(Tables.patients.deletedAt),
        // biome-ignore lint/style/noNonNullAssertion: TEMP
        eq(Tables.patients.practiceUuid, LocalStorage.get("selectedPractice")!),
      ];

      if (firstName) {
        conditions.push(like(Tables.patients.firstName, `%${firstName}%`));
      }

      if (lastName) {
        conditions.push(like(Tables.patients.lastName, `%${lastName}%`));
      }

      return and(...conditions);
    })
    .orderBy(sortOrderFn(Tables.patients[sortKey]))
    .execute();

  const patients = patientsResult.map((patient) => {
    return {
      ...patient,
      ledgerBalance: 314,
    };
  });

  return { patients };
}

async function handlePatientLedger(
  options: DiskAction.Ledger["options"],
): Promise<DiskAction.Ledger["response"]> {
  const patientId = options.patientId;

  const hasPrefetched = await syncWorker.hasPrefetchedLedger(patientId);

  if (!hasPrefetched) {
    await ledgersFromNetwork.fetch(patientId);
  }

  const ledger = await ledgersFromDisk.fetch(patientId);

  return ledger;
}

async function handleAllPayments(
  _options: DiskAction.GetAllPayments["options"],
): Promise<DiskAction.GetAllPayments["response"]> {
  const payments = await db
    .select()
    .from(Tables.eobPayments)
    .where(
      eq(
        Tables.eobPayments.practiceUuid,
        // biome-ignore lint/style/noNonNullAssertion: TEMP
        LocalStorage.get("selectedPractice")!,
      ),
    )
    .limit(50)
    .orderBy(desc(Tables.eobPayments.paymentDate))
    .execute();

  return { payments };
}

async function handlePayments(
  options: DiskAction.Payments["options"],
): Promise<DiskAction.Payments["response"]> {
  const { query, pagination, sort } = options;

  // pagination
  const offsetBy = (pagination.page - 1) * pagination.size;

  // sorting
  const sortKey = sort?.key || "paymentDate";
  const sortOrder = sort?.order || "desc";
  const sortOrderFn = sortOrder === "desc" ? desc : asc;

  const payments = await db
    .select()
    .from(Tables.eobPayments)
    .limit(pagination.size)
    .offset(offsetBy)
    .where(() => {
      const conditions: SQLWrapper[] = [
        eq(
          Tables.eobPayments.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ];

      if (query?.traceNumber) {
        conditions.push(
          like(Tables.eobPayments.paymentNumber, `%${query.traceNumber}%`),
        );
      }

      if (query?.amount) {
        // if the user specifies an amount with a decimal, we assume they want an exact match
        const isCentSpecific = query.amount.includes(".");

        const cents = Number(query.amount) * 100;

        if (!Number.isNaN(cents)) {
          if (isCentSpecific) {
            // look for an exact match
            conditions.push(eq(Tables.eobPayments.paymentAmount, cents));
          } else {
            conditions.push(gte(Tables.eobPayments.paymentAmount, cents));
            // add a dollar since we're not looking for floats
            conditions.push(lt(Tables.eobPayments.paymentAmount, cents + 100));
          }
        }
      }

      if (query?.dateRange) {
        const { from, to } = query.dateRange;

        if (from) {
          conditions.push(
            gte(Tables.eobPayments.paymentDate, from.toISOString()),
          );

          const end = to ? endOfDay(to) : endOfDay(from);

          conditions.push(
            lte(Tables.eobPayments.paymentDate, end.toISOString()),
          );
        }
      }

      if (query?.payerGroupId) {
        conditions.push(
          eq(Tables.eobPayments.payerGroupId, query.payerGroupId),
        );
      }

      return and(...conditions);
    })
    .orderBy(sortOrderFn(Tables.eobPayments[sortKey]))
    .execute();
  return { payments };
}

async function handlePayment(
  options: DiskAction.GetPayment["options"],
): Promise<DiskAction.GetPayment["response"]> {
  const paymentId = options.paymentId;

  let payment = await paymentsFromDisk.fetch(paymentId);

  const hasPrefetched = await syncWorker.hasPrefetchedPayment(paymentId);
  const hasNoData = !payment?.paymentId;

  if (!hasPrefetched && hasNoData) {
    await paymentsFromNetwork.fetch(paymentId);

    payment = await paymentsFromDisk.fetch(paymentId);
  }

  return payment;
}

async function handlePatientInbox(
  _options: DiskAction.PatientInbox["options"],
): Promise<DiskAction.PatientInbox["response"]> {
  const timer = new Timer("Inbox RPC Timer");

  const tasksResult = await db
    .select({
      id: Tables.tasks.id,
      type: Tables.tasks.type,
      note: Tables.tasks.note,
      createdAt: Tables.tasks.createdAt,
      updatedAt: Tables.tasks.updatedAt,
      completedAt: Tables.tasks.completedAt,

      patientId: Tables.eobToEhrClaims.ehrPatientId,
      ehrClaimId: Tables.eobToEhrClaims.ehrClaimId,
      eobClaimId: Tables.eobToEhrClaims.eobClaimId,
      eobClaim: Tables.eobClaims,
    })
    .from(Tables.tasks)
    .where(
      and(
        isNull(Tables.tasks.deletedAt),
        isNull(Tables.tasks.completedAt),
        not(eq(Tables.tasks.type, "POST_CLAIM")),
        eq(
          Tables.tasks.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
        exists(
          db
            .select()
            .from(Tables.claimProcedures)
            .where(
              and(
                eq(
                  Tables.claimProcedures.claimId,
                  Tables.eobToEhrClaims.ehrClaimId,
                ),
                isNull(Tables.claimProcedures.deletedAt),
              ),
            ),
        ),
      ),
    )
    .innerJoin(
      Tables.eobToEhrClaims,
      eq(Tables.tasks.claimId, Tables.eobToEhrClaims.eobClaimId),
    )
    .leftJoin(
      Tables.eobClaims,
      eq(Tables.eobToEhrClaims.eobClaimId, Tables.eobClaims.id),
    )
    .orderBy(desc(Tables.tasks.updatedAt))
    .execute();

  timer.check("TASK QUERY");

  const patientIds = tasksResult.map((task) => task.patientId);

  const patientResult = await db
    .select({
      id: Tables.patients.id,
      bookId: Tables.patients.bookId,
      firstName: Tables.patients.firstName,
      lastName: Tables.patients.lastName,
      dateOfBirth: Tables.patients.dateOfBirth,
      familyId: Tables.patients.familyId,
      plans: Tables.patients.plans,
      createdAt: Tables.patients.createdAt,
      updatedAt: Tables.patients.updatedAt,
      deletedAt: Tables.patients.deletedAt,
      lastViewedAt: Tables.patients.lastViewedAt,
      ledgerBalance: sql<number>`sum(${Tables.bookEntries.debitAmount} - ${Tables.bookEntries.creditAmount})`,
    })
    .from(Tables.patients)
    .where(inArray(Tables.patients.id, patientIds))
    .leftJoin(
      Tables.bookEntries,
      sql`${Tables.patients.bookId} = ${Tables.bookEntries.bookId}`,
    )
    .groupBy(Tables.patients.id)
    .execute();

  timer.check("PATIENT QUERY");

  const tasks = tasksResult
    .map((task) => {
      const patient = patientResult.find((p) => p.id === task.patientId);

      if (!patient) {
        console.warn(`[Task ${task.id}] patient not found: ${task.patientId}`);
        return null;
      }

      if (!task.eobClaim) {
        console.warn(
          `[Task ${task.id}] eob claim not found: ${task.eobClaimId}`,
        );
        return null;
      }

      return {
        ...task,
        patient,
      };
    })
    .filter((t) => t !== null);

  timer.check("TASK MERGE");

  timer.elapsed();

  // TODO: FIX THIS TYPE
  return tasks as unknown as Client.PatientInboxTask[];
}

async function handleCompletedPatientInbox(
  options: DiskAction.CompletedPatientInbox["options"],
): Promise<DiskAction.CompletedPatientInbox["response"]> {
  const { pagination } = options;
  const offsetBy = (pagination.page - 1) * pagination.size;

  const timer = new Timer("Completed Inbox RPC Timer");

  const tasksResult = await db
    .select({
      id: Tables.tasks.id,
      type: Tables.tasks.type,
      note: Tables.tasks.note,
      createdAt: Tables.tasks.createdAt,
      updatedAt: Tables.tasks.updatedAt,
      completedAt: Tables.tasks.completedAt,

      patientId: Tables.eobToEhrClaims.ehrPatientId,
      ehrClaimId: Tables.eobToEhrClaims.ehrClaimId,
      eobClaimId: Tables.eobToEhrClaims.eobClaimId,
      eobClaim: Tables.eobClaims,
    })
    .from(Tables.tasks)
    .limit(pagination.size)
    .offset(offsetBy)
    .where(
      and(
        isNull(Tables.tasks.deletedAt),
        isNotNull(Tables.tasks.completedAt),
        eq(
          Tables.tasks.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ),
    )
    .innerJoin(
      Tables.eobToEhrClaims,
      eq(Tables.tasks.claimId, Tables.eobToEhrClaims.eobClaimId),
    )
    .leftJoin(
      Tables.eobClaims,
      eq(Tables.eobToEhrClaims.eobClaimId, Tables.eobClaims.id),
    )
    .orderBy(desc(Tables.tasks.completedAt))
    .execute();

  timer.check("TASK QUERY");

  const patientIds = tasksResult.map((task) => task.patientId);

  const patientResult = await db
    .select({
      id: Tables.patients.id,
      bookId: Tables.patients.bookId,
      firstName: Tables.patients.firstName,
      lastName: Tables.patients.lastName,
      dateOfBirth: Tables.patients.dateOfBirth,
      familyId: Tables.patients.familyId,
      plans: Tables.patients.plans,
      createdAt: Tables.patients.createdAt,
      updatedAt: Tables.patients.updatedAt,
      deletedAt: Tables.patients.deletedAt,
      lastViewedAt: Tables.patients.lastViewedAt,
      ledgerBalance: sql<number>`sum(${Tables.bookEntries.debitAmount} - ${Tables.bookEntries.creditAmount})`,
    })
    .from(Tables.patients)
    .where(inArray(Tables.patients.id, patientIds))
    .leftJoin(
      Tables.bookEntries,
      sql`${Tables.patients.bookId} = ${Tables.bookEntries.bookId}`,
    )
    .groupBy(Tables.patients.id)
    .execute();

  timer.check("PATIENT QUERY");

  const tasks = tasksResult
    .map((task) => {
      const patient = patientResult.find((p) => p.id === task.patientId);

      if (!patient) {
        console.warn(`[Task ${task.id}] patient not found: ${task.patientId}`);
        return null;
      }

      if (!task.eobClaim) {
        console.warn(
          `[Task ${task.id}] eob claim not found: ${task.eobClaimId}`,
        );
        return null;
      }

      return {
        ...task,
        patient,
      };
    })
    .filter((t) => t !== null);

  timer.check("TASK MERGE");

  timer.elapsed();

  // TODO: FIX THIS TYPE
  return tasks as unknown as Client.PatientInboxTask[];
}

async function handlePaymentsInbox(
  _options: DiskAction.PaymentsInbox["options"],
): Promise<DiskAction.PaymentsInbox["response"]> {
  const timer = new Timer("Payments Inbox RPC Timer");

  const paymentTasks = await db
    .select({
      id: Tables.tasks.id,
      type: Tables.tasks.type,
      note: Tables.tasks.note,
      createdAt: Tables.tasks.createdAt,
      updatedAt: Tables.tasks.updatedAt,
      completedAt: Tables.tasks.completedAt,
      claimId: Tables.tasks.claimId,
      paymentId: Tables.tasks.paymentId,
    })
    .from(Tables.tasks)
    .where(
      and(
        isNull(Tables.tasks.deletedAt),
        isNull(Tables.tasks.completedAt),
        isNotNull(Tables.tasks.paymentId),
        or(
          inArray(Tables.tasks.type, ["POST_CLAIM", "RESOLVE_PAYMENT"]),
          // other payment-specific tasks
          and(eq(Tables.tasks.type, "OTHER"), isNull(Tables.tasks.claimId)),
        ),
        eq(
          Tables.tasks.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ),
    )
    .orderBy(desc(Tables.tasks.updatedAt))
    .execute();

  timer.check("TASK QUERY");

  const tasksByPaymentId: Record<
    string,
    {
      paymentTask: Client.InboxTask | null;
      claimTasks: Record<string, Client.InboxTask>;
    }
  > = {};

  for (const task of paymentTasks) {
    // biome-ignore lint/style/noNonNullAssertion: in query
    const paymentId = task.paymentId!;

    if (!tasksByPaymentId[paymentId]) {
      // init empty task data
      tasksByPaymentId[paymentId] = {
        paymentTask: null,
        claimTasks: {},
      };
    }

    const claimId = task.claimId;

    if (claimId) {
      tasksByPaymentId[paymentId].claimTasks[claimId] = task;
    } else {
      tasksByPaymentId[paymentId].paymentTask = task;
    }
  }

  timer.check("TASK GROUPING");

  const paymentIds = Object.keys(tasksByPaymentId);

  const paymentsResult = await db
    .select({
      payment: Tables.eobPayments,
      bankTransaction: Tables.bankTransactions,
    })
    .from(Tables.eobPayments)
    .leftJoin(
      Tables.bankTransactions,
      eq(Tables.eobPayments.bankTransactionId, Tables.bankTransactions.id),
    )
    .where(inArray(Tables.eobPayments.id, paymentIds))
    .execute();

  const claimsResult = await db
    .select({
      eobClaim: Tables.eobClaims,
      ehrClaimId: Tables.eobToEhrClaims.ehrClaimId,
      ehrPatientId: Tables.eobToEhrClaims.ehrPatientId,
    })
    .from(Tables.eobClaims)
    .leftJoin(
      Tables.eobToEhrClaims,
      eq(Tables.eobClaims.id, Tables.eobToEhrClaims.eobClaimId),
    )
    .where(inArray(Tables.eobClaims.paymentId, paymentIds))
    .execute();

  const claimsByPaymentId = claimsResult.reduce(
    (acc, claim) => {
      if (!acc[claim.eobClaim.paymentId]) {
        acc[claim.eobClaim.paymentId] = [];
      }
      acc[claim.eobClaim.paymentId].push({
        ...claim.eobClaim,
        ehrClaimId: claim.ehrClaimId,
        ehrPatientId: claim.ehrPatientId,
      });
      return acc;
    },
    {} as Record<string, Client.EobClaimWithEhr[]>,
  );

  timer.check("PAYMENT/CLAIMS QUERY");

  const payments = paymentsResult.map((paymentResult) => {
    const { payment, bankTransaction } = paymentResult;

    const claims = claimsByPaymentId[payment.id];

    const paymentTasks = tasksByPaymentId[payment.id];

    const paymentTask = paymentTasks.paymentTask;
    const claimsWithTasks = claims.map((claim) => {
      const claimTask = paymentTasks.claimTasks[claim.id];
      return {
        ...claim,
        task: claimTask ?? null,
      };
    });

    return {
      payment,
      claims: claimsWithTasks,
      bankTransaction,
      task: paymentTask,
    };
  });

  timer.check("MERGE");

  payments.sort((a, b) => {
    return (
      new Date(a.payment.paymentDate).getTime() -
      new Date(b.payment.paymentDate).getTime()
    );
  });

  timer.check("SORT");

  timer.elapsed();

  return { payments };
}

const getPostedByStatuses = (
  postedBy?: DiskAction.Posted["options"]["postedBy"],
): EobClaim["status"][] => {
  if (postedBy === "Lassie") {
    return ["POSTED_BY_US"];
  }
  if (postedBy === "Practice") {
    return ["POSTED_BY_PRACTICE", "SKIPPED"];
  }
  return ["POSTED_BY_US", "POSTED_BY_PRACTICE", "SKIPPED"];
};

async function handlePosted(
  options: DiskAction.Posted["options"],
): Promise<DiskAction.Posted["response"]> {
  const timer = new Timer("Posted RPC Timer");

  const {
    dateRange,
    postedBy,
    groupBy,
    payerGroupId,
    patientName,
    claimNumber,
    paymentNumber,
    postedAmount,
  } = options;
  const { from: startDate, to: endDate } = dateRange;

  const claims = await db
    .select()
    .from(Tables.eobClaims)
    .where(() => {
      const conditions: SQLWrapper[] = [
        inArray(Tables.eobClaims.status, getPostedByStatuses(postedBy)),
        isNotNull(Tables.eobClaims.processedAt),
        gte(Tables.eobClaims.processedAt, startOfDay(startDate).toISOString()),
        lte(Tables.eobClaims.processedAt, endOfDay(endDate).toISOString()),
        eq(
          Tables.eobClaims.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ];

      if (patientName) {
        conditions.push(like(Tables.eobClaims.patientName, `%${patientName}%`));
      }

      return and(...conditions);
    });

  timer.check("CLAIM QUERY");

  // Step 2: Get completed tasks for SKIPPED claims
  const skippedClaimIds = claims
    .filter((claim) => claim.status === "SKIPPED")
    .map((claim) => claim.id);

  let completedTasks: { claimId: string | null; completedAt: string | null }[] =
    [];

  if (skippedClaimIds.length) {
    // we only need to make this query if there are any skipped claims
    completedTasks = await db
      .select({
        claimId: Tables.tasks.claimId,
        completedAt: Tables.tasks.completedAt,
      })
      .from(Tables.tasks)
      .where(
        and(
          inArray(Tables.tasks.claimId, skippedClaimIds),
          isNotNull(Tables.tasks.completedAt),
        ),
      );
  }

  timer.check("TASK QUERY");

  // Step 3: Get all relevant payments
  const paymentIds = [...new Set(claims.map((claim) => claim.paymentId))];

  const paymentResults = await db
    .select({
      payment: Tables.eobPayments,
      task: Tables.tasks,
    })
    .from(Tables.eobPayments)
    .leftJoin(Tables.tasks, eq(Tables.eobPayments.id, Tables.tasks.paymentId))
    .where(() => {
      const conditions: SQLWrapper[] = [
        inArray(Tables.eobPayments.id, paymentIds),
      ];

      if (payerGroupId) {
        conditions.push(eq(Tables.eobPayments.payerGroupId, payerGroupId));
      }

      return and(...conditions);
    });

  timer.check("PAYMENT QUERY");

  // Step 4: Process and group the data in memory
  const completedTaskSet = new Set(completedTasks.map((task) => task.claimId));
  const paymentMap = new Map(
    paymentResults.map((result) => [
      result.payment.id,
      {
        ...result.payment,
        task: result.task,
      },
    ]),
  );

  if (groupBy === "Payments") {
    timer.check("Grouping by payments...");

    const groupedClaimsByPayment = claims.reduce(
      (acc, claim) => {
        const isPosted =
          claim.status === "POSTED_BY_PRACTICE" ||
          claim.status === "POSTED_BY_US" ||
          (claim.status === "SKIPPED" && completedTaskSet.has(claim.id));

        if (isPosted) {
          // biome-ignore lint/style/noNonNullAssertion: in query
          const processedDate = new Date(claim.processedAt!).toDateString();
          const key = `${claim.paymentId}-${processedDate}`;
          const claimPayment = paymentMap.get(claim.paymentId);

          if (!claimPayment) {
            if (!payerGroupId) {
              console.warn(
                `[Posted/payments] payment not found: ${claim.paymentId} with no payerGroupId filter`,
              );
            }
            return acc;
          }

          const isPostedByUs = claim.status === "POSTED_BY_US";
          const isPostedByPractice =
            claim.status === "POSTED_BY_PRACTICE" ||
            (claim.status === "SKIPPED" && completedTaskSet.has(claim.id));

          if (!acc[key]) {
            acc[key] = {
              claimsProcessedAt: processedDate,
              claimPaymentAmount: 0,
              eobPayment: claimPayment,
              postedByLassie: 0,
              postedByPractice: 0,
            };
          }

          acc[key].claimPaymentAmount += claim.paymentAmount;
          if (isPostedByUs) {
            acc[key].postedByLassie += claim.paymentAmount;
          } else if (isPostedByPractice) {
            acc[key].postedByPractice += claim.paymentAmount;
          }
        }

        return acc;
      },
      {} as Record<string, Client.PostedPayment>,
    );

    let payments = [...Object.values(groupedClaimsByPayment)].sort(
      (a, b) =>
        new Date(b.claimsProcessedAt).getTime() -
        new Date(a.claimsProcessedAt).getTime(),
    );

    if (paymentNumber) {
      // TODO sort by relevance
      payments = payments.filter((p) =>
        p.eobPayment.paymentNumber
          .toLowerCase()
          .includes(paymentNumber.toLowerCase()),
      );
    }

    if (postedAmount) {
      const postedCents =
        Number(postedAmount.replace("$", "").replace(",", "")) * 100;
      if (!Number.isNaN(postedCents)) {
        const remainderCents = postedCents % 100;

        if (remainderCents === 0) {
          payments = payments.filter((p) => {
            const totalPosted = p.postedByLassie + p.postedByPractice;
            return (
              totalPosted >= postedCents &&
              // if there are no cents specified, we want to match any amount
              // within the dollar
              totalPosted < postedCents + 100
            );
          });
        } else {
          payments = payments.filter(
            (p) => p.eobPayment.paymentAmount === postedCents,
          );
        }
      }
    }

    timer.elapsed();

    return {
      payments,
    };
  }

  if (groupBy === "Claims") {
    timer.check("Grouping by claims...");
    let claimsResult = claims
      .filter((claim) => {
        const isPosted =
          claim.status === "POSTED_BY_PRACTICE" ||
          claim.status === "POSTED_BY_US" ||
          (claim.status === "SKIPPED" && completedTaskSet.has(claim.id));

        return isPosted;
      })
      .map((claim) => {
        // biome-ignore lint/style/noNonNullAssertion: in query
        const payment = paymentMap.get(claim.paymentId)!;
        // biome-ignore lint/style/noNonNullAssertion: in query
        const processedDate = new Date(claim.processedAt!).toDateString();
        return {
          eobClaim: claim,
          eobPayment: payment,
          claimsProcessedAt: processedDate,
          claimPaymentAmount: claim.paymentAmount,
          postedByLassie:
            claim.status === "POSTED_BY_US" ? claim.paymentAmount : 0,
          postedByPractice:
            claim.status === "POSTED_BY_PRACTICE" ||
            (claim.status === "SKIPPED" && completedTaskSet.has(claim.id))
              ? claim.paymentAmount
              : 0,
        };
      })
      .filter((claim) => claim.eobPayment !== undefined)
      .sort((a, b) => {
        return (
          new Date(b.claimsProcessedAt).getTime() -
          new Date(a.claimsProcessedAt).getTime()
        );
      });

    timer.elapsed();

    if (claimNumber) {
      // TODO sort by relevance
      claimsResult = claimsResult.filter((c) =>
        c.eobClaim.claimNumber
          .toLowerCase()
          .includes(claimNumber.toLowerCase()),
      );
    }

    if (postedAmount) {
      const postedCents =
        Number(postedAmount.replace("$", "").replace(",", "")) * 100;
      if (!Number.isNaN(postedCents)) {
        const remainderCents = postedCents % 100;

        if (remainderCents === 0) {
          claimsResult = claimsResult.filter(
            (c) =>
              c.claimPaymentAmount >= postedCents &&
              // if there are no cents specified, we want to match any amount
              // within the dollar
              c.claimPaymentAmount < postedCents + 100,
          );
        } else {
          claimsResult = claimsResult.filter(
            (c) => c.claimPaymentAmount === postedCents,
          );
        }
      }
    }

    return {
      claims: claimsResult,
    };
  }

  throw new Error(
    "[Invariant] No group by available for posted (claims or payments)",
  );
}

async function handlePaperChecks(
  options: DiskAction.PaperChecks["options"],
): Promise<DiskAction.PaperChecks["response"]> {
  const { status } = options;
  const threeMonthsAgo = startOfDay(subMonths(new Date(), 3)).toISOString();
  const selectedPractice = LocalStorage.get("selectedPractice");
  const selectedPracticeMetadata = LocalStorage.get("selectedPracticeMetadata");

  if (!selectedPractice || !selectedPracticeMetadata) {
    throw new Error("No selected practice or practice metadata");
  }

  const payments = await db
    .select()
    .from(Tables.eobPayments)
    .where(() => {
      const conditions: SQLWrapper[] = [
        inArray(Tables.eobPayments.paymentMethod, ["CHECK", "VCC"]),
        eq(Tables.eobPayments.practiceUuid, selectedPractice),
      ];

      if (status === PaperCheckTab.AWAITING) {
        conditions.push(isNull(Tables.eobPayments.receivedByPracticeAt));
        conditions.push(gte(Tables.eobPayments.paymentDate, threeMonthsAgo));
      } else {
        conditions.push(isNotNull(Tables.eobPayments.receivedByPracticeAt));
      }

      return and(...conditions);
    })
    .orderBy(desc(Tables.eobPayments.paymentDate))
    .execute();

  const [counts] = await db
    .select({
      // TODO: Filter by posting start on counts
      awaiting: sql<number>`
        COUNT(CASE 
          WHEN receivedByPracticeAt IS NULL 
          AND paymentDate >= ${threeMonthsAgo}
          THEN 1 END)`,
      received: sql<number>`
        COUNT(CASE 
          WHEN receivedByPracticeAt IS NOT NULL 
          THEN 1 END)`,
    })
    .from(Tables.eobPayments)
    .where(
      and(
        inArray(Tables.eobPayments.paymentMethod, ["CHECK", "VCC"]),
        eq(Tables.eobPayments.practiceUuid, selectedPractice),
      ),
    )
    .execute();

  const payerGroupsById = selectedPracticeMetadata.payerGroups.reduce(
    (acc, pg) => {
      acc[pg.id] = pg;
      return acc;
    },
    {} as Record<string, PracticePayerGroup>,
  );

  // Posting start dates are only available by session, so we need to filter
  // outside of the query.
  const filteredPayments = payments.filter((payment) => {
    const payerGroupId = payment.payerGroupId;
    const payerGroup = payerGroupsById[payerGroupId];

    if (!payerGroup) {
      return false;
    }

    const paymentDate = new Date(payment.paymentDate);
    const postingStartDate = payerGroup?.postingStartDate
      ? new Date(payerGroup.postingStartDate)
      : null;

    if (!postingStartDate) {
      return false;
    }

    return isAfter(paymentDate, postingStartDate);
  });

  return {
    payments: filteredPayments,
    counts: {
      awaiting: counts.awaiting,
      received: counts.received,
      unposted: 0,
    },
  };
}

async function handlePayerGroups(
  _options: DiskAction.PayerGroups["options"],
): Promise<DiskAction.PayerGroups["response"]> {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("No selected practice");
  }

  const payerGroupsQuery = db
    .select({
      id: Tables.payerGroups.id,
      name: Tables.payerGroups.name,
    })
    .from(Tables.eobPayments)
    .innerJoin(
      Tables.payerGroups,
      eq(Tables.eobPayments.payerGroupId, Tables.payerGroups.id),
    )
    .where(eq(Tables.eobPayments.practiceUuid, selectedPractice))
    .groupBy(Tables.payerGroups.id)
    .orderBy(desc(count(Tables.eobPayments.id)));

  const payerGroups = await payerGroupsQuery.execute();

  return { payerGroups };
}

async function handleFamily(
  options: DiskAction.Family["options"],
): Promise<DiskAction.Family["response"]> {
  const { familyId } = options;
  const patientResult = await db
    .select({
      id: Tables.patients.id,
      bookId: Tables.patients.bookId,
      firstName: Tables.patients.firstName,
      lastName: Tables.patients.lastName,
      dateOfBirth: Tables.patients.dateOfBirth,
      familyId: Tables.patients.familyId,
      plans: Tables.patients.plans,
      createdAt: Tables.patients.createdAt,
      updatedAt: Tables.patients.updatedAt,
      deletedAt: Tables.patients.deletedAt,
      lastViewedAt: Tables.patients.lastViewedAt,
      balance: Tables.patients.balance,
      isGuarantor: Tables.patients.isGuarantor,
    })
    .from(Tables.patients)
    .where(eq(Tables.patients.familyId, familyId))
    .groupBy(Tables.patients.id)
    .execute();

  // TODO: FIX THIS TYPE
  return { family: patientResult } as any;
}

async function handleBalances(
  options: DiskAction.Balances["options"],
): Promise<DiskAction.Balances["response"]> {
  const timer = new Timer("Balances RPC Timer");

  const { type, pagination, filters } = options;

  // pagination
  const offsetBy = (pagination.page - 1) * pagination.size;

  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("No selected practice");
  }

  const patients = await db
    .select()
    .from(Tables.patients)
    .limit(pagination.size)
    .offset(offsetBy)
    .where(() => {
      const conditions: SQLWrapper[] = [
        isNotNull(Tables.patients.balance),
        eq(Tables.patients.isGuarantor, true),
        eq(Tables.patients.practiceUuid, selectedPractice),
        sql`json_extract(${Tables.patients.balance}, '$.openFamilyBalance') = 0`,
        // less than 0 for credits, greater than 0 for debits
        type === "debit"
          ? sql`json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') > 0`
          : sql`json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') < 0`,
        sql`json_extract(${Tables.patients.balance}, '$.resolved') = 0`,
        sql`json_extract(${Tables.patients.balance}, '$.archivedAt') is NULL`,
      ];

      if (filters?.lastName) {
        conditions.push(
          like(Tables.patients.lastName, `%${filters.lastName}%`),
        );
      }

      return and(...conditions);
    })
    .orderBy(
      sql`json_extract(${Tables.patients.balance}, '$.lastClaimPostedAt') DESC NULLS LAST,
          json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') DESC`,
    )
    .execute();

  const totals = await db
    .select({
      debitsCount: sql<number>`
      COUNT(CASE WHEN json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') > 0 THEN 1 END)
    `,
      creditsCount: sql<number>`
      COUNT(CASE WHEN json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') < 0 THEN 1 END)
    `,
      debitsSum: sql<number>`
      COALESCE(SUM(CASE 
        WHEN json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') > 0 
        THEN CAST(json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') AS FLOAT) 
        END), 0)
    `,
      creditsSum: sql<number>`
      COALESCE(SUM(CASE 
        WHEN json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') < 0 
        THEN CAST(json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') AS FLOAT) 
        END), 0)
    `,
    })
    .from(Tables.patients)
    .where(
      and(
        isNotNull(Tables.patients.balance),
        eq(Tables.patients.isGuarantor, true),
        eq(Tables.patients.practiceUuid, selectedPractice),
        sql`json_extract(${Tables.patients.balance}, '$.openFamilyBalance') = 0`,
        sql`json_extract(${Tables.patients.balance}, '$.resolved') = 0`,
        sql`json_extract(${Tables.patients.balance}, '$.archivedAt') is NULL`,
        filters?.lastName
          ? like(Tables.patients.lastName, `%${filters.lastName}%`)
          : undefined,
      ),
    )
    .execute();

  timer.check("TOTALS QUERY");

  timer.elapsed();

  return {
    patients,

    // totals
    debitsCount: totals[0].debitsCount,
    creditsCount: totals[0].creditsCount,
    debitsAmount: totals[0].debitsSum,
    creditsAmount: totals[0].creditsSum,

    // TODO: FIX THIS TYPE
  } as any;
}

async function diskQuery(
  action: DiskAction.Query,
): Promise<DiskAction.Response<typeof action>> {
  if (action.name === "patients") {
    return handlePatients(action.options);
  }
  if (action.name === "ledger") {
    return handlePatientLedger(action.options);
  }
  if (action.name === "realtime") {
    return null;
  }
  if (action.name === "patientInbox") {
    return handlePatientInbox(action.options);
  }
  if (action.name === "allPayments") {
    return handleAllPayments(action.options);
  }
  if (action.name === "payments") {
    return handlePayments(action.options);
  }
  if (action.name === "payment") {
    return handlePayment(action.options);
  }
  if (action.name === "paymentsInbox") {
    return handlePaymentsInbox(action.options);
  }
  if (action.name === "completedPatientInbox") {
    return handleCompletedPatientInbox(action.options);
  }
  if (action.name === "posted") {
    return handlePosted(action.options);
  }
  if (action.name === "paperChecks") {
    return handlePaperChecks(action.options);
  }
  if (action.name === "payerGroups") {
    return handlePayerGroups(action.options);
  }
  if (action.name === "family") {
    return handleFamily(action.options);
  }
  if (action.name === "balances") {
    return handleBalances(action.options);
  }

  // server only
  if (action.name === "insuranceLogins") {
    return handleInsuranceLogins(action.options);
  }
  if (action.name === "bankAccounts") {
    return handleBankAccounts(action.options);
  }
  if (action.name === "search") {
    return handleSearch(action.options);
  }
  if (action.name === "recentSearches") {
    return handleRecentSearches();
  }
  if (action.name === "clearRecentSearch") {
    return handleClearRecentSearch(action.options);
  }
  if (action.name === "createBankLink") {
    return handleCreateBankLink(action.options);
  }
  if (action.name === "completeBankLink") {
    return handleCompleteBankLink(action.options);
  }
  if (action.name === "removeBankLink") {
    return handleRemoveBankLink(action.options);
  }
  throw new Error("Invalid query");
}

export function baseQuery() {
  return async (action: DiskAction.Query) => {
    try {
      const data = await diskQuery(action);

      return { data };
    } catch (error) {
      return { error };
    }
  };
}
