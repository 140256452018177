import { Button } from "@lassie/ui/button";
import { EmptyState } from "@lassie/ui/empty-state";
import * as Sentry from "@sentry/react";
import {
  IconAlertCircleFilled,
  IconArrowLeft,
  IconMessageCircle2Filled,
  IconRotate,
} from "@tabler/icons-react";
import { useRouter } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { TopBar } from "../top-bar";

export function ErrorFallback({
  error,
}: {
  error: Error;
}) {
  const [eventId, setEventId] = useState<string | null>(null);
  const router = useRouter();

  /*
   * Some error boundaries are outside of the router context, so we need to
   * conditionally show the back navigation.
   */
  const goBack = router
    ? () => {
        router.history.back();
      }
    : null;

  // Capture the error when the component mounts
  useEffect(() => {
    if (error) {
      const id = Sentry.captureException(error);
      setEventId(id);
    }
  }, [error]);

  const openSupport = () => {
    // create a descriptive message for the error
    const errorMessage = `Error Report: ${error?.message || "Unknown error"}`;

    const errorDetails = {
      message: error?.message || "Unknown error",
      stack: error?.stack || "No stack trace available",
      url: window.location.href,
      timestamp: new Date().toISOString(),
      sentryEventId: eventId,
    };

    if (window.Intercom) {
      window.Intercom("update", {
        error_occurred: true,
        error_details: JSON.stringify(errorDetails, null, 2),
        sentry_event_id: eventId,
      });

      const supportMessage = [
        "Hi, I encountered an error on the site.",
        "<additional details>",
      ]
        .filter(Boolean)
        .join("\n");
      window.Intercom("showNewMessage", supportMessage);
      return;
    }

    // if Intercom is not available, send an email
    window.location.href = `mailto:concierge@golassie.com?subject=${errorMessage}&body=${errorDetails}`;
  };

  return (
    <div
      className="relative flex min-h-screen flex-col items-center justify-center gap-y-2 bg-slate-100 p-4 pb-32"
      data-sentry-unmask
    >
      {goBack && (
        // conditionally show the top bar if the router is available
        // the included sidebar button also only works in the router layout
        <TopBar.Wrapper className="!border-b-0 absolute top-0 left-0 w-full">
          <TopBar.Left>
            <Button
              prefix={<IconArrowLeft size={16} />}
              variant="ghost"
              intent="muted"
              className="-ml-1.5 hover:bg-slate-200/80"
              onClick={goBack}
            >
              Back
            </Button>
          </TopBar.Left>
        </TopBar.Wrapper>
      )}

      <EmptyState
        intent="danger"
        icon={<IconAlertCircleFilled className="h-16 w-16" />}
        title="Something went wrong!"
        description="We apologize for the inconvenience are here to help."
        actions={[
          {
            prefix: <IconRotate size={18} />,
            variant: "secondary",
            children: "Try Again",
            onClick: () => window.location.reload(),
          },
          {
            prefix: <IconMessageCircle2Filled size={18} />,
            variant: "ghost",
            children: "Support",
            onClick: openSupport,
            className: "hover:bg-slate-200/80",
          },
        ]}
      />
    </div>
  );
}
